import React, { Component, useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import {Row, Col, Button, message, Modal, Form, Input} from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { translate } from "../../helpers/translate";
// fake data generator
const getItems = (count:number) =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k}`,
    content: `item ${k}`
  }));

// a little function to help us with reordering the result
const reorder = (list:any[], startIndex:number, endIndex:number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const grid = 8;

const getItemStyle = (isDragging:boolean, draggableStyle:any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "white",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = (isDraggingOver:boolean) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
});

type DragdropType = {
  type: any[]
}

export const Dragdrop = (props: {lang:string|null}) => {
  const [leftItems, setLeftItems] = useState<any[]>([]);
  const [orderItems, setOrderItems] = useState<any[]>([]);
  const [lang, setLang] = useState(props.lang);
  const [isModalAddEventOpen, setIsModalAddEventOpen] = useState(false);
  const [ísModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [eventId, setEventId] = useState(0);
  const getData = async () => {
    const token = localStorage.getItem('token');
    const urlStr = localStorage.getItem("serverName") + 'api/PredefinedEvent/events'
    const rawResponse = await fetch(urlStr, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
}).then((response:any) => response.json())
.then((data)=>{
        setOrderItems(data.map((item:any, index:number)=>{
            return {order: index, id: item.id}
        }));
        setLeftItems(data);
})
.catch(err => console.log(err))
  }
  function onDragEnd(result:any) {
    if (!result.destination) {
      return;
    }
   setLeftItems(reorder(
      leftItems,
      result.source.index,
      result.destination.index
    ));
  }
  useEffect(()=>{
    setLang(localStorage.getItem('language'));
  },[localStorage.getItem('language')])
  useEffect(()=>{
    getData();
  },[])

  const onFinish = (values:any) =>{
    const token = localStorage.getItem('token');
    const urlStr = localStorage.getItem("serverName") + 'api/PredefinedEvent'
    const rawResponse = fetch(urlStr, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
  body: JSON.stringify({eventNameVn: values.EventNameVn, eventNameGer: values.EventNameGer,
    eventNameEn: values.EventNameEn, eventOrder : leftItems.length
  })
}).then((response:any) => response.json())
.then(data=>{
    if(data=='Added successfully')
    {
      getData();
    }
    else
    {
      message.info("Đã có lỗi xảy ra khi thêm sự kiện");
    }
})
      setIsModalAddEventOpen(false);
  }

    return(
      <>
      <Row>
        <Col span={14}>
          <Button onClick={async ()=>{
            const token = localStorage.getItem('token');
            for(let i=0; i<leftItems.length; i++){
              const urlStr = localStorage.getItem("serverName") + 'api/PredefinedEvent/' + leftItems[i].id
              const rawResponse = await fetch(urlStr, {
                  method: 'PUT',
                  headers: {
                              'Accept': 'application/json',
                              'Content-Type': 'application/json',
                              'Authorization': `Bearer ${token}`,
                          },
                      body: JSON.stringify({id: leftItems[i].id, 
                          eventNameVn: leftItems[i].eventNameVn,
                          eventNameGer: leftItems[i].eventNameGer,
                          eventNameEn: leftItems[i].eventNameEn,
                          eventOrder: i,
                          })                
      }).then((response:any) => response.json())
            }
            message.info("Ordering successfully!");
          }
          }>{translate(lang==null?'ger':lang, 'Thay đổi thứ tự')}</Button><Button onClick={()=>{
            setIsModalAddEventOpen(true);
          }}>{translate(lang==null?'ger':lang, 'Thêm sự kiện mới')}</Button>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="leftItems">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {leftItems.map((item:any, index:number) => (
                <Draggable key={item.id.toString()} draggableId={item.id.toString()} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      {lang=='vie'?item.eventNameVn:lang=='en'?item.eventNameEn:item.eventNameGer} &nbsp;&nbsp; 
                      {[1, 2, 3, 4, 9].includes(item.id)?'':<a href='#' onClick={()=>{
                        setEventId(item.id);
                        setIsModalDeleteOpen(true)}}>{translate(lang==null?'ger':lang, 'Xóa sự kiện')}</a>}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>          
          )}
        </Droppable>
      </DragDropContext>
      </Col>
      </Row>
      <Modal title= "Thêm sự kiện" open={isModalAddEventOpen}
      footer={null}
      onCancel={()=>{setIsModalAddEventOpen(false)}}>
          <Form onFinish={onFinish} style={{border: '1px solid gray', marginTop: 20, paddingTop:20}}
    layout="horizontal"
    labelCol={{ span: 10 }} wrapperCol={{ span: 20 }}
    >
        <FormItem name="EventNameVn" label="Tên sự kiện(vn):"
      rules={[{required:true, message:'Phải nhập sự kiện'}]}>
        <Input />
      </FormItem>
      <FormItem name="EventNameGer" label="Tên sự kiện(ger):"
      rules={[{required:true, message:'Phải nhập sự kiện'}]}>
        <Input />
      </FormItem>
      <FormItem name="EventNameEn" label="Tên sự kiện(en):"
      rules={[{required:true, message:'Phải nhập sự kiện'}]}>
        <Input />
      </FormItem>
      <Form.Item label="   ">
          <Button type="primary" htmlType="submit"
          >Lưu dữ liệu</Button>
      </Form.Item>
    </Form>
      </Modal>
      <Modal title="Xóa sự kiện" open={ísModalDeleteOpen} onOk={()=>{
          const token = localStorage.getItem('token');
          const urlStr = localStorage.getItem("serverName") + 'api/PredefinedEvent/' + eventId
          const rawResponse = fetch(urlStr, {
              method: 'DELETE',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
              },
      }).then((response:any) => response.json())
      .then(data=>{
          if(data=='Removed successfully')
          {
            getData();
          }
          else
          {
            message.info("Đã có lỗi xảy ra khi xóa sự kiện");
          }
      })
      setIsModalDeleteOpen(false);
      }} onCancel={(()=>{
        setIsModalDeleteOpen(false)
      })}>
      <p>Bạn có chắc chắn xóa lịch sự kiện này không?</p>
    </Modal>          
      </>
    );
}