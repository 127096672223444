import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { useAppDispatch } from '../../configs/hook';
import getState from 'redux-thunk';
import {InternType} from '../../interface';
import axios from 'axios';
import { stat } from 'fs';

export const allInternSlice = createSlice({
    name:'allInterns',
    initialState: {} as InternType[],
    reducers: ({
        getAllInterns: (state, action: PayloadAction<InternType[]>) => {
            return action.payload;
        }
    })
})
export const internSlice = createSlice({
    name:'intern',
    initialState: {} as InternType,
    reducers: ({
        getIntern: (state, action: PayloadAction<InternType>) => {
            return action.payload;
        },
        updateIntern: (state, action: PayloadAction<InternType>) => {
            return action.payload;
        }
    })
})
export const historySlice = createSlice({
    name:'history',
    initialState: {} as any,
    reducers: ({
        getHistory: (state, action: PayloadAction<any>) => {
            return action.payload;
        }
    })
})

export function getIntern(id?:string|null){
    return async function getInternThunk(dispatch: Dispatch, getState:any){
        const token = localStorage.getItem('token');
        const rawResponse = await fetch(localStorage.getItem("serverName")+'api/Register/getintern/'
        + (id!=null? id : localStorage.getItem('emailData')), {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
    }).then(response => response.json())
    .then(async(data)=>{
        console.log(data);
        let newdata: InternType = {
            key: data.id,
            id: data.id,
            internName:data.internName,
            dateOfBirth:data.dateOfBirth,
            internMail:data.internMail,
            imageDataUrl:data.imageDataUrl,
            telephoneNum:data.telephoneNum,
            internSpecialized:data.internSpecialized,
            fileUrl:data.fileUrl,
            father: data.father,
            mother:data.mother,
            parentAddress: data.parentAddress,
            parentContact: data.parentContact,
            placeOfBirth: data.placeOfBirth,
            passport: data.passport,
            socialMedia: data.socialMedia,
            address: data.address,
            cVFil1Url: data.cVFil1Url,
            cmndUrl: data.cmndUrl,
            passportUrl: data.passportUrl,
            graduatedUrl: data.graduatedUrl,
            major:data.major,
            gender:data.gender,
            internStatus: data.internStatus,
            foreignLanguage: data.foreignLanguage,
            isEditableInternSpecialized: false,
            university: data.university,
            internable: data.internable,
            citizenIdentification: data.citizenIdentification == null ? 'Không có dữ liệu' : data.citizenIdentification,
            citizenIdentificationDate: data.citizenIdentificationDate == null ? 'Không có dữ liệu' : data.citizenIdentification == null ? 'Không có dữ liệu' : data.citizenIdentificationDate,
            taxCode: data.taxCode,
            enterprisevn: data.enterprisevn,
            isEnterprisevnEditable: false,
            idEnterpriseRegister:data.idEnterpriseRegister
            }
            dispatch(
                internSlice.actions.getIntern(newdata))
                await fetch(localStorage.getItem("serverName")+'api/InternHistory/'
                + data.id, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
            }).then(response => response.json())
            .then((datas)=>{
                dispatch(
                    historySlice.actions.getHistory(datas));
            })
        })
    .catch(err => console.log(err));
    }
}
export function getAllInterns(){
    return async function getInternThunk(dispatch: Dispatch, getState:any){
        const token = localStorage.getItem('token');
        fetch(localStorage.getItem("serverName")+'api/Register/', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
      }).then(response => response.json())
      .then(async(datas)=>{
      let tempData: InternType[] = [];
      await datas.map((data:any)=>{
        tempData.push({
                key: data.id,
                id: data.id,
                internName:data.internName,
                dateOfBirth:data.dateOfBirth,
                internMail:data.internMail,
                imageDataUrl:data.imageDataUrl,
                telephoneNum:data.telephoneNum,
                internSpecialized:data.internSpecialized,
                fileUrl:data.fileUrl,
                father: data.father,
                mother:data.mother,
                parentAddress: data.parentAddress,
                parentContact: data.parentContact,
                placeOfBirth: data.placeOfBirth,
                passport: data.passport,
                socialMedia: data.socialMedia,
                address: data.address,
                cVFil1Url: data.cVFil1Url,
                cmndUrl: data.cmndUrl,
                passportUrl: data.passportUrl,
                graduatedUrl: data.graduatedUrl,
                major:data.major,
                gender:data.gender=='1'?'Nam':'Nữ',
                internable: data.internable,
                internStatus: data.internStatus,
                foreignLanguage: data.foreignLanguage,
                isEditableInternSpecialized: false,
                university: data.university,
                citizenIdentification: data.citizenIdentification == null ? 'Không có dữ liệu' : data.citizenIdentification,
                citizenIdentificationDate: data.citizenIdentificationDate == null ? 'Không có dữ liệu' : data.citizenIdentification == null ? 'Không có dữ liệu' : data.citizenIdentificationDate,
                taxCode: data.taxCode,
                enterprisevn: data.enterprisevn,
                isEnterprisevnEditable: false,
                idEnterpriseRegister: data.idEnterpriseRegister
        })
      })
      dispatch(allInternSlice.actions.getAllInterns(tempData));
    })
}
}
