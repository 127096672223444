import React, {useState, useEffect} from 'react';
import Register from '../../components/register';
import {Jds} from '../../components/register/Jds';
import { CompanyType, Monhoc } from '../../interface';
import { useAppDispatch } from '../../configs/hook';
import { getCompany, getSubject } from './companySlice';
import { getCareer } from './careerNavigateSlice';
import {
  UploadOutlined,
  UserOutlined
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Layout, Menu, theme, Select, SelectProps } from 'antd';
import RegisterCompany from '../../components/register/company';
import {translate} from '../../helpers/translate';
import './RegisterLayout.css';
import { url } from 'inspector';
const { Header, Content, Footer } = Layout;

const handleMenuClick: MenuProps['onClick'] = (e) => {
  console.log('click', e);
};

const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
  console.log('click left button', e);
};

const RegisterLayout: React.FC = () => {
  const [data, setData] = useState<CompanyType[]>([]);
  const [options, setOptions] = useState<SelectProps['options']>([
    {value:'vie', label:'Vietnamese'},{value:'en',label:'English'},{value:'ger', label:'German'}
  ]);
  const [language, setLanguage] = useState('ger');
  useEffect(()=>{
    localStorage.setItem("lang", 'ger');
    dispatch(getCompany());
    dispatch(getSubject());
    dispatch(getCareer());
  },[])
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [item, setItem] = useState(3);
  const items: MenuProps['items'] = [
    translate(language,'Đăng ký trực tuyến'),
    translate(language,'Đăng nhập')
 ].map((item, index) => ({
  key: String(index + 1),
  icon: index==0?React.createElement(UserOutlined):React.createElement(UploadOutlined),
  label: item,
  onClick: ()=>{
    if(index == 1){
      window.location.href= '/';
    }
   setItem(index+1);
  }
}));
const dispatch = useAppDispatch();
const handleChange = (value: string) => {
    setLanguage(value);
    localStorage.setItem("lang", value);
}
  return (
    <Layout>
      <Header style={{ position: 'sticky', top: 0, zIndex: 1, width: '100%', borderBottom:'1px solid gray',
      backgroundColor:'white', paddingLeft:'20px' }}>
        <div
          style={{
            float: 'left',
            width: 165,
            height: 35,
            margin: '16px 5px 16px 0',
            overflow:'hidden'
          }}
        >
          <img src='logo.jpg' style={{width:'140px', height:'70px', marginRight:'5px', marginTop:'-18px'}} />
        </div>
        <Menu theme="light" mode="horizontal" defaultSelectedKeys={['4']} items={items} />
        <div
          style={{
            float: 'right',
            width: 255,
            height: 35,
            margin: '-65px -29px 16px 0',
            padding:'0px'
          }}
        > {translate(language,'Ngôn ngữ')}: &nbsp;
        <Select defaultValue={'ger'}
        onChange={handleChange} style={{width:'165px'}}
        options={options}
        />
        </div>
        </Header>
        <Content className="site-layout" style={{ padding: '0 20px' }}>
        {item==1?
            <Register lang={language} />
        : ""
        }
        </Content>
        <Footer style={{ textAlign: 'center' }}>Alta Software - Trainning Team - 04/2023</Footer>
    </Layout>
  );
};
export default RegisterLayout;