import React, {useState, useEffect} from 'react';
import { Table, DatePicker, Button } from 'antd';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import {translate} from '../../../helpers/translate';
import { RootState } from '../../../redux/store';
import './style.css';

const GerStatistic = (props:{enterprise:string, lang:string | null}) => {
    const [lang, setLang] = useState<string|null>('ger');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date(new Date().getTime()+90*24*60*60*1000));
    const myCities = useSelector((state:RootState)=>state.cities);
    const gerReportInfo = useSelector((state:RootState)=>state.gerReportInfo);
    const [myReport, setMyReport] = useState<any[]>([]);
    const [data, setData] = useState([]);
    const getReport = async () => {
        const token = localStorage.getItem('token');
        let myStartMonth = (startDate.getMonth()+1)<10?"0"+(startDate.getMonth()+1):startDate.getMonth()+1;
        let myStartDate = startDate.getDate()<10?"0"+startDate.getDate():startDate.getDate();
        let myEndMonth = (endDate.getMonth()+1)<10?"0"+(endDate.getMonth()+1):endDate.getMonth()+1;
        let myEndDate = endDate.getDate()<10?"0"+endDate.getDate():endDate.getDate();
        let mystartDate = startDate.getFullYear() + '-' + myStartMonth + '-' + myStartDate;
        let myendDate = endDate.getFullYear() + '-' + myEndMonth +'-'+ myEndDate;
            const rawResponse = await fetch(localStorage.getItem("serverName")+'api/Statistic/getdata/' 
            + localStorage.getItem('taxCode') + '/' + mystartDate + "/" + myendDate, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
        }).then(response => response.json())
        .then((data)=>{
            setMyReport(data);
            console.log(myReport);
        })
        .catch(err => console.log(err));
    }
useEffect(()=>{
    console.log(gerReportInfo);
    setLang(localStorage.getItem('language'));
    getReport();
}, [localStorage.getItem('language')])
return(
<>
{translate(props.lang ==null ? 'ger' : props.lang, 'Từ ngày')}: &nbsp;
      <DatePicker defaultValue={dayjs(startDate)}
        onChange={(date, dateString)=>{
          setStartDate(new Date(dateString));
        }} />
 {translate(props.lang ==null ? 'ger' : props.lang, 'Đến ngày')}: &nbsp;
      <DatePicker defaultValue={dayjs(endDate)}
        onChange={(date, dateString)=>{
          setEndDate(new Date(dateString));
        }} />
<Button type="primary" onClick={()=>{
            getReport();
        }}>{translate(props.lang ==null ? 'ger' : props.lang, 'Kết quả')}</Button>
<hr />
<table border={2} style={{marginTop:'10px'}}>
            <tr className='Header'>
                <td colSpan={3} style={{textAlign:'center'}}>Career Navigation / Städte</td>
                {myCities.map(item=><td style={{width: '100px', textAlign:'center'}} key={item.key}>{item.title}</td>)}
            </tr>
            {gerReportInfo.map(item=>{
                let i=0;
                let k=0;
                console.log(item.abbreatation);
                item.details.map((u:any)=>{
                    u.status.map((y:any)=>{k++})
                })
                return(
                    <>
                {item.details.map((subitem1:any)=>{
                    let a=0;
                    return(
                    <>
                    {subitem1.status.map((row:string)=>{
                        i++; a++;
                if(i==1 && a==1)
                return(
                <tr>
                    <td className='Header' rowSpan={k}>{item.careerNavigationGe}</td>
                    <td className='Header' rowSpan={subitem1.status.length} >{subitem1.navigaiton==false?translate(props.lang!=null ? props.lang : 'ger', 'Học nghề'): 
                    translate(props.lang!=null ? props.lang : 'ger', 'Đổi bằng') }</td>
                    <td className='Header'>{row}</td>
                    {myCities.map(itemData=><td style={{width: '100px', textAlign:'center'}}>
                        {myReport.filter(x=>x.city == itemData.title && x.abbreation == item.abbreatation && x.navigation == subitem1.navigaiton
                            && x.status == row).length>0?myReport.filter(x=>x.city == itemData.title && x.abbreation == item.abbreatation && x.navigation == subitem1.navigaiton
                            && x.status == row)[0].numberOfStudents:0}</td>)}
                </tr>)
                else if(i==1 && a!=1)
                return(
                    <tr>
                        <td className='Header' rowSpan={k}>{item.careerNavigationGe}</td>
                        <td className='Header'>{row}</td>
                        {myCities.map(itemData=><td style={{width: '100px', textAlign:'center'}}>
                        {myReport.filter(x=>x.city == itemData.title && x.abbreation == item.abbreatation && x.navigation == subitem1.navigaiton
                            && x.status == row).length>0?myReport.filter(x=>x.city == itemData.title && x.abbreation == item.abbreatation && x.navigation == subitem1.navigaiton
                            && x.status == row)[0].numberOfStudents:0}</td>)}
                    </tr>)
                else if(i!=1 && a==1)
                return(
                    <tr>
                        <td className='Header' rowSpan={subitem1.status.length}>{subitem1.navigaiton==false?translate(props.lang!=null ? props.lang : 'ger', 'Học nghề'): 
                        translate(props.lang!=null ? props.lang : 'ger', 'Đổi bằng') }</td>
                        <td className='Header'>{row}</td>
                        {myCities.map(itemData=><td style={{width: '100px', textAlign:'center'}}>
                        {myReport.filter(x=>x.city == itemData.title && x.abbreation == item.abbreatation && x.navigation == subitem1.navigaiton
                            && x.status == row).length>0?myReport.filter(x=>x.city == itemData.title && x.abbreation == item.abbreatation && x.navigation == subitem1.navigaiton
                            && x.status == row)[0].numberOfStudents:0}</td>)}
                    </tr>)
                else
                return(
                    <tr>
                        <td className='Header'>{row}</td>
                        {myCities.map(itemData=><td style={{width: '100px', textAlign:'center'}}>
                        {myReport.filter(x=>x.city == itemData.title && x.abbreation == item.abbreatation && x.navigation == subitem1.navigaiton
                            && x.status == row).length>0?myReport.filter(x=>x.city == itemData.title && x.abbreation == item.abbreatation && x.navigation == subitem1.navigaiton
                            && x.status == row)[0].numberOfStudents:0}</td>)}
                    </tr>)
                    })}
                    </>)
                })}
                </>)                
            })}
            
</table>
</>)
};
export default GerStatistic;