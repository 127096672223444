import React, {useState } from 'react';
import { Button, Checkbox, Form, Input, message, Select } from 'antd';
import vnflag from '../../assets/images/vn.png';
import gerflag from '../../assets/images/ger.png';
import enflag from '../../assets/images/en.png';
import {translate} from '../../helpers/translate';
import ChangePass from '../../components/changePass';
const DeclareInfo = () => {
  const [lang, setLang] = useState('ger');
  const [imgvnStyle, setImgvnStyle] = useState({
    width:'70px', height:'35px', border:'5px solid blue', marginRight:'5px', marginTop:'10px'
  })
  const [imggerStyle, setImggerStyle] = useState({
    width:'70px', height:'35px', border: 'None', marginRight:'5px', marginTop:'10px'
  })
  const [imgenStyle, setImgenStyle] = useState({
    width:'70px', height:'35px', border: 'None', marginRight:'5px', marginTop:'10px'
  })
  
  return (
      <div>
          <div style={{paddingLeft: '5px', paddingBottom:'2px'}}>
              <img src='logo.jpg' style={{width:'140px', height:'70px', marginRight:'5px', marginTop:'10px'}} />
          
    <div style={{float:'right', fontSize:'20px', fontWeight:'bold', paddingTop: '20px',
    paddingRight: '20px'}}>
            <img onClick={()=>{setImgvnStyle({
    width:'70px', height:'35px', border:'5px solid blue', marginRight:'5px', marginTop:'10px'
  })
    setImggerStyle({
      width:'70px', height:'35px', border:'None', marginRight:'5px', marginTop:'10px'
    }) 
    setImgenStyle({
      width:'70px', height:'35px', border:'None', marginRight:'5px', marginTop:'10px'
    })
    setLang('vie');
    localStorage.setItem('language', 'vie');  
            }} src={vnflag} style={imgvnStyle} />
            <img onClick={()=>{setImgvnStyle({
    width:'70px', height:'35px', border:'None', marginRight:'5px', marginTop:'10px'
  })
    setImggerStyle({
      width:'70px', height:'35px', border:'5px solid blue', marginRight:'5px', marginTop:'10px'
    }) 
    setImgenStyle({
      width:'70px', height:'35px', border:'None', marginRight:'5px', marginTop:'10px'
    })
    setLang('ger');
    localStorage.setItem('language', 'ger');  
            }} src={gerflag} style={imggerStyle} />
            <img onClick={()=>{setImgvnStyle({
    width:'70px', height:'35px', border:'None', marginRight:'5px', marginTop:'10px'
  })
    setImggerStyle({
      width:'70px', height:'35px', border:'None', marginRight:'5px', marginTop:'10px'
    }) 
    setImgenStyle({
      width:'70px', height:'35px', border:'5px solid blue', marginRight:'5px', marginTop:'10px'
    })
    setLang('en');
    localStorage.setItem('language', 'en');  
            }} src={enflag} style={imgenStyle} />
    </div>
    </div>
          <hr />
    <div style={{/*backgroundImage:`url(${bgimage})`,*/ paddingTop:'10px', height:'1000%'}}>
    <ChangePass lang={lang} reset={false} email={localStorage.getItem("emailData")} resetRole={localStorage.getItem("roleData")} />
    </div>
    </div>
  );
};

export default DeclareInfo;