import React, { useState, useEffect } from 'react';
import { Table, Modal, Form, Input, Button, DatePicker, message, Select, SelectProps } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { TableRowSelection } from 'antd/es/table/interface';
import { InternType } from '../../interface';
import {translate} from '../../helpers/translate';
import dayjs from 'dayjs';
import axios from 'axios';
import { getDateString } from '../../helpers';
import './style.css';
import deleteImg from '../../assets/images/recyclebin.png';
import ChangePass from '../changePass';
import FormItemInput from 'antd/es/form/FormItemInput';
import MyProfile from '../myProfile';
import { useAppDispatch } from '../../configs/hook';
import { getIntern, internSlice } from '../../layouts/guests/internSlice';

const { Search } = Input;
interface ExpandedDataType {
    id: number;
    eventName: string;
    predefineEventId:number;
    eventDate: string;
    result: string;
    userCreated: string;
    internId: number;
    link: string;
    isLinkEditable: boolean;
    isResultEditable: boolean;
  }
interface DataType {
  key: React.Key,
  id: number,
  internName: string,
  dateOfBirth: string,
  internMail: string,
  telephoneNum: string,
  internSpecialized: string,
  fileUrl: string,
  major: string,
  gender: string,
  father:string,
  mother: string,
  placeOfBirth: string,
  passport:string,
  imageDataUrl?: string,
  internable: string,
  parentAddress: string,
  parentContact: string,
  socialMedia: string,
  university: string,
  address: string,
  citizenIdentification:string,
  citizenIdentificationDate:string,
  isEditableInternSpecialized: boolean,
  cVFil1Url: string,
  cmndUrl: string,
  passportUrl: string,
  graduatedUrl: string,
  internStatus:string,
  foreignLanguage: string
}
const MyIntroducedCv = (props: {lang:string|null}) => {
  const dispatch = useAppDispatch();
  const [isModalResetPassOpen, setIsModalResetPassOpen] = useState(false);
  const [resetRole, setResetRole] = useState('0');
  const [options, setOptions] = useState<SelectProps['options']>([]);
  const [options1, setOptions1] = useState<SelectProps['options']>([]);
  const [optionsResults, setOptionsResults] = useState<SelectProps['options']>([
    {value:'Pending', label:'Pending'}, {value:'Passed', label:'Passed'},{value:'Failed', label:'Failed'}
  ]);
  const [optionsEventPredefine, setOptionsEventPredefine] = useState<SelectProps['options']>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalInternDetail, setIsModalInternDetail] = useState(false);
    const [subdata, setSubdata] = useState<ExpandedDataType[]>([]);
    const [data, setData] = useState<InternType[]>([]);
    const [career, setCareer] = useState<{abbreation:string,navigation:string}[]>([]);
    const [searchData, setSearchData] = useState<InternType[]>([]);
    const [internId, setInternId] = useState(-1);
    const [internIdList, setInternIdList] = useState<number[]>([]);
    const [deleteItem, setDeleteItem] = useState(-1); 
    const [eventDate, setEventDate] = useState(new Date());  
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [email, setEmail] = useState('');
    const lang = props.lang;
    const token = localStorage.getItem('token');
    const getData = () => {
      if(localStorage.getItem('roleData')!='1')
      {
    fetch(localStorage.getItem("serverName")+'api/Register/passedinterviewger/' 
    + localStorage.getItem('taxCode'), {
      method: 'GET',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
      },
}).then(response => response.json())
.then(async(datas)=>{
let tempData: InternType[] = [];
await datas.map((data:any)=>{
  tempData.push({
    key: data.id,
            id: data.id,
            internName:data.internName,
            dateOfBirth:data.dateOfBirth,
            internMail:data.internMail,
            imageDataUrl:data.imageDataUrl,
            telephoneNum:data.telephoneNum,
            internSpecialized:data.internSpecialized,
            fileUrl:data.fileUrl,
            father: data.father,
            mother:data.mother,
            internable: data.internable,
            parentAddress: data.parentAddress,
            parentContact: data.parentContact,
            placeOfBirth: data.placeOfBirth,
            passport: data.passport,
            socialMedia: data.socialMedia,
            address: data.address,
            cVFil1Url: data.cVFil1Url,
            cmndUrl: data.cmndUrl,
            passportUrl: data.passportUrl,
            graduatedUrl: data.graduatedUrl,
            major:data.major,
            gender:data.gender,
            internStatus: data.internStatus,
            foreignLanguage: data.foreignLanguage,
            isEditableInternSpecialized: false,
            university: data.university,
            citizenIdentification: data.citizenIdentification == null ? 'Không có dữ liệu' : data.citizenIdentification,
            citizenIdentificationDate: data.citizenIdentificationDate == null ? 'Không có dữ liệu' : data.citizenIdentification == null ? 'Không có dữ liệu' : data.citizenIdentificationDate,
            taxCode: data.taxCode,
            enterprisevn: data.enterprisevn,
            isEnterprisevnEditable: false,
            idEnterpriseRegister: data.idEnterpriseRegister
  })
})
setData(tempData);
setSearchData(tempData);            
})
      }
    else
    {
      fetch(localStorage.getItem("serverName")+'api/Register/', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
  }).then(response => response.json())
  .then(async(datas)=>{
  let tempData: InternType[] = [];
  await datas.map((data:any)=>{
    tempData.push({
            key: data.id,
            id: data.id,
            internName:data.internName,
            dateOfBirth:data.dateOfBirth,
            internMail:data.internMail,
            imageDataUrl:data.imageDataUrl,
            telephoneNum:data.telephoneNum,
            internSpecialized:data.internSpecialized,
            fileUrl:data.fileUrl,
            father: data.father,
            mother:data.mother,
            parentAddress: data.parentAddress,
            parentContact: data.parentContact,
            placeOfBirth: data.placeOfBirth,
            passport: data.passport,
            socialMedia: data.socialMedia,
            address: data.address,
            cVFil1Url: data.cVFil1Url,
            cmndUrl: data.cmndUrl,
            passportUrl: data.passportUrl,
            graduatedUrl: data.graduatedUrl,
            major:data.major,
            gender:data.gender=='1'?'Nam':'Nữ',
            internable: data.internable,
            internStatus: data.internStatus,
            foreignLanguage: data.foreignLanguage,
            isEditableInternSpecialized: false,
            university: data.university,
            citizenIdentification: data.citizenIdentification == null ? 'Không có dữ liệu' : data.citizenIdentification,
            citizenIdentificationDate: data.citizenIdentificationDate == null ? 'Không có dữ liệu' : data.citizenIdentification == null ? 'Không có dữ liệu' : data.citizenIdentificationDate,
            taxCode: data.taxCode,
            enterprisevn: data.enterprisevn,
            isEnterprisevnEditable: false,
            idEnterpriseRegister: data.idEnterpriseRegister
    })
  })
  setData(tempData);
  setSearchData(tempData);            
  })
    }
    }
    const handleChange = (value: string) => {
      if(value != '-1')
          setSearchData(data.filter(x=>x.internSpecialized.includes(value.toString())));
      else
          setSearchData(data);
    }
    const handleChange1 = (value: string) => {
      fetch(localStorage.getItem("serverName")+'api/Statistic/' 
      + value, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }).then(response => response.json())
    .then(async(datas)=>{
      setSearchData(datas.map((item:any)=>{
        return {...item, gender: item.gender == '1' ? 'Nam' : 'Nữ'}
      }));
    })
    .catch(err => console.log(err));
    }
const getSubdata = () => {
    fetch(localStorage.getItem("serverName")+'api/InternHistory/getallinternhistory/', {
      method: 'GET',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
      }
  }).then(response => response.json())
  .then(async(data)=>{
    setSubdata(data.map((item:any)=>{
      return {
        id: item.id,
        eventName: item.eventName,
        predefineEventId:item.predefineEventId,
        eventDate: item.eventDate,
        result: item.result,
        userCreated: item.userCreated,
        internId: item.internId,
        link: item.link,
        isLinkEditable: false,
        isResultEditable: false
      }
    }))
  })
  .catch(err => console.log(err));
  }
  const columns: ColumnsType<InternType> = [
    {
        title: 'Id',
        dataIndex: 'id',
        hidden: true,
        key: 'id'
      },
      {
        title: '',
        dataIndex: 'add',
        key: 'add',
        render:(key:number, record:InternType, index:number)=>{
        return(
          <>
        <a href='#'
        onClick= {()=>{setIsModalOpen(true);
        setInternIdList([...internIdList, record.id])}}
        >Add</a><br />
        <a href='#'
        onClick= {()=>{setIsModalResetPassOpen(true);
        setResetRole('3')
        setEmail(record.internMail);
      }}
        >Reset password</a>
        </>
        )
      }
    },
      {
        title: translate(lang!=null?lang:"en",'Tên ứng viên'),
        dataIndex: 'internName',
        key: 'internName',
        render:(key:number, record:InternType, text:any)=>{
          return(
            <>
              <a href='#' onClick={()=>{
                setInternId(record.id);
                dispatch(internSlice.actions.getIntern(searchData[searchData.findIndex(x=>x.id==record.id)]))
                setIsModalInternDetail(true)}}>{record.internName}</a>
              <p style={{color:'blue'}}>{record.internStatus}</p>
            </>
          )
        }
      },
      {
        title: translate(lang!=null?lang:"en",'Ngày sinh'),
        dataIndex: 'dateOfBirth',
        key: 'dateOfBirth'
      },
      {
        title: translate(lang!=null?lang:"en",'Giới tính'),
        dataIndex: 'gender',
        key: 'gender'
      },
      {
        title: translate(lang!=null?lang:"en",'Email'),
        dataIndex: 'internMail',
        key: 'internMail',
        hidden: true
      },
      {
        title:translate(lang!=null?lang:"en",'Chuyên ngành'),
        dataIndex:'major',
        key: 'major',
        hidden:true
      },
      {
        title:translate(lang!=null?lang:"en",'Tax code'),
        dataIndex:'taxCode',
        key: 'taxCode',
        hidden:true
      },
      {
        title:translate(lang!=null?lang:"en",'Vietnam Center'),
        dataIndex:'enterprisevn',
        key: 'enterprisevn',
        hidden: localStorage.getItem('roleData')=='2'? true : false,
        render:(key:number, record: InternType, index:number) => {
          if(!record.isEnterprisevnEditable)
          return(
            <a onClick={()=>{
              let index = data.findIndex(x=>x.id==record.id);
              let prefix = data.slice(0, index);
                  let mySlice = {...data[index], isEnterprisevnEditable: true}
                  let suffix = data.slice(index+1);
                  setSearchData([...prefix, mySlice, ...suffix])
            }}
            >{record.enterprisevn}</a>
          )
          else
          return(
            <Select style={{width:'200px'}} options={options1?.filter(x=>x.value!='All')}
            onChange={(value:string)=>{
              fetch(localStorage.getItem("serverName")+'api/IntroducedList/'+record.idEnterpriseRegister, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({id: data[index].idEnterpriseRegister, candidateName: data[index].internName,
                    telephoneNumber:data[index].telephoneNum, identifiedNumber:data[index].citizenIdentification,
                    taxCode: value
                })
        }).then(response => {
          let index = data.findIndex(x=>x.id==record.id);
          let prefix = data.slice(0, index);
              let mySlice = {...data[index], isEnterprisevnEditable: false, enterprisevn:value}
              let suffix = data.slice(index+1);
              setSearchData([...prefix, mySlice, ...suffix])
        })
        .catch(err => console.log(err));
            }}
           />
          )
          }
      },
      {
        title:translate(lang!=null?lang:"en",'Ngoại ngữ'),
        dataIndex:'foreignLanguage',
        key: 'foreignLanguage'
      },
      {
        title:translate(lang!=null?lang:"en",'Vị trí'),
        dataIndex:'internSpecialized',
        key:'internSpecialized',
        render: (key:number, record:InternType, index:number) => {
          let myfilter:any[] = career.filter(x=>record.internSpecialized.includes(x.abbreation));
          return(
            <>
            {myfilter.map(row=>
                  <p>{row.navigation}</p>
            )}
            </>
          )
        }
      },      
      {
          title: 'Download CV',
          dataIndex: 'download',
          key: 'download',
          render: (key:number, record:InternType, text:any) => {
            return(
            <a href='#'
            onClick = {()=>{
              console.log(record.fileUrl);
              fetch(localStorage.getItem("serverName")+'api/Register/files/' + record.fileUrl, {
                  method: 'GET',
                  headers: {
                      'Authorization': `Bearer ${token}`,
                  },
          })
          .then(response => response.blob())
      .then(blob => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = record.fileUrl;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();  //afterwards we remove the element again
          fetch(localStorage.getItem("serverName")+'api/Register/seencv/'+record.id, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({id:record.id})
    }).then(response => {
        //getData();
    })
    .catch(err => console.log(err));         
      });
          }}
            >{translate(lang!=null?lang:"en",'Tải CV')}</a>
            ) 
          }
        }
].filter(item=>!item.hidden);
const [optionEnterpriseVn, setOptionEnterpriseVn] = useState(false);
useEffect(()=>{
    getData();
    getSubdata();
},[])
const expandedRowRender = (record:InternType) => {
    const columns: ColumnsType<ExpandedDataType> = [
      { title: 'id', dataIndex: 'id', key: 'id', hidden: true },
      { title: 'Event Name', dataIndex: 'eventName', key: 'eventName' },
      { title: 'PredefineEventId', dataIndex: 'predefineEventId', key: 'predefineEventId', hidden: true },
      { title: 'Event Date', dataIndex: 'eventDate', key: 'eventDate' },
      { title: 'Responsible', dataIndex: 'userCreated', key: 'userCreated' },
      { title: 'Link', dataIndex: 'link', key: 'link',
        render:(key:any, record1:ExpandedDataType, index:number)=>{
          if([1,2,3,4].includes(record1.predefineEventId)){
              return(
                <p>{record1.link}</p>
              )
          }
          else{
          if(!record1.isLinkEditable)
          return(          
            <a onClick={()=>{
              let index = subdata.findIndex(x=>x.id==record1.id);
              let prefix = subdata.slice(0, index);
                  let mySlice = {...subdata[index], isLinkEditable: true}
                  let suffix = subdata.slice(index+1);
                  setSubdata([...prefix, mySlice, ...suffix])
            }}
            >{record1.link!=null?record1.link:'No link'}</a>
          )
          else
          return(          
            <input value={record1.link!=null?record1.link:'No link'} 
            onChange={(e)=>{
              let index = subdata.findIndex(x=>x.id==record1.id);
              let prefix = subdata.slice(0, index);
              let mySlice = {...subdata[index], link: e.currentTarget.value}
              let suffix = subdata.slice(index+1);
              setSubdata([...prefix, mySlice, ...suffix])
             }}
             onKeyPress={(e)=>{
               if(e.key == "Enter"){
                fetch(localStorage.getItem("serverName")+'api/InternHistory/'+record1.id,{
                  method:'PUT',
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                  },
                  body: JSON.stringify({eventName: record1.predefineEventId.toString(), eventDate:record1.eventDate,
                  result:record1.result, userCreated: record1.userCreated, internId: record1.internId.toString(),
                  link: e.currentTarget.value})
                  })
                .then((data:any)=>{
                  setSubdata(subdata.map(item=>{
                    if(item.id==record1.id)
                      return {...item, isLinkEditable: false};
                    else
                      return item;
                  }))
                })
               }
             }}
            />
          )}
        } 
      },
      { title: 'Result', dataIndex: 'result', key: 'result',
      render:(key:any, record1:ExpandedDataType, index:number)=>{
        if(localStorage.getItem('roleData')=='2'){
          if([3,4].includes(record1.predefineEventId)){
            if(!record1.isResultEditable)
            return(          
              <a onClick={()=>{
                let index = subdata.findIndex(x=>x.id==record1.id);
                let prefix = subdata.slice(0, index);
                    let mySlice = {...subdata[index], isResultEditable: true}
                    let suffix = subdata.slice(index+1);
                    setSubdata([...prefix, mySlice, ...suffix])
              }}
              >{record1.result!=null?record1.result:'No result'}</a>
            )
            else
            return(
              <Select style={{width:'70px'}}
                    onChange={(values)=>{
                      fetch(localStorage.getItem("serverName")+'api/InternHistory/'+record1.id,{
                        method:'PUT',
                        headers: {
                          'Accept': 'application/json',
                          'Content-Type': 'application/json',
                          'Authorization': `Bearer ${token}`
                        },
                        body: JSON.stringify({eventName: record1.predefineEventId.toString(), eventDate:record1.eventDate,
                        link:record1.link, userCreated: record1.userCreated, internId: record1.internId.toString(),
                        result: values})
                        })
                      .then(response=>response.text)
                      .then((data:any)=>{
                        let index = subdata.findIndex(x=>x.id==record1.id);
                        let prefix = subdata.slice(0, index);
                        let mySlice = {...subdata[index], result: values, isResultEditable: false}
                        let suffix = subdata.slice(index+1);
                        setSubdata([...prefix, mySlice, ...suffix])                 
                      })
                    }}
                    options={optionsResults}
              />
            )
          }
          else{
            return(
              <p>{record1.result!=null?record1.result:'No result'}</p>
            )
          }
        }
        if(localStorage.getItem('roleData')=='1'){
          if([3,4].includes(record1.predefineEventId)){
            return(
              <p>{record1.result!=null?record1.result:'No result'}</p>
            )
          }
          else{
            if(!record1.isResultEditable)
            return(          
              <a onClick={()=>{
                let index = subdata.findIndex(x=>x.id==record1.id);
                let prefix = subdata.slice(0, index);
                    let mySlice = {...subdata[index], isResultEditable: true}
                    let suffix = subdata.slice(index+1);
                    setSubdata([...prefix, mySlice, ...suffix])
              }}
              >{record1.result!=null?record1.result:'No result'}</a>
            )
            else
            return(
              <Select style={{width:'70px'}}
                    onChange={(values)=>{
                      fetch(localStorage.getItem("serverName")+'api/InternHistory/'+record1.id,{
                        method:'PUT',
                        headers: {
                          'Accept': 'application/json',
                          'Content-Type': 'application/json',
                          'Authorization': `Bearer ${token}`
                        },
                        body: JSON.stringify({eventName: record1.predefineEventId.toString(), eventDate:record1.eventDate,
                        link:record1.link, userCreated: record1.userCreated, internId: record1.internId.toString(),
                        result: values})
                        })
                      .then(response=>response.text)
                      .then((data:any)=>{
                        let index = subdata.findIndex(x=>x.id==record1.id);
                        let prefix = subdata.slice(0, index);
                        let mySlice = {...subdata[index], result: values, isResultEditable: false}
                        let suffix = subdata.slice(index+1);
                        setSubdata([...prefix, mySlice, ...suffix])                 
                      })
                    }}
                    options={optionsResults}
              />
            )
          }
        }
        return(
          <p>{record1.result!=null?record1.result:'No result'}</p>
        )
      }
    },
      {
        title:'Action',
        dataIndex:'delete',
        key:'delete',
        render:(key:number,subrecord:ExpandedDataType, index:number)=>{
          return(
            <Button type='primary' danger disabled={localStorage.getItem('roleData')=='1'?false:
              [3,4].includes(subrecord.predefineEventId)?false:true}
            onClick = {()=>{
              setIsConfirmModalOpen(true);
              setDeleteItem(subrecord.id);
            }}
            >Delete</Button>
          )
        }
      }
    ].filter(a=>!a.hidden);
    return <Table columns={columns} 
    dataSource={subdata.filter(a=>a.internId==record.id)} pagination={false} />;
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleConfirmOk = (item: number) => {
    fetch(localStorage.getItem("serverName")+'api/InternHistory/' + item, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
}).then(response => {
    setSubdata(subdata.filter(c=>c.id!=Number(item)));
    setIsConfirmModalOpen(false);
})
.catch(err => console.log(err));
}
const handleConfirmCancel = () => {
  setIsConfirmModalOpen(false);
}
  const onFinish = async (values: any) => {
    console.log(values);
    console.log(getDateString(eventDate));
    const formData = new FormData();
    formData.append("EventName",values.EventName);
    formData.append("EventDate", getDateString(eventDate));
    formData.append("Result", values.result);
    formData.append("InternId", JSON.stringify(internIdList));
    formData.append("UserCreated", values.email);
    const config = {     
      headers: { "Content-Type": "multipart/form-data", 'Authorization': `Bearer ${token}` }
    }
    axios.post(localStorage.getItem("serverName")+'api/InternHistory', formData, config)
      .then(response => {
            message.success(response.data);
            setIsModalOpen(false);
      })
      .catch(error => {
          message.info("Đã có lỗi trong quá trình đăng ký");
          console.log(error);
      });       
  };
  const getAllSubjects = async () => {
    let myOptions:any = [];
    const rawResponse = await fetch(localStorage.getItem("serverName")+'api/CareerNavigation', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
    }).then(response => response.json())
    .then((data)=>{
          myOptions.push({value: '-1', label: 'All'});
          data.map((item:any)=>{
            myOptions.push({value: item.abbreatation, label:props.lang=='vie'?item.navigationNameVn
            :props.lang=='en'?item.careerNavigationEn:item.careerNavigationGe
          })
          })
          setCareer(data.map((item:any)=>{
            return {abbreation:item.abbreatation, navigation: props.lang=='vie'?item.navigationNameVn
            :props.lang=='en'?item.careerNavigationEn:item.careerNavigationGe}
          }))
          setOptions(myOptions);
          console.log(options);
    })
    .catch(err => console.log(err));
  }
  const getAllCompany = async () => {
    let myOptions:any = [];
    const rawResponse = await fetch(localStorage.getItem("serverName")+'api/Company/companybycountry/vn', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
    }).then(response => response.json())
    .then((data)=>{
          myOptions.push({value: 'All', label: 'All'});
          data.map((item:any)=>{
            myOptions.push({value: item.taxCode, label: item.companyName})
          })
          setOptions1(myOptions);
    })
    .catch(err => console.log(err));
  }
  const getPredefinedEvents = () => {
    fetch(localStorage.getItem("serverName")+'api/CareerNavigation/events', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
  }).then(response => response.json())
  .then(async(data)=>{
    setOptionsEventPredefine(data.filter((x:any)=>![1,2,3,4].includes(x.id)).map((item:any)=>{
      return {value: item.id, label: props.lang=='vn'?item.eventNameVn:props.lang=='en'?item.eventNameEn:item.eventNameGer}
    }))
  })
  }
  const onSearch = (value: string) => console.log(value);
  useEffect(()=>{
    getAllSubjects();
    getPredefinedEvents();
    getAllCompany();
  },[localStorage.getItem('language')])
  return (
    <>
      <div className="HeaderStyle">
      <Search addonBefore="Search by Candidate Name: " placeholder="input search text" 
      onSearch={onSearch} style={{ width: 400 }}
      onChange={(e)=>{
        setSearchData(data.filter(x=>x.internName.includes(e.currentTarget.value)))
      }}
      />
      <Search addonBefore="Search by candidate history: " placeholder="input search text" 
      onSearch={onSearch} style={{ width: 400 }}
      onChange={(e)=>{
        let myInternIdArr = subdata.filter(x=>x.eventName.includes(e.currentTarget.value)).map(row=>row.internId)
        setSearchData(data.filter(x=>myInternIdArr.includes(x.id)))
      }}
      /> <br />
      Career applied: &nbsp;
      <Select
        defaultValue={'-1'}
        onChange={handleChange}
        options={options}
        style={{width:'25%'}}
        /> &nbsp;
      {localStorage.getItem('roleData')=='1'?
      <>
      Vietnam Enterprise Name: &nbsp;
      <Select
        defaultValue={'All'}
        onChange={handleChange1}
        options={options1}
        style={{width:'25%'}}
        />
      </>
      :''}
      </div>
      
      <Table
        columns={columns}
        expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
        dataSource={searchData}
      />
      <Modal title="Add event" open={isModalOpen} onCancel={handleCancel} footer={null}>
        <Form labelCol={{ span: 8 }}
        wrapperCol={{ span: 25 }}
        layout="horizontal"
        onFinish={onFinish} >
      <Form.Item name="EventName"
      label='Event name:'
      rules={[{required:true, message:'Phải chọn tên sự kiện'}]}
      >
        <Select
        options={optionsEventPredefine}
        />
      </Form.Item>
        <Form.Item label="Event Date" 
        name="eventDate"
        rules={[{ required: true, message: 'This field not be empty!' }]}>
          <DatePicker
        onChange={(date, dateString)=>{
          setEventDate(new Date(dateString));
        }} />
        </Form.Item>
        <Form.Item label="Email of Resposibility" 
        name="email"
        rules={[{ required: true, message: 'This field not be empty!' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Result"  
        name="result" 
        rules={[{ required: true, message: 'This field not be empty!' }]}>
          <Select
        options={optionsResults}
        />
        </Form.Item>
        <Form.Item label="" wrapperCol={{offset:4}}>
          <Button type="primary" htmlType="submit">Save data</Button>
        </Form.Item>
        </Form>
    </Modal>
    <Modal title={translate(lang!=null?lang:'en',"Xóa dữ liệu")} open={isConfirmModalOpen} onOk={()=>handleConfirmOk(deleteItem)} onCancel={handleConfirmCancel}>
        <div style={{height:'110px', marginTop:'20px'}}>
          <div style={{float:'left', overflow:'hidden'}}>
            <img src={deleteImg} style={{width:'100px', height:'80px'}} />
          </div>
          <div style={{float:'left', fontSize:'24px', paddingLeft:'10px', color:'red'}}>
          {translate(lang!=null?lang:'en',"Are you sure to delete this event?")}
          </div>
        </div>
      </Modal>
    <Modal width={1000} title={translate(lang!=null?lang:'ger',"Thông tin chi tiết")}
    open={isModalInternDetail} onOk={()=>setIsModalInternDetail(false)}
    >
      <div style={{height:'420px', marginTop:'10px'}}>
          <MyProfile lang='en'
          readOnlyOption={false}
          />
      </div>
    </Modal>
    <Modal title="Reset passwort" open={isModalResetPassOpen} onCancel={()=>setIsModalResetPassOpen(false)} footer={null}>
    <ChangePass lang={'ger'} reset={true} email={email} resetRole={resetRole} />
</Modal>

    </>
  );
};
export default MyIntroducedCv;