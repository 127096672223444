import React, { useEffect, useState } from 'react';
import { Space, Table, Tag, Button, Modal, Form, Input, Popconfirm, message, Switch } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import axios from 'axios';
import {getAdmins, updateAdmin, deleteAdmin} from '../../layouts/admin/adminSlide';
import deleteImage from '../../assets/images/recyclebin.png';
import { getEnvironmentData } from 'worker_threads';
import { translate } from '../../helpers/translate';

const CareerNavigation = (props:{lang:string | null}) => {
    const [isModalResetPassOpen, setIsModalResetPassOpen] = useState(false);
    const [lang, setLang] = useState<string|null>('ger');
    const columns = [
        {
          title: translate(lang==null?'ger':lang,'Ký hiệu tắt'),
          dataIndex: 'abbreatation',
          key: 'abbreatation'
        },
        {
          title: translate(lang==null?'ger':lang,"Tên nghề tiếng Việt"),
          dataIndex: 'navigationNameVn',
          key: 'navigationNameVn'
        },
        {
          title: translate(lang==null?'ger':lang,"Tên nghề tiếng Anh"),
          dataIndex: 'careerNavigationEn',
          key: 'careerNavigationEn'
        },
        {
          title: translate(lang==null?'ger':lang,"Tên nghề tiếng Đức"),
          dataIndex: 'careerNavigationGe',
          key: 'careerNavigationGe'
        },
        {
          title: translate(lang==null?'ger':lang,"Tùy chọn"),
          dataIndex: 'delete',
          key: 'delete',
          render: (key:number, record:any, index:number) => {
            return(
              <>
            <Button type="primary" danger
            onClick = {()=>{
              setManhansu(record.abbreatation);
              setIsConfirmModalOpen(true); 
            }}
            >{translate(lang==null?'ger':lang,"Xóa")}</Button>
            </>
            ) 
          }
        }
      ]
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const handleConfirmOk = (item: string) => {
      const token = localStorage.getItem('token');
      const urlStr = localStorage.getItem("serverName") + 'api/CareerNavigation/' + manhansu
      fetch(urlStr, {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      }).then(response => response.json())
      .then((data)=>{
          getData();
      })
      setIsConfirmModalOpen(false);
    }
    const handleConfirmCancel = () => {
      setIsConfirmModalOpen(false);
    }
    const handleOk = () => {
        setIsModalOpen(false);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
      };
      const showModal = () => {
        setIsModalOpen(true);
      };
    const [manhansu, setManhansu] = useState("");
    const [data, setData] = useState([]);
    const onFinish = async (values: any) => {
      console.log(values);
      const token = localStorage.getItem('token');
      const urlStr = localStorage.getItem("serverName") + 'api/CareerNavigation'
      const formData = new FormData();
          formData.append("Abbreatation", values.Abbreatation);
          formData.append("NavigationNameVn", values.NavigationNameVn);
          formData.append("CareerNavigationEn", values.CareerNavigationEn);
          formData.append("CareerNavigationGe", values.CareerNavigationGe);
          const config = {     
            headers: { 'content-type': 'multipart/form-data', 'Authorization': `Bearer ${token}` }
          }
          axios.post(urlStr,formData, config)
            .then(response => {
                console.log(response);
            }).then(()=>{
                getData();
            })
            .catch(error => {
                console.log(error);
            });
      };
    const getData = async () => {
        const token = localStorage.getItem('token');
        const urlStr = localStorage.getItem("serverName") + 'api/CareerNavigation'
        
        const rawResponse = await fetch(urlStr, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
      //body: JSON.stringify(answerRequest)
    }).then((response:any) => response.json())
    .then((data:any)=>{
            setData(data);
    })
    .catch(err => console.log(err))
    }
    useEffect(()=>{
        getData();
    },[]);
    useEffect(()=>{
      setLang(localStorage.getItem('language'))
  },[localStorage.getItem('language')])
    return(
    <>
    <Button onClick={showModal}
    disabled = {localStorage.getItem('userRole') == '1' ? 
    false : true } 
    >{translate(lang==null?'ger':lang,"Thêm nghề nghiệp")}</Button>
     <Table columns={columns} dataSource={data} />
    <Modal title={translate(lang==null?'ger':lang,"Thêm nghề nghiệp")} open={isModalOpen} onCancel={handleCancel}
    footer={null}>
        <Form labelCol={{ span: 8 }}
        wrapperCol={{ span: 20 }}
        layout="horizontal"
        onFinish={onFinish} >
          {localStorage.getItem('roleData') == '1' ?
            <Form.Item label={translate(lang==null?'ger':lang,"Ký hiệu tắt")} 
        name="Abbreatation"
        rules={[{ required: true, message: 'This field must not be empty!' }]}>
          <Input />
        </Form.Item>
        : '' }
        <Form.Item label= {translate(lang==null?'ger':lang,"Tên nghề tiếng Việt")} name="NavigationNameVn"
        rules={[{ required: true, message: 'This field must not be empty!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={translate(lang==null?'ger':lang,"Tên nghề tiếng Anh:")} 
        name="CareerNavigationEn"
        rules={[{ required: true, message: 'This field must not be empty!' }]}>
          <Input />
        </Form.Item>
        <Form.Item label={translate(lang==null?'ger':lang,"Tên nghề tiếng Đức:")} 
        name="CareerNavigationGe"
        rules={[{ required: true, message: 'This field must not be empty!' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="" wrapperCol={{offset:4}}>
          <Button type="primary" htmlType="submit">{translate(lang==null?'ger':lang,"Lưu dữ liệu")}</Button>
        </Form.Item>
        </Form>
    </Modal>
    <Modal title="Delete data" open={isConfirmModalOpen} onOk={()=>handleConfirmOk(manhansu)} onCancel={handleConfirmCancel}>
        <div style={{height:'110px', marginTop:'20px'}}>
          <div style={{float:'left', overflow:'hidden'}}>
            <img src={deleteImage} style={{width:'100px', height:'80px'}} />
          </div>
          <div style={{float:'left', fontSize:'24px', paddingLeft:'10px', color:'red'}}>
          Are you sure to delete this Career?
          </div>
        </div>
      </Modal>
    </>
    )
}
export default CareerNavigation;