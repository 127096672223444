import React, {useState, useEffect} from 'react';
import { Breadcrumb, Layout, Menu, theme, Select, SelectProps } from 'antd';
import {translate} from '../../helpers/translate';
import EnterpiseRequirement from '../../components/requirement';
import MyIntroducedCv from '../../components/introducedCv/myIntroducedCv';
import { useAppDispatch } from '../../configs/hook';
import { getCareer } from '../registers/careerNavigateSlice';
import JobPool from '../../components/jobpool';
import { getJobPools } from './jobPoolSlice';
import {TimeLineInterviews} from '../../components/timeline/interview';
import { getAllInterns } from '../guests/internSlice';
import AdminUser from '../../components/admin';
import { getAdmins } from '../admin/adminSlide';
import { getCities, getGerReport } from './statisticSlide';
import GerStatistic from '../../components/statistic/gerEnterprise';
import { getOverviewTasks } from '../../components/timeline/interviewSlice';
const { Header, Content, Footer, Sider } = Layout;

const EnterpriseLayout: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

const menuItems = [
    {
    key: '1',
    label: 'Người dùng'
    },
    {
        key: '2',
        label: 'Lịch phỏng vấn'
    },
    {
        key: '3',
        label: 'Hồ sơ được giới thiệu'
    },
    {
      key: '5',
      label: 'Tạo công việc'
    },
    {
      key: '6',
      label: 'Thống kê'
    },
    {
        key: '4',
        label: 'Đăng xuất'
    }
]  
const [options, setOptions] = useState<SelectProps['options']>([
    {value:'vie', label:'Vietnamese'},{value:'en',label:'English'},{value:'ger', label:'German'}
  ]);
const [language, setLanguage] = useState('ger');
const [myKey, setMyKey] = useState('2');
const handleChange = async (value: string) => {
    await localStorage.setItem("language", value);
    setLanguage(value);
}
useEffect(()=>{
  localStorage.setItem("language", 'ger')
  dispatch(getCareer());
  dispatch(getJobPools());
  dispatch(getAllInterns());
  dispatch(getAdmins());
  dispatch(getCities());
  dispatch(getGerReport());
  let month = (new Date()).getMonth();
  let year = (new Date()).getFullYear();
  let stdate = (month)<1?(year-1) + '-12-25': year + '-' + ((month)<10?'0'+(month):(month)) + '-25'
  let eddate = (month+2)>12?(year+1) + '-01-05': year + '-' + ((month+2)<10?'0'+(month+2):(month+2)) + '-05'
  dispatch(getOverviewTasks(stdate, eddate));
},[]);
  return (
    <Layout className="layout">
      <Header>       
        <div className="demo-logo" />
        <Menu
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            width: '70%',
            display: 'flex',
            alignItems: 'left',
          }}
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['2']}
          items={menuItems.map((row, index) => {
            const key = row.key;
            return {
              key,
              label: translate(language,row.label),
              onClick:()=>{
                switch(key)
                {
                  case '4':
                    localStorage.clear();
                    window.location.reload();
                    break;
                  default:
                    setMyKey(row.key);
                }
              }
            };
          })}
        />       
        <div
          style={{
            float: 'right',
            width: 255,
            height: 35,
            margin: '-65px -29px 16px 0',
            padding:'0px',
            color:'white'
          }}
        > {translate(language,'Ngôn ngữ')}: &nbsp;
        <Select
        defaultValue={'ger'}
        onChange={handleChange} style={{width:'165px'}}
        options={options}
        />
        </div>
      </Header>
      <Content style={{ padding: '10px 50px' }}>
        <div className="site-layout-content" style={{ background: colorBgContainer }}>
          {myKey=='2'?<TimeLineInterviews enterprise='TYG' lang={localStorage.getItem('language')} />
          :myKey=='3'?<MyIntroducedCv lang={localStorage.getItem('language')} />
          :myKey=='5'?<JobPool lang={localStorage.getItem('language')} />
          :myKey=='6'?<GerStatistic enterprise='TYG' lang={localStorage.getItem('language')} />
          :myKey=='1'?<AdminUser lang={localStorage.getItem('language')}/>:""}
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>Alta group - Trainning team ©2023</Footer>
    </Layout>
  );
};
export default EnterpriseLayout;