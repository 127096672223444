import React, {useState, useEffect} from 'react';
import { DatePicker, Button } from 'antd';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import {translate} from '../../../helpers/translate';
import { RootState } from '../../../redux/store';
import './style.css';

const VieStatistic = (props:{enterprise:string, lang:string | null}) => {
    const [lang, setLang] = useState<string|null>('vie');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date(new Date().getTime()+90*24*60*60*1000));
    const myCareers = useSelector((state:RootState)=>state.careers);
    const predefinedEvents = useSelector((state:RootState)=>state.predefinedEvents);
    const [myReport, setMyReport] = useState<any[]>([]);
    const [data, setData] = useState<any[]>([]);
    const getReport = async () => {
        const token = localStorage.getItem('token');
        let myStartMonth = (startDate.getMonth()+1)<10?"0"+(startDate.getMonth()+1):startDate.getMonth()+1;
        let myStartDate = startDate.getDate()<10?"0"+startDate.getDate():startDate.getDate();
        let myEndMonth = (endDate.getMonth()+1)<10?"0"+(endDate.getMonth()+1):endDate.getMonth()+1;
        let myEndDate = endDate.getDate()<10?"0"+endDate.getDate():endDate.getDate();
        let mystartDate = startDate.getFullYear() + '-' + myStartMonth + '-' + myStartDate;
        let myendDate = endDate.getFullYear() + '-' + myEndMonth +'-'+ myEndDate;
            const rawResponse = await fetch(localStorage.getItem("serverName")+'api/Statistic/getdatavn/' 
            + localStorage.getItem('taxCode') + '/' + mystartDate + "/" + myendDate, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
        }).then(response => response.json())
        .then((data)=>{
            console.log(data);
            setMyReport(data);
            console.log(myReport);
        })
        .catch(err => console.log(err));
    }
useEffect(()=>{
    setLang(localStorage.getItem('language'));
    getReport();
}, [localStorage.getItem('language')])
return(
<>
{translate(props.lang ==null ? 'ger' : props.lang, 'Từ ngày')}: &nbsp;
      <DatePicker defaultValue={dayjs(startDate)}
        onChange={(date, dateString)=>{
          setStartDate(new Date(dateString));
        }} />
 {translate(props.lang ==null ? 'ger' : props.lang, 'Đến ngày')}: &nbsp;
      <DatePicker defaultValue={dayjs(endDate)}
        onChange={(date, dateString)=>{
          setEndDate(new Date(dateString));
        }} />
<Button type="primary" onClick={()=>{
            getReport();
        }}>{translate(props.lang ==null ? 'ger' : props.lang, 'Kết quả')}</Button>
<hr />
<table border={2} style={{marginTop:'10px'}}>
            <tr className='Header'>
                <td rowSpan={2} style={{textAlign:'center'}}>Hoạt động / Lĩnh vực đăng ký</td>
                {myCareers.map(item=><td colSpan={2} style={{textAlign:'center'}} key={item.abbreatation}>{item.navigationNameVn}</td>)}
            </tr>
            <tr className='Header'>
            {myCareers.map(item=>{
                return(<>
                    <td style={{textAlign:'center'}}>Học nghề</td>
                    <td style={{textAlign:'center'}}>Đổi bằng</td>
                </>)
            })}
            </tr>
            {predefinedEvents.map(item=>{
                return(
                    <tr>
                        <td>{item.id + '-' + item.eventNameVn}</td>
                        {myCareers.map(rowitem=>{
                return(<>
                    <td style={{textAlign:'center'}}>
                        {myReport.length>0?myReport.filter((m:any)=>m.id == item.id
                    && m.abbreatation == rowitem.abbreatation && m.navigation == false)[0].numberOfStudent:0}
                    </td>
                    <td style={{textAlign:'center'}}>
                        {myReport.length>0?myReport.filter((m:any)=>m.id == item.id
                    && m.abbreatation == rowitem.abbreatation && m.navigation == true)[0].numberOfStudent:0}
                    </td>
                </>)
            })}
                    </tr>
                )
            })}
</table>
</>)
};
export default VieStatistic;