import { configureStore } from "@reduxjs/toolkit";
import { adminSlice } from "../layouts/admin/adminSlide";
import { subjectSlice } from "../layouts/registers/companySlice";
import { companySlice } from "../layouts/registers/companySlice";
import { internSlice, historySlice, allInternSlice } from "../layouts/guests/internSlice";
import { careerNavigateSlice } from "../layouts/registers/careerNavigateSlice";
import { interviewSlice, overviewTaskSlice } from "../components/timeline/interviewSlice";
import { jobpoolSlice } from "../layouts/enterprise/jobPoolSlice";
import { citiesSlice, gerReportSlice } from "../layouts/enterprise/statisticSlide";
import { getPredefinedEvents, predefinedEventSlice } from "../layouts/enterprisevn/predefinedEventSlice";
export const store = configureStore({
    reducer: {
        admins: adminSlice.reducer,
        companies:companySlice.reducer,
        subjects: subjectSlice.reducer,
        intern: internSlice.reducer,
        careers: careerNavigateSlice.reducer,
        interview: interviewSlice.reducer,
        histories: historySlice.reducer,
        jobpools: jobpoolSlice.reducer,
        allInterns: allInternSlice.reducer,
        overviewTasks: overviewTaskSlice.reducer,
        cities: citiesSlice.reducer,
        gerReportInfo: gerReportSlice.reducer,
        predefinedEvents: predefinedEventSlice.reducer
    }
})
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;