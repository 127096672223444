import React, {useState } from 'react';
import { Button, Checkbox, Form, Input, message, Select } from 'antd';
import vnflag from '../../assets/images/vn.png';
import gerflag from '../../assets/images/ger.png';
import enflag from '../../assets/images/en.png';
import {translate} from '../../helpers/translate';
type LoginProps = {
  message?: string,
  reRenderApp: ()=>void
}
const Login = (props:LoginProps) => {
  const [role, setRole] = useState("1");
  const [lang, setLang] = useState('vie');
  const [imgvnStyle, setImgvnStyle] = useState({
    width:'70px', height:'35px', border:'5px solid blue', marginRight:'5px', marginTop:'10px'
  })
  const [imggerStyle, setImggerStyle] = useState({
    width:'70px', height:'35px', border: 'None', marginRight:'5px', marginTop:'10px'
  })
  const [imgenStyle, setImgenStyle] = useState({
    width:'70px', height:'35px', border: 'None', marginRight:'5px', marginTop:'10px'
  })
  const onFinish = async (values: any) => {
    localStorage.setItem("password", values.password);
    localStorage.setItem("role", values.role);
    const rawResponse = await fetch(localStorage.getItem("serverName")+'api/token', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "username": values.username,
        "password": values.password,
        "role": values.role
      })
    });
    const data = await rawResponse.json();
    console.log(data)
    if(data!="Invalid credentials"){
      let userFullName = data.userFullName != null ? data.userFullName : "";
        localStorage.setItem("emailData", values.username);
        localStorage.setItem("token", data.token);
        localStorage.setItem("userNameData", data.userCode);
        localStorage.setItem("userFullName", userFullName);
        localStorage.setItem("passwordStatus", data.passwordStatus);
        localStorage.setItem("roleData", values.role);
        localStorage.setItem("userRole", data.roleData);
        localStorage.setItem("taxCode", data.taxCode);
        props.reRenderApp();
    }
    else
    {
      message.info("Login failed! Maybe username, password, role incorrect or your orginization has been disabled.");
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return (
      <div>
          <div style={{paddingLeft: '5px', paddingBottom:'2px'}}>
              <img src='logo.jpg' style={{width:'140px', height:'70px', marginRight:'5px', marginTop:'10px'}} />
          
    <div style={{float:'right', fontSize:'20px', fontWeight:'bold', paddingTop: '20px',
    paddingRight: '20px'}}>
            <img onClick={()=>{setImgvnStyle({
    width:'70px', height:'35px', border:'5px solid blue', marginRight:'5px', marginTop:'10px'
  })
    setImggerStyle({
      width:'70px', height:'35px', border:'None', marginRight:'5px', marginTop:'10px'
    }) 
    setImgenStyle({
      width:'70px', height:'35px', border:'None', marginRight:'5px', marginTop:'10px'
    })
    setLang('vie');  
            }} src={vnflag} style={imgvnStyle} />
            <img onClick={()=>{setImgvnStyle({
    width:'70px', height:'35px', border:'None', marginRight:'5px', marginTop:'10px'
  })
    setImggerStyle({
      width:'70px', height:'35px', border:'5px solid blue', marginRight:'5px', marginTop:'10px'
    }) 
    setImgenStyle({
      width:'70px', height:'35px', border:'None', marginRight:'5px', marginTop:'10px'
    })
    setLang('ger');  
            }} src={gerflag} style={imggerStyle} />
            <img onClick={()=>{setImgvnStyle({
    width:'70px', height:'35px', border:'None', marginRight:'5px', marginTop:'10px'
  })
    setImggerStyle({
      width:'70px', height:'35px', border:'None', marginRight:'5px', marginTop:'10px'
    }) 
    setImgenStyle({
      width:'70px', height:'35px', border:'5px solid blue', marginRight:'5px', marginTop:'10px'
    })
    setLang('en');  
            }} src={enflag} style={imgenStyle} />
    </div>
    </div>
          <hr />
    <div style={{/*backgroundImage:`url(${bgimage})`,*/ paddingTop:'10px', height:'1000%'}}>
      <h1 style={{textAlign:'center'}}>{translate(lang, 'Hệ thống quản trị tài năng')}</h1>
    <center><a href='/register'>{translate(lang,'Đến trang đăng ký')}</a>&nbsp;&nbsp;<a href='https://www.ty-academy.com/'>{translate(lang,'Trang chủ Ty-Academy')}</a>
    </center>
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 8 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
        <Form.Item 
        style={{color: 'red'}}
        wrapperCol={{ offset: 8, span: 6 }}     
        >
        </Form.Item>
        <Form.Item 
        style={{color: 'red'}}
        label={translate(lang, 'Vai trò')}
        name="role"
        wrapperCol={{ offset: 0, span: 6 }}
        rules={[{ required: true, message: 'Please select role!' }]}     
        >
        <Select >
            <Select.Option value="1">{translate(lang,'Quản trị viên')}</Select.Option>
            <Select.Option value="2">{translate(lang,'Đối tác Đức')}</Select.Option>
            <Select.Option value="4">{translate(lang,'Đối tác Việt Nam')}</Select.Option>
            <Select.Option value="3">{translate(lang,'Sinh viên')}</Select.Option>
          </Select>
        </Form.Item>
      <Form.Item
        label={translate(lang, "Tên đăng nhập")}
        name="username"
        rules={[{ required: true, message: translate(lang, "Please input your username!")}]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={translate(lang, "Mật khẩu")}
        name="password"
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 6 }}>
        <Button type="primary" htmlType="submit">
          {translate(lang, 'Đăng nhập') }
        </Button>
      </Form.Item>
    </Form>
    </div>
    </div>
  );
};

export default Login;