import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { useAppDispatch } from '../../configs/hook';
export const citiesSlice = createSlice({
    name:'cities',
    initialState:[] as any[],
    reducers: ({
        getCities: (state, action: PayloadAction<any[]>) => {
            state = [];
            return action.payload;
        }
    })
})
export const gerReportSlice = createSlice({
    name:'gerReport',
    initialState:[] as any[],
    reducers: ({
        getCities: (state, action: PayloadAction<any[]>) => {
            state = [];
            return action.payload;
        }
    })
})

export function getCities(){
    return async function getCitiesThunk(dispatch: Dispatch, getState:any){
        const token = localStorage.getItem('token');
        const urlStr = localStorage.getItem("serverName")
        + 'api/Statistic/City/' + localStorage.getItem('taxCode');
        const rawResponse = await fetch(urlStr, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
      //body: JSON.stringify(answerRequest)
    }).then((response:any) => response.json())
    .then((data:any)=>{
        dispatch(citiesSlice.actions.getCities(data.map((item:string)=>{
            return {title: item, dataIndex: item, key: item}
        })))      
    })
    .catch(err => console.log(err))
    }
}
export function getGerReport(){
    return async function getGerReportThunk(dispatch: Dispatch, getState:any){
        const token = localStorage.getItem('token');
        const urlStr = localStorage.getItem("serverName")
        + 'api/Statistic/CareerNavigation/' + localStorage.getItem('taxCode');
        const rawResponse = await fetch(urlStr, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
      //body: JSON.stringify(answerRequest)
    }).then((response:any) => response.json())
    .then((data:any)=>{
        dispatch(gerReportSlice.actions.getCities(data))      
    })
    .catch(err => console.log(err))
    }
}

