import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { useAppDispatch } from '../../configs/hook';
import getState from 'redux-thunk';
import {JobPoolType} from '../../interface';
import axios from 'axios';
import { stat } from 'fs';
export const jobpoolSlice = createSlice({
    name:'jobpool',
    initialState:[] as JobPoolType[],
    reducers: ({
        getJobPools: (state, action: PayloadAction<JobPoolType[]>) => {
            state = [];
            return action.payload;
        }
    })
})
export function getJobPools(){
    return async function getJobPoolsThunk(dispatch: Dispatch, getState:any){
        const token = localStorage.getItem('token');
        const urlStr = localStorage.getItem("serverName")
        + 'api/JobPool/' + localStorage.getItem('taxCode');
        const rawResponse = await fetch(urlStr, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
      //body: JSON.stringify(answerRequest)
    }).then((response:any) => response.json())
    .then((data:any)=>{
            dispatch(
                jobpoolSlice.actions.getJobPools(data)
              )      
    })
    .catch(err => console.log(err))
    }
}
