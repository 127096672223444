import React from 'react';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
export const getCurrentWeek = (values?:Dayjs):Date[] => {
    let curDate = values != undefined ? values : dayjs();
  let n=curDate.toDate().getDay();
  let myDateOfWeek:Date[] = [];
  switch(n){
    case 0:
      myDateOfWeek.push(
        curDate.add(1,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(2,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(3,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(4,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(5,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(6,'day').toDate()
      )
      break;
    case 1:
      myDateOfWeek.push(
        curDate.add(0,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(1,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(2,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(3,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(4,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(5,'day').toDate()
      );
      break;
    case 2:
      myDateOfWeek.push(
        curDate.add(-1,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(0,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(1,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(2,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(3,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(4,'day').toDate()
      );
      break;
    case 3:
      myDateOfWeek.push(
        curDate.add(-2,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(-1,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(0,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(1,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(2,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(3,'day').toDate()
      );
      break;
    case 4:
      myDateOfWeek.push(
        curDate.add(-3,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(-2,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(-1,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(0,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(1,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(2,'day').toDate()
      );      
      break;
    case 5:
      myDateOfWeek.push(
        curDate.add(-4,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(-3,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(-2,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(-1,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(0,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(1,'day').toDate()
      );     
      break;
    default:
      myDateOfWeek.push(
        curDate.add(-5,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(-4,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(-3,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(-2,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(-1,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(0,'day').toDate()
      );  
      break;
  }
  return myDateOfWeek;
}
export const getDateString = (value:Date):string =>{
  let myOutput = value.getFullYear() + '-'
  + (value.getMonth()+1<10?'0'+ (value.getMonth()+1)
  :value.getMonth()+1)+'-'
  + (value.getDate()<10?'0'+value.getDate()
  :value.getDate());
  return myOutput;
}
const offsetToTimeZoneFormat = (offset:number) => {
  const sign = offset < 0 ? '-' : '+';
  const hours = Math.abs(Math.floor(offset / 60));
  const minutes = Math.abs(offset % 60);
  return `${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};
export const getDateStringWithTimezone = (value:any):string =>{
  let myOutput = value.getFullYear() + '-'
  + (value.getMonth()+1<10?'0'+ (value.getMonth()+1)
  :value.getMonth()+1)+'-'
  + (value.getDate()<10?'0'+value.getDate()
  :value.getDate()) + ' ' + value.getHours() + ':' + value.getMinutes() + ':' + value.getSeconds()
  //let index = value.toString().indexOf('GMT');
  return myOutput + ' UTC+7';
}

