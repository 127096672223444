import React, { useEffect, useState } from 'react';
import { Space, Table, Tag, Button, Modal, Form, Input, Popconfirm, message, Switch } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useAppDispatch } from '../../configs/hook';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import {Admin} from '../../interface';
import axios from 'axios';
import {getAdmins, updateAdmin, deleteAdmin} from '../../layouts/admin/adminSlide';
import deleteImage from '../../assets/images/recyclebin.png';
import ChangePass from '../changePass';
import { isDisabled } from '@testing-library/user-event/dist/utils';
import { translate } from '../../helpers/translate';


const AdminUser = (props:{lang:string | null}) => {
  const [lang, setLang] = useState<string|null>('ger');
    const dispatch = useAppDispatch();
    const [isModalResetPassOpen, setIsModalResetPassOpen] = useState(false);
    const columns: ColumnsType<Admin> = [
        {
          title: translate(lang!=null?lang:'ger','Mã nhân viên'),
          dataIndex: 'manhansu',
          key: 'manhansu',
          hidden: localStorage.getItem('roleData') != '1' ? true : false
        },
        {
          title: translate(lang!=null?lang:'ger','Họ và tên'),
          dataIndex: 'hovaten',
          key: 'hovaten'
        },
        {
          title: translate(lang!=null?lang:'ger','Thư điện tử'),
          dataIndex: 'email',
          key: 'email'
        },
        {
          title: translate(lang!=null?lang:'ger','Số điện thoại'),
          dataIndex: 'dienthoai',
          key: 'dienthoai',
          render: (key:number, record:Admin, index:number) => {
            return(
            <>
            {localStorage.getItem('emailData') == record.email ?
            !record.isEditableDienthoai?
             <a onClick={()=>{
                 let index = data.findIndex(c=>c.manhansu==record.manhansu);
                  let prefix = data.slice(0, index);
                  let mySlice = {...data[index], isEditableDienthoai: true}
                  let suffix = data.slice(index+1);
                  setData([...prefix, mySlice, ...suffix])
             }}>{record.dienthoai}</a>
             :<input type="text" value={record.dienthoai}
             onChange={(e)=>{
              let index = data.findIndex(c=>c.manhansu==record.manhansu);
              let prefix = data.slice(0, index);
              let mySlice = {...data[index], dienthoai: e.currentTarget.value}
              let suffix = data.slice(index+1);
              setData([...prefix, mySlice, ...suffix])
             }}
             onKeyPress={(e)=>{
               if(e.key == "Enter"){
                setData(data.map(item=>{
                  if(item.manhansu==record.manhansu)
                    return {...item, isEditableDienthoai: false};
                  else
                    return item;
                }))
                dispatch(updateAdmin(record));
               }
             }}
             />
            : <p>{record.dienthoai}</p>
            }
            </>
            )
        }
        },
        {
          title: translate(lang!=null?lang:'ger','Tùy chọn'),
          dataIndex: 'delete',
          key: 'delete',
          render: (key:number, record:Admin, index:number) => {
            return(
              <>
            <Button type="primary" danger
            disabled = {localStorage.getItem('userRole') == '1' && record.hovaten != 'administrator' ? 
            false : true } 
            onClick = {()=>{
              setManhansu(record.manhansu);
              setIsConfirmModalOpen(true); 
            }}
            >{translate(lang!=null?lang:'ger','Xóa')}</Button>
            <Button type="primary"
            disabled = {localStorage.getItem('userRole') == '1' && record.hovaten != 'administrator' ? 
            false : true } 
            onClick = {()=>{
              setEmail(record.email);             
              setIsModalResetPassOpen(true); 
            }}
            >{translate(lang!=null?lang:'ger','Tạo lại mật khẩu')}</Button>
            </>
            ) 
          }
        }
      ].filter(x=>!x.hidden);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const handleConfirmOk = (item: string) => {
          dispatch(deleteAdmin(item));
          setData(data.filter(c=>c.manhansu!=item));
    }
    const handleConfirmCancel = () => {
      setIsConfirmModalOpen(false);
    }
    const handleOk = () => {
        setIsModalOpen(false);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
      };
      const showModal = () => {
        setIsModalOpen(true);
      };
    const myAdmins = useSelector((state:RootState)=>state.admins);
    const [manhansu, setManhansu] = useState("");
    const [data, setData] = useState(myAdmins);
    const [hovaten, setHovaten] = useState("");
    const [email, setEmail] = useState("");
    const [dienthoai, setDienthoai] = useState("");
    const onFinish = async (values: any) => {
      console.log(values);
      const token = localStorage.getItem('token');
      const urlStr = localStorage.getItem("serverName")
        + (localStorage.getItem('taxCode')=="All" ? 'api/Admin/' : 'api/Admin/companyadmin/');
      const myTaxCode:string | null = localStorage.getItem('taxCode');
      const formData = new FormData();
          formData.append("Manhansu", values.manhansu);
          formData.append("Hovaten", values.hovaten);
          formData.append("Email", values.email);
          formData.append("Password", "abc123!");
          formData.append("Dienthoai", values.dienthoai);
          formData.append("RoleAdmin", values.RoleAdmin?"1":"0");
          if(myTaxCode!=null)
            formData.append("TaxCode", myTaxCode)
          const config = {     
            headers: { 'content-type': 'multipart/form-data', 'Authorization': `Bearer ${token}` }
          }
          axios.post(urlStr,formData, config)
            .then(response => {
                  console.log(response);
                  message.info("Added new user successfully!");
            })
            .catch(error => {
                console.log(error);
            });       
        dispatch(getAdmins());
        setData([...data, {
          "manhansu": values.manhansu,
            "hovaten": values.hovaten,
            "email": values.email,
            "dienthoai": values.dienthoai,
            isEditableDienthoai: false,
            isEditableEmail: false
        }])
      };
      useEffect(()=>{
        setLang(localStorage.getItem('language'))
    },[localStorage.getItem('language')])
    
    return(
    <>
    <Button onClick={showModal}
    disabled = {localStorage.getItem('userRole') == '1' ? 
    false : true } 
    >{translate(lang!=null?lang:'ger','Thêm thành viên')}</Button>
     <Table columns={columns} dataSource={data} />
    <Modal title={translate(lang!=null?lang:'ger','Thêm thành viên')} open={isModalOpen} onCancel={handleCancel}
    footer={null}>
        <Form labelCol={{ span: 8 }}
        wrapperCol={{ span: 20 }}
        layout="horizontal"
        onFinish={onFinish} >
          {localStorage.getItem('roleData') == '1' ?
            <Form.Item label={translate(lang!=null?lang:'ger','Mã nhân viên')}
        name="manhansu"
        rules={[{ required: true, message: 'This field must not be empty!' }]}>
          <Input value={manhansu}
          onChange={(e)=>{
              setManhansu(e.currentTarget.value);
          }
        } />
        </Form.Item>
        : '' }
        <Form.Item label={translate(lang!=null?lang:'ger','Họ và tên')}
        name="hovaten"
        rules={[{ required: true, message: 'This field must not be empty!' },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (value!='administrator') {
              return Promise.resolve();
            }
            return Promise.reject(new Error('The text you entered not allowed!'));
          },
        }),
        ]}>
          <Input value={hovaten}
          onChange={(e)=>{
              setHovaten(e.currentTarget.value);
          }
        } />
        </Form.Item>
        <Form.Item label={translate(lang!=null?lang:'ger','Thư điện tử')} 
        name="email"
        rules={[{ required: true, message: 'This field must not be empty!' }]}>
          <Input value={email}
          onChange={(e)=>{
              setEmail(e.currentTarget.value);
          }
        } />
        </Form.Item>
        <Form.Item label={translate(lang!=null?lang:'ger','Số điện thoại')} 
        name="dienthoai"
        rules={[{ required: true, message: 'This field must not be empty!' }]}>
          <Input />
          </Form.Item>
        <Form.Item label={translate(lang!=null?lang:'ger','Quyền quản trị cao nhất')} name="RoleAdmin" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item label="" wrapperCol={{offset:4}}>
          <Button type="primary" htmlType="submit">{translate(lang!=null?lang:'ger','Lưu dữ liệu')}</Button>
        </Form.Item>
        </Form>
    </Modal>
    <Modal title={translate(lang!=null?lang:'ger','Xóa dữ liệu')} open={isConfirmModalOpen} onOk={()=>handleConfirmOk(manhansu)} onCancel={handleConfirmCancel}>
        <div style={{height:'110px', marginTop:'20px'}}>
          <div style={{float:'left', overflow:'hidden'}}>
            <img src={deleteImage} style={{width:'100px', height:'80px'}} />
          </div>
          <div style={{float:'left', fontSize:'24px', paddingLeft:'10px', color:'red'}}>
          {translate(lang!=null?lang:'ger','Bạn có chắc chắn xóa không?')}
          </div>
        </div>
      </Modal>
      <Modal title="Reset passwort" open={isModalResetPassOpen} onCancel={()=>setIsModalResetPassOpen(false)} footer={null}>
          <ChangePass lang={'ger'} reset={true} email={email} resetRole={localStorage.getItem('roleData')} />
      </Modal>
    </>
    )
}

export default AdminUser;