import React, {useState, useEffect} from 'react';
import logo from './logo.svg';
import Login from './layouts/login';
import './App.css';
import AdminLayout from './layouts/admin';
import {getAdmins} from './layouts/admin/adminSlide';
import { useAppDispatch } from './configs/hook';
import ChangePass from './components/changePass';
import {Routes, Route} from 'react-router-dom';
import RegisterLayout from './layouts/registers';
import EnterpriseLayout from './layouts/enterprise';
import EnterpriseVN from './layouts/enterprisevn';
import Guest from './layouts/guests';
import DeclareInfo from './layouts/declare';
function App() {
  const [updated, setUpdated] = useState(false);
  const dispatch = useAppDispatch();
  const handleRerenderApp = () => {
    setUpdated(!updated);
  }
  const getEmail = localStorage.getItem("emailData");
  const getRole = localStorage.getItem("roleData");
  const getPasswordStatus = localStorage.getItem("passwordStatus");
  const myComponent = ()=>{
    if(getPasswordStatus != 'true'){
      switch(getRole)
      {
        case "1":
          dispatch(getAdmins());
          return <AdminLayout />
        case "2":
          return <EnterpriseLayout />
        case "4":
          return <EnterpriseVN />
        case "3":
          return <Guest />
        default:
          return <Login reRenderApp={()=>handleRerenderApp()} />
      }
    }
    else{
      if(getPasswordStatus == null)
        return <Login reRenderApp={()=>handleRerenderApp()} />
      else
        return <DeclareInfo />
    }    
  }
  useEffect(()=>{
    const res = fetch("/api/api.json")
                    .then(response=>response.json())
                    .then(data=>{
                        localStorage.setItem("serverName",data.server);
                    })
  },[])
  useEffect(()=>{
    myComponent();
  },[getRole]);
  return (
    <>
      <Routes>
        <Route path='/' element={myComponent()} />
        <Route path='/register' 
        element={<RegisterLayout />} />
      </Routes>
    </>
  );
}

export default App;
