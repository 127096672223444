import React, { useEffect, useState } from 'react';
import {Table,Select, SelectProps, Button, message} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { InternType } from '../../interface';
export const FindCandidate = (props: {data: InternType[]}) => {
    const columns: ColumnsType<InternType> = [
        {
          title: 'Id',
          dataIndex: 'id',
          hidden: true,
          key: 'id',
        },
        {
          title: 'Candidate Name',
          dataIndex: 'internName',
          key: 'internName',
          render:(key:number, record:InternType, text:any)=>{
            return(
              <>
                {record.internName}
                <p style={{color:'blue'}}>{record.internStatus}</p>
              </>
            )
          }
        },
        {
          title: 'Gender',
          dataIndex: 'gender',
          key: 'gender'
        },
        {
          title: 'Birthday',
          dataIndex: 'dateOfBirth',
          key: 'dateOfBirth'
        },
        {
          title:'Major',
          dataIndex:'major',
          key: 'major'
        },
        {
          title:'Job applied',
          dataIndex:'internSpecialized',
          key:'internSpecialized'
        },
        {
          title:'Language level',
          dataIndex:'foreignLanguage',
          key:'foreignLanguage'
        },      
        {
            title: 'Download CV',
            dataIndex: 'download',
            key: 'download',
            render: (key:number, record:InternType, text:any) => {
              return(
              <a href='#'
              onClick = {()=>{
                console.log(record.fileUrl);
                const token = localStorage.getItem('token');
                fetch(localStorage.getItem("serverName")+'api/Register/files/' + record.fileUrl, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
            })
            .then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = record.fileUrl;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();  //afterwards we remove the element again
        });
            }}
              >Download CV</a>
              ) 
            }
          }
    ].filter(item=>!item.hidden);  
    const [data, setData] = useState<InternType[]>([]);     
    return(
    <>
     <Table columns={columns} dataSource={props.data} scroll={{ x: 1800, y: 800 }}
     style={{marginTop:10,width:'160%'}} />
    </>
    )
}