import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { useAppDispatch } from '../../configs/hook';
import getState from 'redux-thunk';
import { CompanyType, Monhoc } from '../../interface';
import axios from 'axios';
import { stat } from 'fs';


export const companySlice = createSlice({
    name:'company',
    initialState:[] as CompanyType[],
    reducers: ({
        getCompany: (state, action: PayloadAction<CompanyType[]>) => {
            state = [];
            return action.payload;
        },
    })
})

export const subjectSlice = createSlice({
    name:'subject',
    initialState:[] as Monhoc[],
    reducers: ({
        getSubject: (state, action: PayloadAction<Monhoc[]>) => {
            state = [];
            return action.payload;
        },
    })
})

export function getCompany(){
    return async function getCompanyThunk(dispatch: Dispatch, getState:any){
        //const token = localStorage.getItem('token');
        const rawResponse = await fetch(localStorage.getItem("serverName")+'api/Company/', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(response => response.json())
    .then((data)=>{
            dispatch(companySlice.actions.getCompany(data))
        console.log(data);       
    })
    .catch(err => console.log(err))
    }
}

export function getSubject(){
    return async function getSubjectThunk(dispatch: Dispatch, getState:any){
        //const token = localStorage.getItem('token');
        const rawResponse = await fetch(localStorage.getItem("serverName")+'api/Subject/', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
    }).then(response => response.json())
    .then((data)=>{
            console.log(data);
            dispatch(subjectSlice.actions.getSubject(data))
    })
    .catch(err => console.log(err))
    }
}