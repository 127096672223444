import React, {useEffect, useState} from 'react';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import IntroducedList from '../../components/introducedList';
import OurInternCv from '../../components/enterprisevn/ourInternCv'; 
import { TimeLineInterviews } from '../../components/timeline/interview';
import { useAppDispatch } from '../../configs/hook';
import { getInterview } from '../../components/timeline/interviewSlice';
import dayjs from 'dayjs';
import AdminUser from '../../components/admin';
import { getAdmins } from '../admin/adminSlide';
import { getCareer } from '../registers/careerNavigateSlice';
import VieStatistic from '../../components/statistic/vieEnterprise';
import { getPredefinedEvents } from './predefinedEventSlice';
import { getOverviewTasks } from '../../components/timeline/interviewSlice';
const { Header, Content, Footer } = Layout;

const EnterpriseVN: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [itemMenu, setItemMenu] = useState(1);
  useEffect(()=>{
    dispatch(getInterview(dayjs(new Date())));
    dispatch(getAdmins());
    dispatch(getCareer());
    dispatch(getPredefinedEvents());
    let month = (new Date()).getMonth();
  let year = (new Date()).getFullYear();
  let stdate = (month)<1?(year-1) + '-12-25': year + '-' + ((month)<10?'0'+(month):(month)) + '-25'
  let eddate = (month+2)>12?(year+1) + '-01-05': year + '-' + ((month+2)<10?'0'+(month+2):(month+2)) + '-05'
  dispatch(getOverviewTasks(stdate, eddate));
  },[])
  return (
    <Layout>
      <Header
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <div className="demo-logo" />
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['1']}
          items={[{id: 1, text: 'Danh sách ứng viên'}, {id:2, text:'Theo dõi ứng viên'},
        {id: 3, text: 'Xếp lịch phỏng vấn với Ty'}, {id: 4, text: 'Danh sách người dùng'},
        {id: 6, text: 'Thống kê'},
        {id: 5, text: 'Đăng xuất'}].map((item) => ({
            key: item.id,
            label: item.text,
            onClick: ()=>{
              if(item.id == 5){
                localStorage.clear();
                window.location.reload();
              }
              setItemMenu(item.id);
            }
          }))}
        />
      </Header>
      <Content className="site-layout" style={{ padding: '0 50px' }}>
        <div style={{ padding: 24, minHeight: 550, background: colorBgContainer }}>
          {itemMenu == 1 ? <IntroducedList /> :
          itemMenu == 2 ? <OurInternCv /> :
          itemMenu == 3 ? <TimeLineInterviews enterprise='TYP' lang='vie' /> :
          itemMenu == 6 ? <VieStatistic enterprise='TYP' lang='vie' /> :
          itemMenu == 4 ? <AdminUser lang={localStorage.getItem('language')} /> : ''
          }
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>Alta group - Trainning team ©2023</Footer>
    </Layout>
  );
};

export default EnterpriseVN;