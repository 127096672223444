import React, {useState, useEffect} from 'react';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import MyProfile from '../../components/myProfile';
import MyHistory from '../../components/myHistory';
import { useAppDispatch } from '../../configs/hook';
import { getIntern} from './internSlice';
import { getCareer } from '../registers/careerNavigateSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

const { Header, Content, Footer } = Layout;

const Guest: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [itemMenu, setItemMenu] = useState(2);
  const myProfileInfo = useSelector((state:RootState)=>state.intern);
  const myHistory = useSelector((state:RootState)=>state.histories);
  useEffect(()=>{
    dispatch(getIntern());
    dispatch(getCareer());
  },[])
  return (
    <Layout>
      <Header
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <div className="demo-logo" />
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['0']}
          items={[{id: 1, text: 'Hồ sơ của tôi'}, {id:2, text:'Lịch sử hoạt động'},
        {id: 3, text: 'Thay đổi thông tin'}, {id: 4, text: 'Đăng xuất'}].map((item) => ({
            key: item.id,
            label: item.text,
            onClick: ()=>{
              if(item.id == 4){
                localStorage.clear();
                window.location.reload();
              }
              setItemMenu(item.id);
            }
          }))}
        />
      </Header>
      <Content className="site-layout" style={{ padding: '0 50px' }}>
        <div style={{ padding: 24, minHeight: 410, background: colorBgContainer }}>
          {itemMenu == 1 ? <MyProfile lang='vie' myProfileInfo={myProfileInfo} 
          readOnlyOption={myHistory.findIndex((x:any)=>x.eventName==9)==-1?false:true}  /> :
          itemMenu == 2 ? <MyHistory /> : ''
          }
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>Alta group - Trainning team ©2023</Footer>
    </Layout>
  );
};
export default Guest;