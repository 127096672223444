import React, {useState, useEffect} from 'react';
import { Space, Table, Tag, Button, Radio, Row, Modal, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { CompanyType } from '../../interface';
import deleteImage from '../../assets/images/recyclebin.png';
import ChangePass from '../changePass';
import { translate } from '../../helpers/translate';
      
const EnterpriseList = (props:{lang:string | null}) => {
    const [data, setData] = useState<CompanyType[]>([]);
    const [lang, setLang] = useState<string|null>('ger');
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [resetRole, setResetRole] = useState('0');
    const [isModalResetPassOpen, setIsModalResetPassOpen] = useState(false);
    const [taxCode, setTaxCode] = useState('');
    const [country, setCountry] = useState('vn');
    const [searchData, setSearchData] = useState<CompanyType[]>([]);
    const token = localStorage.getItem('token');
    const columns: ColumnsType<CompanyType> = [
        {
          title: translate(lang!=null?lang:'ger','Mã số doanh nghiệp'),
          dataIndex: 'taxCode',
          render:(key:number, record:CompanyType, index:number)=>{
            return(
              <p style={{textDecorationLine:!record.companyEnabled?'line-through':'none'}}>{record.taxCode}</p>
            )
          }
        },
        {
          title: translate(lang!=null?lang:'ger','Tên công ty'),
          dataIndex: 'companyName',
          render:(key:number, record:CompanyType, index:number)=>{
            return(
              <p style={{textDecorationLine:!record.companyEnabled?'line-through':'none'}}>{record.companyName}</p>
            )
          }
        },
        {
          title: translate(lang!=null?lang:'ger','Địa chỉ công ty'),
          dataIndex: 'companyAddress',
          render:(key:number, record:CompanyType, index:number)=>{
            return(
              <p style={{textDecorationLine:!record.companyEnabled?'line-through':'none'}}>{record.companyAddress}</p>
            )
          }
        },
        {
            title: translate(lang!=null?lang:'ger','Số liên hệ công ty'),
            dataIndex: 'telephone',
            render:(key:number, record:CompanyType, index:number)=>{
              return(
                <p style={{textDecorationLine:!record.companyEnabled?'line-through':'none'}}>{record.telephone}</p>
              )
            }
        },
        {
          title: translate(lang!=null?lang:'ger','Tùy chọn'),
          dataIndex: 'delete',
          key: 'delete',
          render: (key, record:CompanyType, text) => {
            return(
              <>
            <Button type="primary" danger
            onClick = {()=>{
              setIsConfirmModalOpen(true);
              setTaxCode(record.taxCode);
            }}
            >{translate(lang!=null?lang:'ger','Xóa')}</Button>
            <Button type="primary"
            onClick = {()=>{
              setIsModalResetPassOpen(true);
              setTaxCode(record.taxCode);
              setResetRole('2');
            }}
            >{translate(lang!=null?lang:'ger','Tạo lại mật khẩu')}</Button>
            <Button onClick={()=>{
              fetch(localStorage.getItem("serverName")+'api/Company/changedstatus/' 
              + record.taxCode, 
              {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
          }).then(response => response.json())
          .then(()=>{
            getAllUniveristy(country);
          })
            }}>{record.companyEnabled?'Disable':'Enable'}</Button>
            </>
            ) 
          }
        }
      ];
      const getAllUniveristy = (country:string) => {
        fetch(localStorage.getItem("serverName")+'api/Company/companybycountry/'+country, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
    }).then(response => response.json())
    .then((data)=>{      
        setData(data);
        setSearchData(data);
    })
    .catch(err => console.log(err));
    }
    useEffect(()=>{
        getAllUniveristy('vn');
    },[])
    const handleConfirmOk = (taxCode:string) => {
      fetch(localStorage.getItem("serverName")+'api/Company/' + taxCode, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
}).then(response => response.json())
.then(data=>{
  message.info(data)
  getAllUniveristy(country);
})
setIsConfirmModalOpen(false);
}
const handleConfirmCancel = () => {
  setIsConfirmModalOpen(false);
}
const handleVnClick = ()=>{
    fetch(localStorage.getItem("serverName")+'api/Company/TYP', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
}).then(response => response.json())
.then((data)=>{      
    setData(data);
    setSearchData(data);            
})
.catch(err => console.log(err));
}
const handleGmClick = () => {
    fetch(localStorage.getItem("serverName")+'api/Company/TYG', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
}).then(response => response.json())
.then((data)=>{      
    setData(data); 
    setSearchData(data);           
})
.catch(err => console.log(err));
}
useEffect(()=>{
  setLang(localStorage.getItem('language'))
},[localStorage.getItem('language')])

return(
<>
<Button onClick={handleVnClick}>Create Vietname Partner</Button>
<Button onClick={handleGmClick}>Create Germany Partner</Button>&nbsp;&nbsp;
<Radio.Group defaultValue={'TYP'}
  onChange={(e) => {
    setCountry(e.target.value=='TYP'?'vn':'ger');
    getAllUniveristy(e.target.value=='TYP'?'vn':'ger');
  }}
>
  <Row justify="end">
    <Radio value={'TYP'}>Vietnam Partner</Radio>
    <Radio value={'TYG'}>Germany Partner</Radio>
  </Row>
</Radio.Group>
<Table columns={columns} dataSource={searchData} />
<Modal title="Delete data" open={isConfirmModalOpen} onOk={()=>handleConfirmOk(taxCode)} onCancel={handleConfirmCancel}>
        <div style={{height:'110px', marginTop:'20px'}}>
          <div style={{float:'left', overflow:'hidden'}}>
            <img src={deleteImage} style={{width:'100px', height:'80px'}} />
          </div>
          <div style={{float:'left', fontSize:'24px', paddingLeft:'10px', color:'red'}}>
          Are you sure to delete?
          </div>
        </div>
</Modal>
<Modal title="Reset passwort" open={isModalResetPassOpen} onCancel={()=>setIsModalResetPassOpen(false)} footer={null}>
    <ChangePass lang={'ger'} reset={true} email={taxCode} resetRole={resetRole} />
</Modal>
</>
)
}
export default EnterpriseList;