import React, { useState, useEffect } from 'react';
import { Table, Modal, Form, Input, Button, DatePicker, message, Select, SelectProps } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {translate} from '../../helpers/translate';
import MyProfile from '../myProfile';
import { useAppDispatch } from '../../configs/hook';
import { internSlice } from '../../layouts/guests/internSlice';
const { Search } = Input;
interface ExpandedDataType {
    id: number;
    eventName: string;
    eventDate: string;
    result: string;
    userCreated: string;
    internId: number;
    link: string
  }
export interface DataType {
  key: React.Key,
  id: number,
  internName: string,
  dateOfBirth: string,
  internMail: string,
  telephoneNum: string,
  internSpecialized: string,
  fileUrl: string,
  major: string,
  gender: string,
  father:string,
  mother: string,
  placeOfBirth: string,
  passport:string,
  imageDataUrl?: string,
  parentAddress: string,
  parentContact: string,
  socialMedia: string,
  university: string,
  address: string,
  citizenIdentification:string,
  citizenIdentificationDate:string,
  isEditableInternSpecialized: boolean,
  cVFil1Url: string,
  cmndUrl: string,
  passportUrl: string,
  graduatedUrl: string,
  internStatus:string,
  foreignLanguage: string,
  internable: string,
  enterprisevn: string,
  taxCode: string,
  isEnterprisevnEditable: boolean,
  idEnterpriseRegister: number
  internHistories: ExpandedDataType[]
}
const OurInternCv: React.FC = () => {
  const dispatch = useAppDispatch();
  const [options, setOptions] = useState<SelectProps['options']>([]);
  const [isEditableIntern, setIsEditableIntern] = useState(true);
  const [options1, setOptions1] = useState<SelectProps['options']>([]);
  const [isModalInternDetail, setIsModalInternDetail] = useState(false);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [internId, setInternId] = useState(-1);
    const [data, setData] = useState<DataType[]>([]);
    const [searchData, setSearchData] = useState<DataType[]>([]);
    const lang = localStorage.getItem("language")!=null?
                localStorage.getItem("language"):"en";
    const token = localStorage.getItem('token');
    const getData = () => {
    let myTimeZone = timeZone.replace('/', '-')
    fetch(localStorage.getItem("serverName")+'api/Statistic/' 
    + localStorage.getItem('taxCode') + '/' + myTimeZone, {
      method: 'GET',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
      },
}).then(response => response.json())
.then(async(data)=>{
  setData(data.map((item:any)=>{
    return {...item, gender: item.gender == '1' ? 'Nam' : 'Nữ'}
  }));
  setSearchData(data.map((item:any)=>{
    return {...item, gender: item.gender == '1' ? 'Nam' : 'Nữ'}
  }));
})      
}
    const handleChange = (value: number) => {
      if(value != -1)
          setSearchData(data.filter(x=>x.internSpecialized.includes(value.toString())));
      else
          setSearchData(data);
    }
    const handleChange1 = (value: string) => {
      let mySearchData:DataType[] = [];
      fetch(localStorage.getItem("serverName")+'api/Subject/enterprise/' 
  + value, {
    method: 'GET',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
}).then(response => response.json())
.then(async(datas)=>{
  console.log(datas)
  datas.map((item:any)=>{
      let rows = data.filter(x=>x.internSpecialized.includes(item.id.toString()));
      rows.map(row=>mySearchData.push(row));
  })
  let idArr:number[] = mySearchData.map(x=>{ return x.id})
  idArr = idArr.filter((value, index, array)=>array.indexOf(value)===index);
  setSearchData(data.filter(x=>idArr.includes(x.id)));
})
.catch(err => console.log(err));

    }
  const columns: ColumnsType<DataType> = [
    {
        title: 'Id',
        dataIndex: 'id',
        hidden: true,
        key: 'id'
      },
      {
        title: translate(lang!=null?lang:"en",'Tên ứng viên'),
        dataIndex: 'internName',
        key: 'internName',
        render:(key:number, record:DataType, index:number)=>{
          return(
            <>
              <a href='#' onClick={()=>{
                setInternId(record.id);
                if(record.internHistories.findIndex(x=>x.eventName=='Ankunft in DE')!=-1)
                    setIsEditableIntern(false);
                else
                    setIsEditableIntern(true);
                    dispatch(internSlice.actions.getIntern(searchData[searchData.findIndex(x=>x.id==record.id)]));
                setIsModalInternDetail(true)}}>{record.internName}</a>
            </>
          )
        }
      },
      {
        title: translate(lang!=null?lang:"en",'Ngày sinh'),
        dataIndex: 'dateOfBirth',
        key: 'dateOfBirth'
      },
      {
        title: translate(lang!=null?lang:"en",'Giới tính'),
        dataIndex: 'gender',
        key: 'gender'
      },
      {
        title: translate(lang!=null?lang:"en",'Email'),
        dataIndex: 'internMail',
        key: 'internMail'
      },
      {
        title:translate(lang!=null?lang:"en",'Chuyên ngành'),
        dataIndex:'major',
        key: 'major'
      },
      {
        title:translate(lang!=null?lang:"en",'Ngoại ngữ'),
        dataIndex:'foreignLanguage',
        key: 'foreignLanguage'
      },
      {
        title:translate(lang!=null?lang:"en",'Vị trí'),
        dataIndex:'internSpecialized',
        key:'internSpecialized'
      },      
      {
          title: 'Download CV',
          dataIndex: 'download',
          key: 'download',
          render: (key:any, record:DataType, index:number) => {
            return(
            <a href='#'
            onClick = {()=>{
              console.log(record.fileUrl);
              const token = localStorage.getItem('token');
              fetch(localStorage.getItem("serverName")+'api/Register/files/' + record.fileUrl, {
                  method: 'GET',
                  headers: {
                      'Authorization': `Bearer ${token}`,
                  },
          })
          .then(response => response.blob())
      .then(blob => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = record.fileUrl;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();  //afterwards we remove the element again
          fetch(localStorage.getItem("serverName")+'api/Register/seencv/'+record.id, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({id:record.id})
    }).then(response => {
        //getData();
    })
    .catch(err => console.log(err));         
      });
          }}
            >{translate(lang!=null?lang:"en",'Tải CV')}</a>
            ) 
          }
        }
].filter(item=>!item.hidden);
useEffect(()=>{
    getData();
},[])
const expandedRowRender = (record:DataType) => {
    const columns: ColumnsType<ExpandedDataType> = [
      { title: 'id', dataIndex: 'id', key: 'id', hidden: true },
      { title: 'Event Name', dataIndex: 'eventName', key: 'eventName' },     
      { title: 'Event Date', dataIndex: 'eventDate', key: 'eventDate' },
      { title: 'Responsible', dataIndex: 'userCreated', key: 'userCreated' },
      { title: 'Link', dataIndex: 'link', key: 'link'},
      { title: 'Result', dataIndex: 'result', key: 'result' }
      
    ].filter(a=>!a.hidden);
    return <Table columns={columns} 
    dataSource={searchData.filter(a=>a.id==record.id)[0].internHistories} pagination={false} />;
  };
  const getAllSubjects = async () => {
    const token = localStorage.getItem('token');
    let myOptions:any = [];
    const rawResponse = await fetch(localStorage.getItem("serverName")+'api/CareerNavigation', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
    }).then(response => response.json())
    .then((data)=>{
          myOptions.push({value: '-1', label: 'All'});
          data.map((item:any)=>{
            myOptions.push({value: item.abbreatation, label: item.navigationNameVn})
          })
          setOptions(myOptions);
          console.log(options);
    })
    .catch(err => console.log(err));
  }
  
  const onSearch = (value: string) => console.log(value);
  useEffect(()=>{
    getAllSubjects();
  },[])
  return (
    <>
      <div className="HeaderStyle">
      <Search addonBefore="Search by Candidate Name: " placeholder="input search text" 
      onSearch={onSearch} style={{ width: 400 }}
      onChange={(e)=>{
        setSearchData(data.filter(x=>x.internName.includes(e.currentTarget.value)))
      }}
      />
      Career applied: &nbsp;
      <Select
        defaultValue={-1}
        onChange={handleChange}
        options={options}
        style={{width:'25%'}}
        />
      </div>      
      <Table
        columns={columns}
        expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
        dataSource={searchData}
      />
      <Modal width={1000} title={translate(lang!=null?lang:'en',"Thông tin chi tiết")}
    open={isModalInternDetail} onOk={()=>setIsModalInternDetail(false)}
    >
      <div style={{height:'420px', marginTop:'10px'}}>
          <MyProfile lang='en' //myProfileInfo={searchData[searchData.findIndex(x=>x.id==internId)]}
          readOnlyOption={isEditableIntern?false:true}
          />
      </div>
    </Modal>
    </>
  );
};
export default OurInternCv;