import { OrderedListOutlined, UserOutlined, ControlOutlined, FunctionOutlined } from '@ant-design/icons';
import { MenuProps, Row, Col, SelectProps, Select } from 'antd';
import { useSelector } from 'react-redux';
import { Layout, Menu, Form } from 'antd';
import React, {useState, useEffect} from 'react';
import { RootState } from '../../redux/store';
import { useAppDispatch } from '../../configs/hook';
import AdminUser from '../../components/admin';
import { getOverviewTasks } from '../../components/timeline/interviewSlice';
import MyIntroducedCv from '../../components/introducedCv/myIntroducedCv';
import {PersonalInfo} from '../../components/personalInfo';
import EnterpriseList from '../../components/enterpriseList';
import { TimeLineInterviews } from '../../components/timeline/interview';
import { getInterview } from '../../components/timeline/interviewSlice';
import dayjs from 'dayjs';
import { getCareer } from '../registers/careerNavigateSlice';
import { translate } from '../../helpers/translate';
import {getJobPools} from '../enterprise/jobPoolSlice';
import JobPool from '../../components/jobpool';
import { getAllInterns } from '../guests/internSlice';
import CareerNavigation from '../../components/careerNavigation';
import { Dragdrop } from '../../components/events';
const { Header, Content, Sider } = Layout;
  
type SizeType = Parameters<typeof Form>[0]['size'];

const AdminLayout = () => {
const [collapsed, setCollapsed] = useState(true);
const [options, setOptions] = useState<SelectProps['options']>([
  {value:'vie', label:'Vietnamese'},{value:'en',label:'English'},{value:'ger', label:'German'}
]);
const [emailData, setEmailData] = useState(localStorage.getItem('emailData'));
const dispatch = useAppDispatch();
const myAdmins = useSelector((state:RootState)=>state.admins);
let index = myAdmins.findIndex(c=>c.email==emailData);
const [itemIndex, setItemIndex] = useState(1);
useEffect(()=>{
  let month = (new Date()).getMonth();
  let year = (new Date()).getFullYear();
  let stdate = (month)<1?(year-1) + '-12-25': year + '-' + ((month)<10?'0'+(month):(month)) + '-25'
  let eddate = (month+2)>12?(year+1) + '-01-05': year + '-' + ((month+2)<10?'0'+(month+2):(month+2)) + '-05'
  dispatch(getOverviewTasks(stdate, eddate));
  dispatch(getInterview(dayjs(new Date())));
  dispatch(getCareer());
  dispatch(getJobPools());
  dispatch(getAllInterns());
},[])
const item2List = [
{
  icon:OrderedListOutlined,
  text: 'List'
},
{
  icon: ControlOutlined,
  text: 'Configuration'
}
]
const [language, setLanguage] = useState('ger');
const items1: MenuProps['items'] = [translate(language!=null?language:'ger', 'Đổi mật khẩu và hình đại diện'), translate(language!=null?language:'ger', 'Danh mục nghề nghiệp'), 
translate(language!=null?language:'ger','Danh sách sự kiện'), translate(language!=null?language:'ger', 'Đăng xuất')].map((key, index) => ({
  key,
  label: key,
  onClick: ()=>{
      if(index==3)
        {
            localStorage.clear();
            window.location.reload();
        }
        setItemIndex(index);
  }
}));

const handleChange = (value: string) => {
  setLanguage(value);
  localStorage.setItem("language", value);
}
const items2: MenuProps['items'] = item2List.map(
    (icon, index) => {
      const key = String(index + 1);
      let myArr1 = [translate(language!=null?language:'ger','Người dùng'),translate(language!=null?language:'ger','Đối tác'), 
      translate(language!=null?language:'ger','Phân bổ việc làm')]
      let myArr2 = [translate(language!=null?language:'ger','Lịch phỏng vấn'),
      translate(language!=null?language:'ger','Theo dõi ứng viên')]
      switch(index) 
      {
      case 0:
      return {
        key: `sub${key}`,
        icon: React.createElement(item2List[index].icon),
        label: item2List[index].text,
        children: myArr1.map((item, j) => {
          const subKey = j+4;
          return {
            key: subKey,
            label: item,
            onClick: ()=>{
                setItemIndex(subKey);
          }
          };
        }),
      }
        default:
          return {
            key: `sub${key}`,
            icon: React.createElement(item2List[index].icon),
            label: item2List[index].text,
            children: myArr2.map((item, j) => {
              const subKey = j+7;
              return {
                key: subKey,
                label: item,
                onClick: ()=>{
                    setItemIndex(subKey);
              }
              };
            }),
          }
    }
  }
  );
return(
  <>
  <Layout>
    <Header className="header">
      <Row justify='space-between'>
      <Col style={{width:'60%'}}>
      <div className="logo">
      {/* <img src='logo.jpg' style={{width:'100px', height:'50px', marginRight:'5px', marginTop:'5px'}} /> */}
      </div>
      <Menu theme="dark" mode="horizontal"
      defaultSelectedKeys={['2']} 
      items={items1}
      />
      </Col>
      <Col style={{width:'40%'}}>
      <div style={{display:'inline', float:'right', position:'relative', marginRight:'0px', color:'white', fontWeight:'bold'}}>
      {translate(language,'Ngôn ngữ')}: &nbsp;
        <Select defaultValue={'ger'}
        onChange={handleChange} style={{width:'100px'}}
        options={options}
      />
      </div>
      <div style={{display:'inline', float:'right', position:'relative', marginRight:'0px', color:'white', fontWeight:'bold'}}>
      {myAdmins[index]?.hovaten}&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
      {myAdmins[index]?.imageDataUrl!=null?
        <img src={myAdmins[index].imageDataUrl} 
        style={{width:'40px', height:'40px', marginRight:'10px', float:'right', borderRadius:'100%', border:'2px solid white', marginTop:'10px'}} />:""}
      </Col>
      </Row>
    </Header>
    <Layout>
    <Sider width={300} className="site-layout-background" 
    collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}
    >
        <Menu
          mode="inline"
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['sub1']}
          style={{ height: '100%', borderRight: 0 }}
          items={items2}
        />
      </Sider>
      <Layout style={{ padding: '0 24px 24px' }}>
        <Content      
          className="site-layout-background"
          style={{
            padding: 20,
            margin: 0,
            minHeight: 280,
            overflowX:'scroll'
          }}
        >
          {itemIndex == 4 ? <AdminUser lang={localStorage.getItem('language')} />
          : itemIndex == 0 ? <PersonalInfo lang={localStorage.getItem('language')}/>
          : itemIndex == 2 ? <Dragdrop lang={localStorage.getItem('lang')}/>
          : itemIndex == 1 ? <CareerNavigation lang={localStorage.getItem('lang')} />
          : itemIndex == 5 ? <EnterpriseList lang={localStorage.getItem('language')} />
          : itemIndex == 6 ? <JobPool lang={localStorage.getItem('language')} />
          : itemIndex == 7 ? <TimeLineInterviews enterprise='All' lang={localStorage.getItem('language')} />
          : itemIndex == 8 ? <MyIntroducedCv lang={localStorage.getItem('lang')} />
          : ""
          }
        </Content>
      </Layout>
    </Layout>
  </Layout>
  </>
)
};
export default AdminLayout;