import React, {useEffect, useState} from 'react';
import { Button, Checkbox, Form, Input, message } from 'antd';
import { translate } from '../../helpers/translate';

const ChangePass = (props:{lang:string|null, reset:boolean, email:string|null, resetRole: string | null}) => {
  const [lang, setLang] = useState(props.lang);
  const onFinish = (values: any) => {
    console.log('Success:', values);
    if(values.newpassword != values.newpasswordConfirm){
        setMessage1("Your new password and confirmed password are not the same");
    }
    else{
        setMessage1("");
        const token = localStorage.getItem('token');
        const rawResponse = fetch(localStorage.getItem("serverName")+'api/User', {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
              },
          body: JSON.stringify({role:props.resetRole, userName:props.email, oldPassword:"", 
          newPassword:values.newpassword, mustChangeAtFirstTime: props.reset, companyName:values.CompanyName,
          telephone: values.Telephone, companyAddress: values.CompanyAddress, manager:values.Manager
        })
      }).then(response => response.json())
      .then((data)=>{
            localStorage.setItem("passwordStatus", props.reset?'true':'false');
            message.info("Password has been changed");
            if(!props.reset)
              window.location.reload();
      })
      .catch(err => console.log(err));
    }
  };
  const [message1, setMessage1] = useState("");
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  useEffect(()=>{
    setLang(localStorage.getItem('language'));
  },[localStorage.getItem('language')]);
  return (
    <Form
    style={{border: '1px solid gray', marginTop: 20, paddingTop:20}}
    layout="horizontal"
    labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label={translate(lang!=null?lang:'ger',"Mật khẩu mới:")}
        name="newpassword"
        rules={[{ required: true, message: translate(lang!=null?lang:'ger', 'Vui lòng điền mật khẩu của bạn') }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label={translate(lang!=null?lang:'ger',"Nhập lại mật khẩu:")}
        name="newpasswordConfirm"
        rules={[{ required: true, message: translate(lang!=null?lang:'ger', 'Vui lòng điền mật khẩu của bạn') }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item 
        style={{color: 'red'}}
        wrapperCol={{ offset: 8, span: 6 }}     
        >
        {message1!=''?
        <label>{message1}</label>:''}
        </Form.Item>
        {(localStorage.getItem("roleData")!='1' 
        && localStorage.getItem("roleData")!='3' && localStorage.getItem('userFullName') == "")?
          <>
              <Form.Item name="CompanyName"
      label={translate(lang!=null?lang:'ger','Tên doanh nghiệp:')}
      rules={[{required:true, message:translate(lang!=null?lang:'ger', 'Tên công ty không được để trống')}]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="Telephone"
      label={translate(lang!=null?lang:'ger','Điện thoại công ty:')}
      rules={[{required:true, message:translate(lang!=null?lang:'ger', 'Số điện thoại không được bỏ trống')}]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="CompanyAddress"
      label={translate(lang!=null?lang:'ger','Địa chỉ công ty:')}
      rules={[{required:true, message:translate(lang!=null?lang:'ger', 'Địa chỉ công ty không được bỏ trống')}]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="Manager"
      label={translate(lang!=null?lang:'ger','Người quản lý:')}
      rules={[{required:true, message:'Representative must be not null'}]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="ManagerTelephone"
      label={translate(lang!=null?lang:'ger','Điện thoại người quản lý:')}
      //rules={[{required:true, message:'Company name must be not null'}]}
      >
        <Input />
      </Form.Item>
          </>  
        : ""   
        }       
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          {translate(lang!=null?lang:'ger', 'Lưu dữ liệu')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ChangePass;