import React, {useState, ChangeEvent, useEffect} from "react";
import { useAppDispatch } from '../../configs/hook';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import './style.css';
import { Modal, Input } from "antd";
import axios from "axios";
import { getAdmins } from "../../layouts/admin/adminSlide";
import ChangePass from "../changePass";
import { translate } from "../../helpers/translate";

export const PersonalInfo = (props: {lang:string|null}) => {
  const [lang, setLang] = useState<string|null>('ger');
    const dispatch = useAppDispatch();
    const myAdmins = useSelector((state:RootState)=>state.admins);
    const [fileList, setFileList] = useState<File>();
    const [emailData, setEmailData] = useState(localStorage.getItem('emailData'));
    const [myadmin, setMyadmin] = useState(myAdmins.filter(c=>c.email==emailData)[0]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    useEffect(()=>{
        setMyadmin(myAdmins.filter(c=>c.email==emailData)[0]);
    },myAdmins);
    useEffect(()=>{
      setLang(localStorage.getItem('language'))
  },[localStorage.getItem('language')])
  
    return(
        <>
            <img src={myadmin.imageDataUrl} 
            style={{width:'300px', height:'400px'}}
            onClick={()=>{
                setIsModalOpen(true);
            }}
            />
            {localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
            <div style={{float:'right', paddingLeft:'20px',
            border:'2px solid gray', paddingTop:'10px'}}>
            <ChangePass lang={lang!=null?lang:'ger'} reset={false} email={localStorage.getItem('emailData')} 
            resetRole={localStorage.getItem('roleData')}/> 
            </div>
            : ""}
            <Modal title={translate(lang!=null?lang:'ger', 'Thay đổi ảnh đại diện')} 
            okButtonProps={{
              children: "Custom OK"
              }}
              cancelButtonProps={{
              children: "Custom cancel"
              }}
              okText="OK"
              cancelText={translate(lang!=null?lang:'ger', 'Hủy')}
            open={isModalOpen} onOk={()=>{
                const token = localStorage.getItem('token');
                const formData = new FormData();
                if(fileList)
                {
                    formData.append("ImageData", fileList);
                    formData.append("Manhansu", myadmin.manhansu);
                    formData.append("Hovaten", myadmin.hovaten);
                    formData.append("Email", myadmin.email);
                    formData.append("Dienthoai", myadmin.dienthoai);
                    
            const config = {     
              headers: { 'content-type': 'multipart/form-data', 'Authorization': `Bearer ${token}` }
            }
             axios.put(localStorage.getItem("serverName")+'api/Admin/'+ myadmin.manhansu, formData, config)
              .then(response => {
                dispatch(getAdmins());
                console.log(response);
              })
              .catch(error => {
                  console.log(error);
              });
            setIsModalOpen(false);      
          }}}
            onCancel={()=>setIsModalOpen(false)} >
              {translate(lang!=null?lang:'ger', 'Chọn ảnh')} &nbsp;&nbsp;
              <label htmlFor="file-upload" className="custom-file-upload">
                      {translate(lang!=null?lang:'ger', 'Chọn tệp')}
              </label>
              <input type="file" id="file-upload" style={{display: "none"}}
              
              onChange={(e:ChangeEvent<HTMLInputElement>)=>{
          const temp = e.target.files;
          if(!temp) 
            return;
          else
            setFileList(temp[0])}} />
            </Modal>
        </>
    )
}