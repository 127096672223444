import React, { useState, useEffect } from 'react';
import { Table, Button,message, Select, SelectProps } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { InternType } from '../../interface';
import {translate} from '../../helpers/translate';
import axios from 'axios';
import { getDateString } from '../../helpers';

interface ExpandedDataType {
    id: number;
    eventName: string;
    eventDate: string;
    result: string;
    userCreated: string;
    internId: number;
    link: string;
    isLinkEditable: boolean;
  }
interface DataType {
  key: React.Key,
  id: number,
  internName: string,
  dateOfBirth: string,
  internMail: string,
  telephoneNum: string,
  internable: string,
  internSpecialized: string,
  fileUrl: string,
  major: string,
  gender: string,
  father:string,
  mother: string,
  placeOfBirth: string,
  passport:string,
  imageDataUrl?: string,
  parentAddress: string,
  parentContact: string,
  socialMedia: string,
  university: string,
  address: string,
  citizenIdentification:string,
  citizenIdentificationDate:string,
  isEditableInternSpecialized: boolean,
  cVFil1Url: string,
  cmndUrl: string,
  passportUrl: string,
  graduatedUrl: string,
  internStatus:string,
  foreignLanguage: string,
  taxCode: string,
  enterprisevn: string,
  isEnterprisevnEditable: boolean,
  idEnterpriseRegister: number
}
const MyHistory: React.FC = () => {
    const [subdata, setSubdata] = useState<ExpandedDataType[]>([]);
    const [data, setData] = useState<InternType[]>([]);
    const lang = localStorage.getItem("language")!=null?
                localStorage.getItem("language"):"en";
    const token = localStorage.getItem('token');
    const getData = () => {
      fetch(localStorage.getItem("serverName")+'api/Register/', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
  }).then(response => response.json())
  .then(async(data)=>{
    console.log(data);
  //let tempData: InternType[] = [];
  // await data.map((item:any)=>{
  //   tempData.push({
  //     key: data.id,
  //           id: data.id,
  //           internName:data.internName,
  //           dateOfBirth:data.dateOfBirth,
  //           internMail:data.internMail,
  //           imageDataUrl:data.imageDataUrl,
  //           telephoneNum:data.telephoneNum,
  //           internSpecialized:data.internSpecialized,
  //           fileUrl:data.fileUrl,
  //           father: data.father,
  //           mother:data.mother,
  //           parentAddress: data.parentAddress,
  //           parentContact: data.parentContact,
  //           placeOfBirth: data.placeOfBirth,
  //           passport: data.passport,
  //           socialMedia: data.socialMedia,
  //           address: data.address,
  //           cVFil1Url: data.cVFil1Url,
  //           cmndUrl: data.cmndUrl,
  //           passportUrl: data.passportUrl,
  //           graduatedUrl: data.graduatedUrl,
  //           major:data.major,
  //           gender:data.gender,
  //           internStatus: data.internStatus,
  //           foreignLanguage: data.foreignLanguage,
  //           isEditableInternSpecialized: false,
  //           university: data.university,
  //           internable: data.internable,
  //           citizenIdentification: data.citizenIdentification == null ? 'Không có dữ liệu' : data.citizenIdentification,
  //           citizenIdentificationDate: data.citizenIdentificationDate == null ? 'Không có dữ liệu' : data.citizenIdentification == null ? 'Không có dữ liệu' : data.citizenIdentificationDate,
  //   })    
  setData(data.filter((x:any)=>x.internMail == localStorage.getItem('emailData')));})
  }
const getSubdata = () => {
    fetch(localStorage.getItem("serverName")+'api/InternHistory/getallinternhistory/', {
      method: 'GET',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
      }
  }).then(response => response.json())
  .then(async(data)=>{
    console.log(data);
    setSubdata(data.map((item:any)=>{
      return {
        id: item.id,
        eventName: item.eventName,
        eventDate: item.eventDate,
        result: item.result,
        userCreated: item.userCreated,
        internId: item.internId,
        link: item.link,
        isLinkEditable: false
      }
    }))
  })
  .catch(err => console.log(err));
  }
  const columns: ColumnsType<DataType> = [
    {
        title: 'Id',
        dataIndex: 'id',
        hidden: true,
        key: 'id'
    },
      {
        title: translate(lang!=null?lang:"en",'Tên ứng viên'),
        dataIndex: 'internName',
        key: 'internName',
        render:(key:number, record:InternType, text:any)=>{
          return(
            <>
              {record.internName}
              <p style={{color:'blue'}}>{record.internStatus}</p>
            </>
          )
        }
      },
      {
        title: translate(lang!=null?lang:"en",'Ngày sinh'),
        dataIndex: 'dateOfBirth',
        key: 'dateOfBirth'
      },
      {
        title: translate(lang!=null?lang:"en",'Giới tính'),
        dataIndex: 'gender',
        key: 'gender'
      },
      {
        title: translate(lang!=null?lang:"en",'Email'),
        dataIndex: 'internMail',
        key: 'internMail'
      },
      {
        title:translate(lang!=null?lang:"en",'Chuyên ngành'),
        dataIndex:'major',
        key: 'major'
      },
      {
        title:translate(lang!=null?lang:"en",'Ngoại ngữ'),
        dataIndex:'foreignLanguage',
        key: 'foreignLanguage'
      },
      {
        title:translate(lang!=null?lang:"en",'Vị trí'),
        dataIndex:'internSpecialized',
        key:'internSpecialized'
      },      
      {
          title: 'Download CV',
          dataIndex: 'download',
          key: 'download',
          render: (key:number, record:InternType, text:any) => {
            return(
            <a href='#'
            onClick = {()=>{
              console.log(record.fileUrl);
              const token = localStorage.getItem('token');
              fetch(localStorage.getItem("serverName")+'api/Register/files/' + record.fileUrl, {
                  method: 'GET',
                  headers: {
                      'Authorization': `Bearer ${token}`,
                  },
          })
          .then(response => response.blob())
      .then(blob => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = record.fileUrl;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();  //afterwards we remove the element again
          fetch(localStorage.getItem("serverName")+'api/Register/seencv/'+record.id, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({id:record.id})
    }).then(response => {
        //getData();
    })
    .catch(err => console.log(err));         
      });
          }}
            >{translate(lang!=null?lang:"en",'Tải CV')}</a>
            ) 
          }
        }
].filter(item=>!item.hidden);
useEffect(()=>{
    getData();
    getSubdata();
},[])
const expandedRowRender = (record:DataType) => {
    const columns: ColumnsType<ExpandedDataType> = [
      { title: 'id', dataIndex: 'id', key: 'id', hidden: true },
      { title: 'Event Name', dataIndex: 'eventName', key: 'eventName' },
      
      { title: 'Event Date', dataIndex: 'eventDate', key: 'eventDate' },
      { title: 'Responsible', dataIndex: 'userCreated', key: 'userCreated' },
      { title: 'Link', dataIndex: 'link', key: 'link',
        render:(key:any, record:any, index:number)=>{
          return(          
            <a 
            >{record.link!=null?record.link:''}</a>
          )
        } 
      },
      { title: 'Result', dataIndex: 'result', key: 'result' }
    ].filter(a=>!a.hidden);
    return <Table columns={columns} 
        dataSource={subdata.filter(a=>a.internId==record.id)} pagination={false} />;
  }; 
  return (
    <>
      <Table
        columns={columns}
        expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
        dataSource={data}
      />
    </>
  );
};
export default MyHistory;