import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { getCurrentWeek } from '../../helpers';
import { OverviewTasks } from '../../interface';
import type { Dayjs } from 'dayjs';

type itemTypes = {id:string, content: string, candidates: string, interviewDate: string,
  interviewUser: string, interviewLink: string, result:string, style: string[]}
type listWeekDayTaskType = {
    weekday: string, data: itemTypes[]
}[]
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const listWeekDayTask = [
    [{weekday: 'monday', data: [] as itemTypes[]},
    {weekday: 'tuesday', data: [] as itemTypes[]},
    {weekday: 'wednesday', data: [] as itemTypes[]},
    {weekday: 'thursday', data: [] as itemTypes[]},
    {weekday: 'friday', data: [] as itemTypes[]},
    {weekday: 'saturday', data: [] as itemTypes[]}],
   
    [{weekday: 'monday', data: [] as itemTypes[]},
    {weekday: 'tuesday', data: [] as itemTypes[]},
    {weekday: 'wednesday', data: [] as itemTypes[]},
    {weekday: 'thursday', data: [] as itemTypes[]},
    {weekday: 'friday', data: [] as itemTypes[]},
    {weekday: 'saturday', data: [] as itemTypes[]}],
]
export const interviewSlice = createSlice({
    name:'interview',
    initialState:listWeekDayTask,
    reducers: ({
        getInterview: (state, action: PayloadAction<listWeekDayTaskType[]>) => {
            state = [];
            return action.payload;
        }
    })
})
export const overviewTaskSlice = createSlice({
  name:'overviewTask',
  initialState:[] as OverviewTasks[],
  reducers: ({
      getOverviewTasks: (state, action: PayloadAction<OverviewTasks[]>) => {
          return action.payload;
      }
  })
})
export function getOverviewTasks(stDate: string, edDate: string){
  return async function getOverviewTasksThunk(dispatch:Dispatch, getState:any){
    const token = localStorage.getItem('token');
    let myTimeZone = timeZone.replace('/', '-')
    console.log(stDate);
    console.log(edDate);
    let myArrList : OverviewTasks[] = [];
    fetch(localStorage.getItem("serverName")+'api/InternHistory/getallinterviewsbytaxcode/'
    + (localStorage.getItem('roleData') != '1' ? localStorage.getItem("taxCode") : '1') + '/' +stDate+'/'+edDate + '/' + myTimeZone, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
  }).then(response => response.json())
  .then((data)=>{
      console.log(data);
      myArrList = [];
      data.map((item:any)=>item.eventDate.substring(0,10))//filter((value:any, index:number, array:any)=>array.indexOf(value))
      .map((row:any)=>{
        console.log(row)
        myArrList.push({countEvents: 1, eventDate: new Date(row)})
      })
  }).then(async ()=>{
    console.log(myArrList);
    await dispatch(overviewTaskSlice.actions.getOverviewTasks(myArrList))}
  )
  }
}
export function getInterview(data:Dayjs){
    return async function getTimelineThunk(dispatch: Dispatch, getState:any){
        const token = localStorage.getItem('token');
        let myTimeZone = timeZone.replace('/', '-')
        let myNewArrLst = [] as listWeekDayTaskType[];
        let stDate = await getCurrentWeek(data)[0].getFullYear() + '-'
  + (getCurrentWeek(data)[0].getMonth()+1<10?'0'+ (getCurrentWeek(data)[0].getMonth()+1)
  :getCurrentWeek(data)[0].getMonth()+1)+'-'
  + (getCurrentWeek(data)[0].getDate()<10?'0'+getCurrentWeek(data)[0].getDate()
  :getCurrentWeek(data)[0].getDate());
  let edDate = await getCurrentWeek(data)[5].getFullYear() + '-'
  + (getCurrentWeek(data)[5].getMonth()+1<10?'0'+ (getCurrentWeek(data)[5].getMonth()+1)
  :getCurrentWeek(data)[5].getMonth()+1)+'-'
  + (getCurrentWeek(data)[5].getDate()<10?'0'+getCurrentWeek(data)[5].getDate()
  :getCurrentWeek(data)[5].getDate());
        fetch(localStorage.getItem("serverName")+'api/InternHistory/getallinterviewsbytaxcode/'
        + (localStorage.getItem('roleData') != '1' ? localStorage.getItem("taxCode") : '1') + '/' +stDate+'/'+edDate + '/'+myTimeZone, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
      }).then(response => response.json())
      .then((data)=>{
          console.log(data);
          myNewArrLst = [];
          for(let i=0; i<=1; i++){
            myNewArrLst.push(setValue(i, data));
          }
      }).then(async ()=>{
        await dispatch(interviewSlice.actions.getInterview(myNewArrLst))}
      )
    }
    }
export const setValue = (i:number, data:any) => {
    let dayPart1 = i==0 ? data.filter((x:any)=>Number(x.eventDate.substring(11,13))<12)
    : data.filter((x:any)=>Number(x.eventDate.substring(11,13))>=12)
    let myNewList = listWeekDayTask[i];
    if(dayPart1 == null)
        myNewList = [{weekday: 'monday', data: [] as itemTypes[]},
      {weekday: 'tuesday', data: [] as itemTypes[]},
      {weekday: 'wednesday', data: [] as itemTypes[]},
      {weekday: 'thursday', data: [] as itemTypes[]},
      {weekday: 'friday', data: [] as itemTypes[]},
      {weekday: 'saturday', data: [] as itemTypes[]}]
    else{
      let mondayTasks = dayPart1.filter((x:any)=>(new Date(x.eventDate)).getDay()==1)
      let tuesdayTasks = dayPart1.filter((x:any)=>(new Date(x.eventDate)).getDay()==2)
      let wednesdayTasks = dayPart1.filter((x:any)=>(new Date(x.eventDate)).getDay()==3)
      let thursdayTasks = dayPart1.filter((x:any)=>(new Date(x.eventDate)).getDay()==4)
      let fridayTasks = dayPart1.filter((x:any)=>(new Date(x.eventDate)).getDay()==5)
      let saturdayTasks = dayPart1.filter((x:any)=>(new Date(x.eventDate)).getDay()==6)
      console.log(saturdayTasks);
      myNewList = [{weekday: 'monday', data: mondayTasks.map((item:any)=>{
        let myIdStr = '';
        let myResult = '';
        let myInternNames: string[] = Object.values(item.interns);
        let displayInternNames: string = '';
        myInternNames.map((x:string, index:number)=>{
          displayInternNames = displayInternNames + (index==(myInternNames.length - 1) ? x : (x + ', '));
        })
        item.id.map((x:any)=>{myIdStr += x+'_'});
        item.result.map((x:any)=>{myResult += x+'_'});
        let myStyle:string[] = [/*(item.Result == 'Passed' || item.Result == 'Failed')?'line-through':'None',*/
          (new Date()>new Date(item.eventDate))?'red':'black'] 
          return(
            {id:myIdStr, content: item.eventName, candidates: displayInternNames, result: myResult,
              interviewDate: item.eventDate, interviewUser: item.userCreated, interviewLink: item.link, style: myStyle
          }
          )
      })},
      {weekday: 'tuesday', data: tuesdayTasks.map((item:any)=>{
        let myIdStr = '';
        let myResult = '';
        let myInternNames: string[] = Object.values(item.interns);
        let displayInternNames: string = '';
        myInternNames.map((x:string, index:number)=>{
          displayInternNames = displayInternNames + (index==(myInternNames.length - 1) ? x : (x + ', '));
        })
        item.id.map((x:any)=>{myIdStr += x+'_'});
        item.result.map((x:any)=>{myResult += x+'_'});
        let myStyle:string[] = [/*(item.Result == 'Passed' || item.Result == 'Failed')?'line-through':'None',*/
          (new Date()>new Date(item.eventDate))?'red':'black'] 
          return(
            {id:myIdStr, content: item.eventName, candidates: displayInternNames, result: myResult,
              interviewDate: item.eventDate, interviewUser: item.userCreated, interviewLink: item.link, style: myStyle
          }
          )
      })},
      {weekday: 'wednesday',data: wednesdayTasks.map((item:any)=>{
        console.log(item.interns)
        let myIdStr = '';
        let myResult = '';
        let myInternNames: string[] = Object.values(item.interns);
        let displayInternNames: string = '';
        myInternNames.map((x:string, index:number)=>{
          displayInternNames = displayInternNames + (index==(myInternNames.length - 1) ? x : (x + ', '));
        })
        item.id.map((x:any)=>{myIdStr += x+'_'});
        item.result.map((x:any)=>{myResult += x+'_'});
        let myStyle:string[] = [/*(item.Result == 'Passed' || item.Result == 'Failed')?'line-through':'None',*/
          (new Date()>new Date(item.eventDate))?'red':'black'] 
          return(
            {id:myIdStr, content: item.eventName, candidates: displayInternNames, result: myResult,
              interviewDate: item.eventDate, interviewUser: item.userCreated, interviewLink: item.link, style: myStyle
          }
          )
      })},
      {weekday: 'thursday', data: thursdayTasks.map((item:any)=>{
        let myIdStr = '';
        let myResult = '';
        let myInternNames: string[] = Object.values(item.interns);
        let displayInternNames: string = '';
        myInternNames.map((x:string, index:number)=>{
          displayInternNames = displayInternNames + (index==(myInternNames.length - 1) ? x : (x + ', '));
        })
        item.id.map((x:any)=>{myIdStr += x+'_'});
        item.result.map((x:any)=>{myResult += x+'_'});
        let myStyle:string[] = [/*(item.Result == 'Passed' || item.Result == 'Failed')?'line-through':'None',*/
          (new Date()>new Date(item.eventDate))?'red':'black'] 
          return(
            {id:myIdStr, content: item.eventName, candidates: displayInternNames, result: myResult,
              interviewDate: item.eventDate, interviewUser: item.userCreated, interviewLink: item.link, style: myStyle
          }
          )
      })},
      {weekday: 'friday', data: fridayTasks.map((item:any)=>{
        let myIdStr = '';
        let myResult = '';
        let myInternNames: string[] = Object.values(item.interns);
        let displayInternNames: string = '';
        myInternNames.map((x:string, index:number)=>{
          displayInternNames = displayInternNames + (index==(myInternNames.length - 1) ? x : (x + ', '));
        })
        item.id.map((x:any)=>{myIdStr += x+'_'});
        item.result.map((x:any)=>{myResult += x+'_'});
        let myStyle:string[] = [/*(item.Result == 'Passed' || item.Result == 'Failed')?'line-through':'None',*/
          (new Date()>new Date(item.eventDate))?'red':'black'] 
          return(
            {id:myIdStr, content: item.eventName, candidates: displayInternNames, result: myResult,
              interviewDate: item.eventDate, interviewUser: item.userCreated, interviewLink: item.link, style: myStyle
          }
          )
      })},
      {weekday: 'saturday', data: saturdayTasks.map((item:any)=>{
        let myIdStr = '';
        let myResult = '';
        let myInternNames: string[] = Object.values(item.interns);
        let displayInternNames: string = '';
        myInternNames.map((x:string, index:number)=>{
          displayInternNames = displayInternNames + (index==(myInternNames.length - 1) ? x : (x + ', '));
        })
        item.id.map((x:any)=>{myIdStr += x+'_'});
        item.result.map((x:any)=>{myResult += x+'_'});
        let myStyle:string[] = [/*(item.Result == 'Passed' || item.Result == 'Failed')?'line-through':'None',*/
          (new Date()>new Date(item.eventDate))?'red':'black'] 
          return(
            {id:myIdStr, content: item.eventName, candidates: displayInternNames, result: myResult,
              interviewDate: item.eventDate, interviewUser: item.userCreated, interviewLink: item.link, style: myStyle
          }
          )
      })}]
    }
    return myNewList;
  }
