import { Calendar, theme, Modal, Button, message, 
  Select, Form, DatePicker, TimePicker, Radio, Input } from 'antd';
import type { CalendarMode } from 'antd/es/calendar/generateCalendar';
import CustomCalendar from '../../assets/guiComponents/customCalendar';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import React, {useState, useEffect} from 'react';
import {getCurrentWeek, getDateString} from '../../helpers';
import FormItem from 'antd/es/form/FormItem';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useAppDispatch } from '../../configs/hook';
import type { SelectProps } from 'antd';
import axios from 'axios';
import { getInterview } from './interviewSlice';
import { translate } from '../../helpers/translate';
import {getDateStringWithTimezone} from '../../helpers/';
import './style.css';
const onPanelChange = (value: Dayjs, mode: CalendarMode) => {
  console.log(value.format('YYYY-MM-DD'), mode);
};
type listWeekDayTaskType = {
  weekday: string, data: itemTypes[]
}[]

type itemTypes = {id:string, content: string, candidates: string, interviewDate: string,
  interviewUser: string, interviewLink: string, style: string[]}

export const TimeLineInterviews = (props:{enterprise:string, lang:string | null}) => {
  const { token } = theme.useToken();
  const mytoken = localStorage.getItem('token');
  const [id, setId] = useState('');
  const [historyId, setHistoryId] = useState<number[]>([]);
  const [student, setStudent] = useState<{name:string,result:string}[]>([]);
  const [lang, setLang] = useState<string|null>('ger');
  function dateFullCellRender(date:Dayjs) {
    console.log(currOverviewTasks);
    const targetDate:Date[] = currOverviewTasks.map(row=>row.eventDate);
    let formatedResult = false;
    targetDate.map((item=>{
      if (date.isSame(item, 'day')) {
        formatedResult = true;
      }
    })) 
    return formatedResult?<div className="special-date">{date.date()}</div>: date.date();
  }
  const [columns, setColumns] = useState([
    {
      title: '',
      dataIndex: 'dayPart',
      key: 'dayPart',
    },
    {
      title: translate(props.lang == null? 'ger':props.lang, 'Thứ hai') + '(' + getDateString(getCurrentWeek()[0]) + ')',
      dataIndex: 'monday',
      key: 'monday',
    },
    {
        title:translate(props.lang == null? 'ger':props.lang, 'Thứ ba') + '(' + getDateString(getCurrentWeek()[1]) + ')',
        dataIndex: 'tuesday',
        key: 'tuesday',
      },
      {
        title:translate(props.lang == null? 'ger':props.lang, 'Thứ tư') + '(' + getDateString(getCurrentWeek()[2]) + ')',
        dataIndex: 'wednesday',
        key: 'wednesday',
      },
      {
        title:translate(props.lang == null? 'ger':props.lang, 'Thứ năm') + '(' + getDateString(getCurrentWeek()[3]) + ')',
        dataIndex: 'thursday',
        key: 'thursday',
      },
      {
        title:translate(props.lang == null? 'ger':props.lang, 'Thứ sáu') +'(' + getDateString(getCurrentWeek()[4]) + ')',
        dataIndex: 'friday',
        key: 'friday',
      },
      {
        title:translate(props.lang == null? 'ger':props.lang, 'Thứ bảy') + '(' + getDateString(getCurrentWeek()[5]) + ')',
        dataIndex: 'saturday',
        key: 'saturday',
      }
  ]);
  const dispatch = useAppDispatch();
  const [dataSource, setDataSource] = useState([
    {
      dayPart: translate(props.lang!=null?props.lang:'ger', 'Buổi sáng'),
      monday: '',
      tuesday: '',
      wednesday:'',
      thursday:'',
      friday: '',
      saturday:''
    },
    {
      dayPart:translate(props.lang!=null?props.lang:'ger', 'Buổi chiều'),
      monday: '',
      tuesday: '',
      wednesday:'',
      thursday:'',
      friday: '',
      saturday:''
    }
]);

const [myDateOfWeek, setMyDateOfWeek] = useState<Date[]>(getCurrentWeek);
const [currentDate, setCurrentDate] = useState(dayjs(new Date()));
const handleChangeDate = (value: Dayjs) => {
setColumns([
{
  title: '',
  dataIndex: 'dayPart',
  key: 'dayPart',
},
{
  title: translate(props.lang == null? 'ger':props.lang, 'Thứ hai') + '(' + getDateString(getCurrentWeek(value)[0]) + ')',
  dataIndex: 'monday',
  key: 'monday',
},
{
    title: translate(props.lang == null? 'ger':props.lang, 'Thứ ba') + '(' + getDateString(getCurrentWeek(value)[1]) + ')',
    dataIndex: 'tuesday',
    key: 'tuesday',
  },
  {
    title: translate(props.lang == null? 'ger':props.lang, 'Thứ tư') + '(' + getDateString(getCurrentWeek(value)[2]) + ')',
    dataIndex: 'wednesday',
    key: 'wednesday',
  },
  {
    title: translate(props.lang == null? 'ger':props.lang, 'Thứ năm') + '(' + getDateString(getCurrentWeek(value)[3]) + ')',
    dataIndex: 'thursday',
    key: 'thursday',
  },
  {
    title: translate(props.lang == null? 'ger':props.lang, 'Thứ sáu') + '(' + getDateString(getCurrentWeek(value)[4]) + ')',
    dataIndex: 'friday',
    key: 'friday',
  },
  {
    title: translate(props.lang == null? 'ger':props.lang, 'Thứ bảy') + '(' + getDateString(getCurrentWeek(value)[5]) + ')',
    dataIndex: 'saturday',
    key: 'saturday',
  }
])
}
const onChange = (value: Dayjs) => {
  setCurrentDate(value);
  dispatch(getInterview(value));
  handleChangeDate(value);
};
const items:itemTypes[]=[];
const myTimeLine = useSelector((state:RootState)=>state.interview);
const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const [item, setItem] = useState<itemTypes[]>(items);
const myAdmins = useSelector((state:RootState)=>state.admins);
const currOverviewTasks = useSelector((state:RootState)=>state.overviewTasks);
const [optionsIntern, setOptionsIntern] = useState<SelectProps['options']>([]);
const [optionsEventPredefine, setOptionsEventPredefine] = useState<SelectProps['options']>([]);
const [optionsInterviewer, setOptionsInterviewer] = useState<SelectProps['options']>([]);
const [optionsResult, setOptionsResult] = useState<SelectProps['options']>([
  {value:'Pending', label: 'Pending'},
  {value:'Passed', label: 'Passed'},
  {value:'Failed', label: 'Failed'}
]);
const handleChange =()=>{

}
const handleChange1 =()=>{

}
  const wrapperStyle: React.CSSProperties = {
    width: 300,
    border: `1px solid ${token.colorBorderSecondary}`,
    borderRadius: token.borderRadiusLG,
  };
const getInternByTaxCode = () => {
  let myTimeZone = timeZone.replace('/', '-')
  fetch(localStorage.getItem("serverName")+'api/Statistic/' 
    + localStorage.getItem('taxCode') + '/' + myTimeZone, {
      method: 'GET',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${mytoken}`,
      },
}).then(response => response.json())
.then(async(data)=>{
  setOptionsIntern(data.map((item:any)=>{
    return {value: item.id, label: item.internName}
  }))
})  
}
const getPredefinedEvents = (myOption:string) => {
  fetch(localStorage.getItem("serverName")+'api/CareerNavigation/events', {
      method: 'GET',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${mytoken}`,
      },
}).then(response => response.json())
.then(async(data)=>{
  if(myOption=='TYP')
  setOptionsEventPredefine(data.filter((x:any)=>[1,2].includes(x.id)).map((item:any)=>{
    return {value: item.id, label: item.eventNameGer}
  }))
  else
  setOptionsEventPredefine(data.filter((x:any)=>[3,4].includes(x.id)).map((item:any)=>{
    return {value: item.id, label: item.eventNameGer}
  }))
})
}
const getInterviewer = () => {
  setOptionsInterviewer(myAdmins.map((item:any)=>{
    return {value: item.email, label: item.hovaten}
  }))
}
useEffect(()=>{
    setLang(localStorage.getItem('language'))
    getInternByTaxCode();
    getInterviewer();
    getPredefinedEvents(props.enterprise);
    handleChangeDate(dayjs(new Date()));
},[localStorage.getItem('language')])
const [isModalOpen, setIsModalOpen] = useState(false);
const [isModalOpen1, setIsModalOpen1] = useState(false);
const [isModalOpen2, setIsModalOpen2] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const showModal2 = () => {
    setIsModalOpen2(true);
  };
  const showModal1 = () => {
    form.setFieldsValue({
      InternId: null,
      Ngayphongvan:null,
    })

    setIsModalOpen1(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleOk2 = () => {
    
  };
const [form] = Form.useForm();
  const handleOk1 = () => {
    setIsModalOpen1(false);
  };

  const onFinish = (values:any) =>{
    if(localStorage.getItem('roleData')=='2' || localStorage.getItem('roleData')=='4'){
      if(id=='')
      {
        const formData = new FormData();
        formData.append("InternId", JSON.stringify(values.InternId));
        formData.append("EventDate", values.EventDate.$d.toISOString()); //values.EventDate.$d.toLocaleString());
        formData.append("EventName", values.EventName);
        formData.append("Result", 'Pending');
        formData.append("UserCreated", 'Chưa xác định');
        formData.append("Link", 'No link');
        const config = {     
          headers: { "Content-Type": "multipart/form-data", 'Authorization': `Bearer ${mytoken}` }
        }
        axios.post(localStorage.getItem("serverName")+'api/InternHistory', formData, config)
          .then(response => {
                message.success(response.data);
                setId('');
          })
          .catch(error => {
              message.info("Đã có lỗi trong quá trình đăng ký");
              console.log(error);
          });
      }
      else{
        console.log(JSON.stringify(student.map(a=>a.result)));
        console.log(JSON.stringify(historyId));
        fetch(localStorage.getItem("serverName")+'api/InternHistory/interviewgroup/',{
          method:'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${mytoken}`
          },
          body: JSON.stringify({ids: JSON.stringify(historyId), eventDate: values.EventDate.$d.toISOString(),
            result: JSON.stringify(student.map(a=>a.result))
          })
          })
        .then(response=>response.json)
        .then(()=>{
          dispatch(getInterview(currentDate));
        })
        setIsModalOpen1(false);
      }
    }
    else{
      console.log(id);
      fetch(localStorage.getItem("serverName")+'api/InternHistory/interviewgroup/',{
        method:'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${mytoken}`
        },
        body: JSON.stringify({ids: JSON.stringify(historyId), userCreated: values.UserCreated,
        link: values.Link})
        })
      .then(response=>response.json)
      .then(()=>{
        dispatch(getInterview(currentDate));
      })
      setIsModalOpen1(false);
    }
  }
  const handleCancel = () => {  
    setIsModalOpen(false);
  };
  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };
  return (
    <>
    <div style={{paddingTop:'0px'}}>
    <Modal title="Xóa lịch phỏng vấn" open={isModalOpen2} onOk={handleOk2} onCancel={handleCancel2}>
      <p>Bạn có chắc chắn xóa lịch phỏng vấn này không?</p>
    </Modal>
      <Modal title= {translate(props.lang!=null?props.lang:'ger', "Lịch phỏng vấn")} open={isModalOpen} 
      onOk={handleOk} onCancel={handleCancel} >
      <Calendar fullscreen={false} dateFullCellRender={dateFullCellRender} 
      onPanelChange={onPanelChange} onChange={onChange} 
      />    
      </Modal>
      <Modal title= {translate(props.lang!=null?props.lang:'ger', "Lịch phỏng vấn")} open={isModalOpen1}
      footer={null}
      onOk={handleOk1} onCancel={handleCancel1}>
      <Form onFinish={onFinish} form={form} style={{border: '1px solid gray', marginTop: 20, paddingTop:20}}
    layout="horizontal"
    labelCol={{ span: 10 }} wrapperCol={{ span: 20 }}
    >
    {localStorage.getItem('roleData')=='2' || localStorage.getItem('roleData') == '4' ?
    <>
      {id==''?
      <>
      <FormItem name="EventDate" label= {translate(props.lang!=null?props.lang:'ger', "Ngày phỏng vấn")}
      rules={[{required:true, message:'Phải chọn ngày'}]}
      >
      <DatePicker showTime={{ format: 'HH:mm' }}
      format="YYYY-MM-DD HH:mm"
      />
      </FormItem>
      <Form.Item name="EventName"
      label='Chọn đợt phỏng vấn:'
      rules={[{required:true, message:'Phải chọn đợt phỏng vấn'}]}
      >
        <Select
        options={optionsEventPredefine}
        />
      </Form.Item>
      <Form.Item name="InternId"
      label='Chọn ứng viên:'
      rules={[{required:true, message:'Phải chọn ứng viên'}]}
      >
        <Select
        mode='multiple'
        options={optionsIntern}
        />
      </Form.Item>
      </>
      : 
      <div>
      <FormItem name="EventDate" label= {translate(props.lang!=null?props.lang:'ger', "Ngày phỏng vấn")}
      rules={[{required:true, message:'Phải chọn ngày'}]}
      >
      <DatePicker showTime={{ format: 'HH:mm' }}
      format="YYYY-MM-DD HH:mm"
      />
      </FormItem>
      {student.map((j,index)=>
            <FormItem name={j.name} label={j.name}>
            <Radio.Group defaultValue={j.result}
            onChange={(e)=>{
              let myStudent = {name: j.name, result: e.target.value}
              let previous = student.slice(0, index);
              let nexts = student.slice(index+1);
              setStudent([...previous, myStudent, ...nexts]);
            }}
            >
              <Radio value="Pending">Pending</Radio>
              <Radio value="Failed">Failed</Radio>
              <Radio value="Passed">Passed</Radio>
            </Radio.Group>
          </FormItem>             
      )}    
      </div>
    }
    </>
    : <>
        <FormItem name="Link" label="Link phỏng vấn:"
      //rules={[{required:true, message:'Phải chọn ngày'}]}
      >
      <Input
      />
      </FormItem>
      <Form.Item name="UserCreated"
      label='Chọn người phỏng vấn:'
      rules={[{required:true, message:'Phải chọn ứng viên'}]}
      >
        <Select
        options={optionsInterviewer}
        />
      </Form.Item>
    </>}
      <Form.Item label="   ">
          <Button type="primary" htmlType="submit"
          disabled = {localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
          false : true }
          >Lưu dữ liệu</Button>
      </Form.Item>
    </Form>
    </Modal>
      <div style={{float:'right', width:'100%'}}>
      <Button type="primary" onClick={showModal} style={{marginLeft:'0px', marginBottom:'5px', width:'19%'}}>
       {translate(props.lang!=null?props.lang:'ger', 'Chọn tuần')}
    </Button>
    {localStorage.getItem('roleData') == '4' ?
          <Button type="primary" onClick={showModal1} 
          style={{marginLeft:'5px', marginBottom:'5px', width:'19%'}}
          >
            Xếp lịch phỏng vấn
          </Button> : ''}
      <div style={{width:'100%', overflow:'auto', whiteSpace:'nowrap'}}>
      <table style={{border:'1px solid gray', width:'150%'}}>
        <thead style={{fontWeight:'bold'}}>
          <tr>
            {columns.map((item:any)=>(
              <td style={{border:'1px solid gray', 
              textAlign:'center', backgroundColor:'gray'}}>{item.title}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {dataSource.map((item:any, index:number)=>{
            let dropableIdArr = {id:index, weekdays: [
              "monday-"+index.toString(),
              "tuesday-"+index.toString(),
              "wednesday-"+index.toString(),
              "thursday-"+index.toString(),
              "friday-"+index.toString(),
              "saturday-"+index.toString()
            ]}
            return(
            <tr>
              <td style={{border:'1px solid gray', width:'50px'}}>{item.dayPart}</td>
              {weekdays.map(item=>{
                let k = (new Date()).getDay() == 1 ? 'monday'
                : (new Date()).getDay() == 2 ? 'tuesday'
                : (new Date()).getDay() == 3 ? 'wednesday'
                : (new Date()).getDay() == 4 ? 'thursday'
                : (new Date()).getDay() == 5 ? 'friday'
                :(new Date()).getDay() == 6 ? 'saturday' : 'sunday';
                let taskDate1 = columns[1].title.substring(columns[1].title.indexOf('(')+1,columns[1].title.lastIndexOf(')'))
                let d1 = new Date(taskDate1);
                d1.setDate(d1.getDate()-1);
                let taskDate2 = columns[6].title.substring(columns[6].title.indexOf('(')+1,columns[6].title.lastIndexOf(')'))
                let d2 = new Date(taskDate2);
                d2.setDate(d2.getDate()+1);
                let myStyle = (item == k && (new Date()) >= d1  && (new Date()) <= d2)
                ? {width:'16%', backgroundColor:'cyan', border:'1px solid gray'}
                : {width:'16%', border:'1px solid gray'};
                let myIndex =  myTimeLine[index].findIndex(c=>c.weekday==item)
                return(
                  <td style={myStyle}>
              <div style={{minHeight:'200px', padding:'2px 2px 2px 2px', whiteSpace:'pre-wrap'}}>
              {myTimeLine[index][myIndex].data.map((item:any, index1:number)=>{
                  return(
                  <p key={item.id} style={{textDecoration:item.style[0], color:item.style[1]}}>
                {translate(props.lang!=null?props.lang:'ger', 'Nội dung:')}&nbsp;{item.content} <br />
                {translate(props.lang!=null?props.lang:'ger', 'Sinh viên')}:&nbsp;{item.candidates} <br />
                {translate(props.lang!=null?props.lang:'ger', 'Ngày giờ phỏng vấn:')}&nbsp;{item.interviewDate} <br />
                {translate(props.lang!=null?props.lang:'ger', 'Người phỏng vấn:')}&nbsp;{item.interviewUser} <br />
                {translate(props.lang!=null?props.lang:'ger', 'Link phỏng vấn:')}&nbsp;{item.interviewUser} <br />
                    <a onClick={()=>{
                      console.log(item.id)
                      setIsModalOpen1(true);
                      setId(item.id);
                      let myArrId: string[] = item.id.split('_');
                      let myResult: string[] = item.result.split('_');
                      let myArrStudent: string[] = item.candidates.includes(',')?item.candidates.split(','):[item.candidates];
                      setHistoryId(myArrId.map(x=>Number(x)));
                      setStudent(myArrStudent.map((z,index)=>{
                        return(
                          {name: z, result: myResult[index]}
                        )
                      }));
                      form.setFieldValue('EventDate', dayjs(new Date(item.interviewDate)));                     
                    }}>Edit Interview</a> 
                  </p>)
                })
              }                
              </div>
              </td>)  
              })}        
            </tr>)
          })}
        </tbody>
      </table>
      </div>
      </div>
    </div>
    </>
  );
}