import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
export const predefinedEventSlice = createSlice({
    name:'predefinedEvents',
    initialState:[] as any[],
    reducers: ({
        getPredefinedEvents: (state, action: PayloadAction<any[]>) => {
            state = [];
            return action.payload;
        }
    })
})
export function getPredefinedEvents(){
    return async function getPredefinedEventsThunk(dispatch: Dispatch, getState:any){
        const token = localStorage.getItem('token');
        const urlStr = localStorage.getItem("serverName")
        + 'api/CareerNavigation/events';
        const rawResponse = await fetch(urlStr, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
      //body: JSON.stringify(answerRequest)
    }).then((response:any) => response.json())
    .then((data:any)=>{
            dispatch(
                predefinedEventSlice.actions.getPredefinedEvents(data)
              )      
    })
    .catch(err => console.log(err))
    }
}
