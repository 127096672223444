import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { Career } from '../../interface';

export const careerNavigateSlice = createSlice({
    name:'career',
    initialState:[] as Career[],
    reducers: ({
        getCareer: (state, action: PayloadAction<Career[]>) => {
            state = [];
            return action.payload;
        },
    })
})

export function getCareer(){
    return async function getCompanyThunk(dispatch: Dispatch, getState:any){
        const token = localStorage.getItem('token');
        const rawResponse = await fetch(localStorage.getItem("serverName")+'api/CareerNavigation/', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(response => response.json())
    .then((data)=>{
            dispatch(careerNavigateSlice.actions.getCareer(data))
        console.log(data);       
    })
    .catch(err => console.log(err))
    }
}