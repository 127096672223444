import React, { useEffect, useState } from 'react';
import { Space, Table, Tag, Button, Modal, Form, Input, Popconfirm, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useAppDispatch } from '../../configs/hook';
import { Excel } from "antd-table-saveas-excel";
import { IntroducedListType } from '../../interface';

const IntroducedList: React.FC = () => {
    const dispatch = useAppDispatch();
    const token = localStorage.getItem('token');
    const [deleteItem, setDeleteItem] = useState(-1); 
    const columns: ColumnsType<IntroducedListType> = [
        {
          title: 'Id',
          dataIndex: 'id',
          key: 'id',
          hidden: true
        },
        {
          title: 'Tên ứng viên',
          dataIndex: 'candidateName',
          key: 'candidateName'
        },
        {
          title: 'Căn cước công dân',
          dataIndex: 'identifiedNumber',
          key: 'identifiedNumber'
        },
        {
          title: 'Điện thoại',
          dataIndex: 'telephoneNumber',
          key: 'telephoneNumber',
          render: (key:any, record:IntroducedListType, index:number) => {
            return(
            <>
            { localStorage.getItem('emailData') != 'test@alta.com.vn' ?
            !record.isEditableTelephoneNumber?
             <a onClick={()=>{
                let index = data.findIndex(c=>c.id==record.id);
                  let prefix = data.slice(0, index);
                  let mySlice = {...data[index], isEditablediachi: true}
                  let suffix = data.slice(index+1);
                  setData([...prefix, mySlice, ...suffix])
             }}>{record.telephoneNumber}</a>
             :<Input type="text" value={record.telephoneNumber}
             onChange={(e)=>{
              let index = data.findIndex(c=>c.id==record.id);
              let prefix = data.slice(0, index);
              let mySlice = {...data[index], telephoneNumber: e.currentTarget.value}
              let suffix = data.slice(index+1);
              setData([...prefix, mySlice, ...suffix])
             }}
             onKeyPress={(e)=>{
               if(e.key == "Enter"){
                let index = data.findIndex(c=>c.id==record.id);
                //let myRecord = data.find(c=>c.matruong==record.matruong);
                fetch(localStorage.getItem("serverName")+'api/IntroducedList/'+record.id, {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({id: data[index].id, candidateName: data[index].candidateName,
                        telephoneNumber:data[index].telephoneNumber, identifiedNumber:data[index].identifiedNumber,
                        taxCode: data[index].taxCode
                    })
            }).then(response => {
                console.log(response);
                message.info("Cập nhật thành công");
            })
            .catch(err => console.log(err));
                setData(data.map(item=>{
                  if(item.id==record.id){
                    return {...item, isEditableTelephoneNumber: false}
                  }
                  else
                    return item
                }))
               }
             }} />
            :<p>{record.telephoneNumber}</p>
            }
             </>)
          }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (key:any, record:IntroducedListType, index:number) => {
              return(
              <>
              { localStorage.getItem('emailData') != 'test@alta.com.vn' ?
              !record.isEditableEmail?
               <a onClick={()=>{
                  let index = data.findIndex(c=>c.id==record.id);
                    let prefix = data.slice(0, index);
                    let mySlice = {...data[index], isEditableEmail: true}
                    let suffix = data.slice(index+1);
                    setData([...prefix, mySlice, ...suffix])
               }}>{record.email}</a>
               :<Input type="text" value={record.email}
               onChange={(e)=>{
                let index = data.findIndex(c=>c.id==record.id);
                let prefix = data.slice(0, index);
                let mySlice = {...data[index], email: e.currentTarget.value}
                let suffix = data.slice(index+1);
                setData([...prefix, mySlice, ...suffix])
               }}
               onKeyPress={(e)=>{
                 if(e.key == "Enter"){
                  let index = data.findIndex(c=>c.id==record.id);
                  //let myRecord = data.find(c=>c.matruong==record.matruong);
                  fetch(localStorage.getItem("serverName")+'api/IntroducedList/'+record.id, {
                      method: 'PUT',
                      headers: {
                          'Accept': 'application/json',
                          'Content-Type': 'application/json',
                          'Authorization': `Bearer ${token}`,
                      },
                      body: JSON.stringify({id: data[index].id, candidateName: data[index].candidateName,
                          telephoneNumber:data[index].telephoneNumber, thongtin:data[index].email
                      })
              }).then(response => {
                  console.log(response);
                  message.info("Cập nhật thành công");
              })
              .catch(err => console.log(err));
                  setData(data.map(item=>{
                    if(item.id==record.id){
                      return {...item, isEditableEmail : false}
                    }
                    else
                      return item
                  }))
                 }
               }} />
              :<p>{record.email}</p>
              }
               </>)
            }
        },
        {
          title: 'Hành động',
          dataIndex: 'delete',
          key: 'delete',
          render: (key:any, record:IntroducedListType, index:number) => {
            return(
            <Button type="primary" danger
            disabled = {localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
            false : true } 
            onClick = {()=>{
              setIsConfirmModalOpen(true);
              setDeleteItem(record.id);
            }}
            >Xóa</Button>
            ) 
          }
        }
      ].filter(x=>!x.hidden);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const handleConfirmOk = (item: number) => {
        fetch(localStorage.getItem("serverName")+'api/IntroducedList/' + item, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
    }).then(response => {
        setData(data.filter(c=>c.id!=item));
    })
    .catch(err => console.log(err));
    }
    const handleConfirmCancel = () => {
      setIsConfirmModalOpen(false);
    }
    const handleOk = () => {
        setIsModalOpen(false);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
      };
      const showModal = () => {
        setIsModalOpen(true);
      };

    const [data, setData] = useState<IntroducedListType[]>([]);
    const getAllUniveristy = () => {
        fetch(localStorage.getItem("serverName")+'api/IntroducedList/'
        + localStorage.getItem('emailData'), {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
    }).then(response => response.json())
    .then((data)=>{      
        setData(data);            
    })
    .catch(err => console.log(err));
    }
    useEffect(()=>{
        getAllUniveristy();
    },[]) 
    const onFinish = async (values: any) => {
      const token = localStorage.getItem('token');
        fetch(localStorage.getItem("serverName")+'api/IntroducedList/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({candidateName: values.candidateName, taxCode: localStorage.getItem('emailData'),
                telephoneNumber: values.telephoneNumber, email:values.email, identifiedNumber:values.IdentifiedNumber
            })
    }).then(response=>response.json())
    .then((data:any) => {
        message.info(data.message);
        getAllUniveristy();
    })
    .catch(err => console.log(err));
      };
    return(
    <>
    <Button onClick={showModal}
    disabled = {localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
    false : true } 
    >Thêm ứng viên</Button>
     <Table columns={columns} dataSource={data} />
    <Modal title="Thêm thành viên" open={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel}>
        <Form labelCol={{ span: 8 }}
        wrapperCol={{ span: 20 }}
        layout="horizontal"
        onFinish={onFinish} >
        <Form.Item label="Tên ứng viên:" 
        name="candidateName"
        rules={[{ required: true, message: 'Không được để trống!' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Căn cước công dân" 
        name="IdentifiedNumber"
        rules={[{ required: true, message: 'Không được để trống!' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Điện thoại:" 
        name="telephoneNumber"
        rules={[{ required: true, message: 'Không được để trống!' }]}>
          <Input  />
        </Form.Item>
        <Form.Item label="Email:" 
        name="email"
        rules={[{ required: true, message: 'Không được để trống!' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="" wrapperCol={{offset:4}}>
          <Button type="primary" htmlType="submit">Lưu dữ liệu</Button>
        </Form.Item>
        </Form>
    </Modal>
    <Modal title="Xóa dữ liệu" open={isConfirmModalOpen} onOk={()=>handleConfirmOk(deleteItem)} onCancel={handleConfirmCancel}>
        <h3 style={{color:'#FF0000'}}>Bạn có chắc chắn xóa ứng viên {deleteItem} này khỏi hệ thống?</h3>
    </Modal>
    </>
    )
}

export default IntroducedList;