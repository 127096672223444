import React from 'react';
export const dataTranslate = [
    {
        text: 'Chọn JDs:',
        vie: 'Chọn JDs:',
        en:'Select JDs:',
        ger:'Wählen Sie JDs aus'
    },
    {
        text: 'Cập nhật ảnh CCCD',
        vie: 'Cập nhật ảnh CCCD',
        en:'Upload citizen ID card image',
        ger:'Laden Sie das Bild des Bürgerausweises hoch'
    },
    {
        text: 'Cập nhật ảnh hộ chiếu',
        vie: 'Cập nhật ảnh hộ chiếu',
        en:'Upload passport image',
        ger:'Passbild hochladen'
    },
    {
        text: 'Cập nhật bằng THPT',
        vie: 'Cập nhật bằng THPT',
        en:'Upload High school Diploma',
        ger:'Hochschulzeugnis hochladen'
    },
    {
        text: 'Cập nhật CV',
        vie: 'Cập nhật CV',
        en:'Upload Curriculum Vitae',
        ger:'Lebenslauf hochladen'
    },
    {
        text: 'Tải bằng THPT',
        vie: 'Tải bằng THPT',
        en:'Download high school diploma',
        ger:'Abiturzeugnis herunterladen'
    },
    {
        text: 'Tải tập tin CV',
        vie: 'Tải tập tin CV',
        en:'Download CV',
        ger:'Lebenslauf herunterladen'
    },
    {
        text: 'Trường đại học/cao đẳng',
        vie: 'Trường đại học/cao đẳng',
        en:'University/college',
        ger:'Universität / Hochschule'
    },
    {
        text: 'Đăng ký',
        vie: 'Đăng ký',
        en:'Register',
        ger:'Register'
    },
    {
        text: 'Tải ảnh CCCD',
        vie: 'Tải ảnh CCCD',
        en:'Download Citizen ID card image',
        ger:'Laden Sie das Bild des Bürgerausweises hoch'
    },
    {
        text: 'Điện thoại liên hệ',
        vie: 'Điện thoại liên hệ',
        en:'Contact No.',
        ger:'Kontakt Nr.'
    },
    {
        text: 'Tải ảnh hộ chiếu',
        vie: 'Tải ảnh hộ chiếu',
        en:'Download passport image',
        ger:'Passbild hochladen'
    },
    {
        text: 'Họ tên cha',
        vie: 'Họ tên cha',
        en:'Father’s full name',
        ger:'Vollständiger Name der Vaters'
    },
    {
        text: 'Họ tên mẹ',
        vie: 'Họ tên mẹ',
        en:'Mother’s full name',
        ger:'Vollständiger Name der Mutter'
    },
    {
        text: 'Địa chỉ bố mẹ',
        vie: 'Địa chỉ bố mẹ',
        en:'Parents address',
        ger:'Adresse der Eltern'
    },
    {
        text: 'Số liên lạc bố/mẹ',
        vie: 'Số liên lạc bố/mẹ',
        en:'Parents contact No.',
        ger:'Elternkontakt Nr.'
    },
    {
        text: 'Đào tạo và kinh nghiệm làm việc',
        vie: 'Đào tạo và kinh nghiệm làm việc',
        en:'Training and working experience',
        ger:'Ausbildung und Berufserfahrung'
    },
    {
        text: 'Thông tin chi tiết',
        vie: 'Thông tin chi tiết',
        en:'Detailed information',
        ger:'Genaue Information'
    },
    {
        text: 'Thông tin chung',
        vie: 'Thông tin chung',
        en:'Genenal information',
        ger:'Allgemeine Informationen'
    },
    {
        text: 'Ngày yêu cầu',
        vie: 'Ngày yêu cầu',
        en:'Requirement Date',
        ger:'Bedarfsdatum'
    },
    {
        text: 'Phân bổ ứng viên',
        vie: 'Phân bổ ứng viên',
        en:'Assign Candidate',
        ger:'Kandidatenzuordnung'
    },
    {
        text: 'Kế hoạch phỏng vấn',
        vie: 'Kế hoạch phỏng vấn',
        en:'Interview Schedule',
        ger:'Zeitplan für das Interview'
    },
    {
        text: 'Số vị trí công việc',
        vie: 'Số vị trí công việc',
        en:'Number of vacancy',
        ger:'Anzahl der offenen Stellen'
    },
    {
        text: 'Số ứng viên đã được phân bổ',
        vie: 'Số ứng viên đã được phân bổ',
        en:'Number of Assigned Candidates',
        ger:'Anzahl der zugewiesenen Kandidaten'
    },
    {
        text: 'Mã số doanh nghiệp',
        vie: 'Mã số doanh nghiệp',
        en:'Enterprise Code',
        ger:'Geschäftscode'
    },
    {
        text: 'Tên công ty',
        vie: 'Tên công ty',
        en:'Company Name',
        ger:'Firmenname'
    },
    {
        text: 'Địa chỉ công ty',
        vie: 'Địa chỉ công ty',
        en:'Company Address',
        ger:'Firmenadresse'
    },
    {
        text: 'Số liên hệ công ty',
        vie: 'Số liên hệ công ty',
        en:'Company Contact',
        ger:'Firmenkontakt'
    },
    
    {
        text: 'Mã nhân viên',
        vie: 'Mã nhân viên',
        en:'Employee Code',
        ger:'Mitarbeiterkodex'
    },
    {
        text: 'Họ và tên',
        vie: 'Họ và tên',
        en:'Full name',
        ger:'Vollständiger Name'
    },
    {
        text: 'Bạn có chắc chắn xóa không?',
        vie: 'Bạn có chắc chắn xóa không?',
        en:'Are you sure to delete this item?',
        ger:'Möchten Sie es wirklich löschen?'
    },
    {
        text: 'Quyền quản trị cao nhất',
        vie: 'Quyền quản trị cao nhất',
        en:'Super admin',
        ger:'Super admin'
    },
    {
        text: 'Thư điện tử',
        vie: 'Thư điện tử',
        en:'Email',
        ger:'Email'
    },
    {
        text: 'Số điện thoại',
        vie: 'Số điện thoại',
        en:'Telephone number',
        ger:'Telefonnummer'
    },
    {
        text: 'Tạo lại mật khẩu',
        vie: 'Tạo lại mật khẩu',
        en:'Reset password',
        ger:'Reset passwort'
    },
    {
        text: 'Ký hiệu tắt',
        vie: 'Ký hiệu tắt',
        en:'Symbol',
        ger:'Symbol'
    },
    {
        text: 'Tên nghề tiếng Việt',
        vie: 'Tên nghề tiếng Việt',
        en:'Jobs in Vietnamese',
        ger:'Jobs auf Vietnamese'
    },
    {
        text: 'Tên nghề tiếng Anh',
        vie: 'Tên nghề tiếng Anh',
        en:'Jobs in English',
        ger:'Jobs auf Englisch '
    },
    {
        text: 'Tên nghề tiếng Đức',
        vie: 'Tên nghề tiếng Đức',
        en:'Jobs in German',
        ger:'Jobs auf Deutsch'
    },
    {
        text: 'Mật khẩu mới:',
        vie: 'Mật khẩu mới:',
        en:'New password:',
        ger:'Neues Passwort:'
    },
    {
        text: 'Nhập lại mật khẩu:',
        vie: 'Nhập lại mật khẩu:',
        en:' Re-enter password:',
        ger:'Passwort erneut eingeben:'
    },
    {
        text: 'Đổi mật khẩu và hình đại diện',
        vie: 'Đổi mật khẩu và hình đại diện',
        en:'Change password and avatar',
        ger:'Passwort und avatar ändern'
    },
    {
        text: 'Thêm nghề nghiệp',
        vie: 'Thêm nghề nghiệp',
        en:'New job',
        ger:'Neuer Job'
    },
    {
        text: 'Thêm mới',
        vie: 'Thêm mới',
        en:'New',
        ger:'Neuer'
    },
    {
        text: 'Định hướng nghề nghiệp',
        vie: 'Định hướng nghề nghiệp',
        en:'Career Orientation',
        ger:'Berufliche Orientierung'
    },
    {
        text: 'Định hướng',
        vie: 'Định hướng',
        en:'Navigation',
        ger:'Navigation'
    },
    {
        text: 'Danh mục nghề nghiệp',
        vie: 'Danh mục nghề nghiệp',
        en:'Career Category',
        ger:'Kategorie Karriere'
    },
    {
        text: 'Danh sách sự kiện',
        vie: 'Danh sách sự kiện',
        en:'Event list',
        ger:'Liste der Veranstaltungen'
    },
    {
        text: 'Thêm thành viên',
        vie: 'Thêm thành viên',
        en:'Add member',
        ger:'Mitglieder hinzufügen'
    },
    {
        text: 'Thành phố',
        vie: 'Thành phố',
        en:'City',
        ger:'Stadt'
    },
    {
        text: 'Thống kê',
        vie: 'Thống kê',
        en:'Statistic',
        ger:'Statistik'
    },
    {
        text: 'Từ ngày',
        vie: 'Từ ngày',
        en:'From date',
        ger:'Ab Datum'
    },
    {
        text: 'Đến ngày',
        vie: 'Đến ngày',
        en:'To date',
        ger:'Miteinander ausgehen'
    },
    {
        text: 'Kết quả',
        vie: 'Kết quả',
        en:'Result',
        ger:'Ergebnis'
    },
    {
        text: 'Nội dung:',
        vie: 'Nội dung:',
        en:'Content:',
        ger:'Inhalt'
    },
    {
        text: 'Lịch phỏng vấn',
        vie: 'Lịch phỏng vấn',
        en:'Interview schedule',
        ger:'Zeitplan für Vorstellungsgespräche'
    },
    {
        text: 'Chọn tuần',
        vie: 'Chọn tuần',
        en:'Select week',
        ger:'Woche auswählen'
    },
    {
        text: 'Buổi sáng',
        vie: 'Buổi sáng',
        en:'AM',
        ger:'AM'
    },
    {
        text: 'Buổi chiều',
        vie: 'Buổi chiều',
        en:'PM',
        ger:'PM'
    },
    {
        text: 'Thứ hai',
        vie: 'Thứ hai',
        en:'Monday',
        ger:'Montag'
    },
    {
        text: 'Thứ ba',
        vie: 'Thứ ba',
        en:'Tuesday',
        ger:'Dienstag'
    },
    {
        text: 'Thứ tư',
        vie: 'Thứ tư',
        en:'Wednesday',
        ger:'Mittwoch'
    },
    {
        text: 'Thứ năm',
        vie: 'Thứ năm',
        en:'Thursday',
        ger:'Donnerstag'
    },
    {
        text: 'Thứ sáu',
        vie: 'Thứ sáu',
        en:'Friday',
        ger:'Freitag'
    },
    {
        text: 'Thứ bảy',
        vie: 'Thứ bảy',
        en:'Saturday',
        ger:'Samstag'
    },
    {
        text: 'Đối tác Việt Nam',
        vie: 'Đối tác Việt Nam',
        en:'Vietnamese Partner',
        ger:'Vietnamese Partner'
    },
    {
        text: 'Đối tác',
        vie: 'Đối tác',
        en:'Partner',
        ger:'Partner'
    },
    {
        text: 'Phân bổ việc làm',
        vie: 'Phân bổ việc làm',
        en:'Job allocation',
        ger:'Arbeitsvergabe'
    },
    {
        text: 'Theo dõi ứng viên',
        vie: 'Theo dõi ứng viên',
        en:'Candidate tracking',
        ger:'Kandidaten verfolgen'
    },
    {
        text: 'Lịch phỏng vấn',
        vie: 'Lịch phỏng vấn',
        en:'Interview schedule',
        ger:'Interview Zeitplan'
    },
    {
        text: 'Thay đổi ảnh đại diện',
        vie: 'Thay đổi ảnh đại diện',
        en:'Change avatar',
        ger:'Avatar ändern'
    },
    {
        text: 'Chọn ảnh',
        vie: 'Chọn ảnh',
        en:'Choose photo',
        ger:'Foto auswählen'
    },
    {
        text: 'Chọn tệp',
        vie: 'Chọn tệp',
        en:'Choose file',
        ger:'Datei wählen'
    },
    {
        text: 'Không có tệp được chọn',
        vie: 'Không có tệp được chọn',
        en:'No file chosen',
        ger:'Keine Datei ausgewählt'
    },
    {
        text: 'Hủy',
        vie: 'Hủy',
        en:'Cancel',
        ger:'Stornieren'
    },
    {
        text: 'Đối tác Đức',
        vie: 'Đối tác Đức',
        en:'German Partner',
        ger:'Deutscher Partner'
    },
    {
        text: 'Vai trò',
        vie: 'Vai trò',
        en:'Role',
        ger:'Rolle'
    },
    {
        text: 'Tạo công việc',
        vie: 'Tạo công việc',
        en:'Job creation',
        ger:'Arbeitsbeschaffung'
    },
    {
        text: 'Hệ thống quản trị tài năng',
        vie: 'Hệ thống quản trị tài năng',
        en:'TALENT MANAGEMENT SYSTEM',
        ger:'TALENT-MANAGEMENT-SYSTEM'
    },
    {
        text: 'Mật khẩu',
        vie: 'Mật khẩu',
        en:'Password',
        ger:'Passwort'
    },
    {
        text: 'Sinh viên',
        vie: 'Sinh viên',
        en:'Student',
        ger:'Student'
    },
    {
        text: 'Ngày giờ phỏng vấn:',
        vie: 'Ngày giờ phỏng vấn:',
        en:'Interview date:',
        ger:'Termin für das Vorstellungsgespräch:'
    },
    {
        text: 'Người phỏng vấn:',
        vie: 'Người phỏng vấn:',
        en:'Interviewer:',
        ger:'Interviewer:'
    },
    {
        text: 'Link phỏng vấn:',
        vie: 'Link phỏng vấn:',
        en:'Interview link:',
        ger:'Link zum Vorstellungsgespräch:'
    },
    {
        text: 'Trang chủ Ty-Academy',
        vie: 'Trang chủ Ty-Academy',
        en:'Ty-Academy Home Page',
        ger:'Ty-Academy Home Page'
    },
    {
        text: 'Quản trị viên',
        vie: 'Quản trị viên',
        en:'Administrator',
        ger:'Administrator'
    },
    {
        text: 'Đến trang đăng ký',
        vie: 'Đến trang đăng ký',
        en:'Go to register page',
        ger:'Zur Registrierungsseite'
    },
    {
        text: 'Tên đăng nhập',
        vie: 'Tên đăng nhập',
        en:'User name',
        ger:'Benutzer'
    },
    {
        text: 'Số hộ chiếu',
        vie: 'Số hộ chiếu',
        en:'Passport No.',
        ger:'Pass Nr.'
    },
    {
        text: 'Định hướng nghề nghiệp',
        vie: 'Định hướng nghề nghiệp',
        en:'Select job navigation',
        ger:'Berufsorientierung'
    },
    {
        text: 'Đổi bằng',
        vie: 'Đổi bằng',
        en:'Professional recognition',
        ger:'Berufs Anerkennung'
    },
    {
        text: 'Học nghề',
        vie: 'Học nghề',
        en:'Vocational training abroad',
        ger:'Ausbildung'
    },
    {
        text: 'Chọn hướng đi',
        vie: 'Chọn hướng đi',
        en:'Bildungsrichtung',
        ger:'Bildungsrichtung'
    },
    {
        text: 'Căn cước công dân',
        vie: 'Căn cước công dân',
        en:'Citizen ID No',
        ger:'Bürger-ID-Nr.'
    },
    {
        text: 'Tài khoản mạng xã hội',
        vie: 'Tài khoản mạng xã hội',
        en:'Social network account',
        ger:'Social network account'
    },
    {
        text: 'Họ tên bó',
        vie: 'Họ tên bó',
        en:'Father',
        ger:'Vater'
    },
    {
        text: 'Họ tên mẹ',
        vie: 'Họ tên mẹ',
        en:'Mother',
        ger:'Mutter'
    },
    {
        text: 'Địa chỉ bố mẹ',
        vie: 'Địa chỉ bố mẹ',
        en:'ParentAddress',
        ger:'Adresse der Eltern'
    },
    {
        text: 'Chọn file ảnh chứng minh nhân dân',
        vie: 'Chọn file ảnh chứng minh nhân dân',
        en:'Personal Identification Image',
        ger:'Ausweis'
    },
    {
        text: 'Chọn file ảnh passport',
        vie: 'Chọn file ảnh passport',
        en:'Passport Image',
        ger:'Reisepass'
    },
    {
        text: 'Chọn file ảnh bằng tốt nghiệp cấp 3',
        vie: 'Chọn file ảnh bằng tốt nghiệp cấp 3',
        en:'Graduated High school Image',
        ger:'Schulabschluss'
    },
    {
        text: 'Liên lạc người thân ở VN',
        vie: 'Liên lạc người thân ở VN',
        en:'Family contact',
        ger:'Telefonnummer der erste Kontakt Person'
    },
    {
        text: 'Đăng nhập',
        vie: 'Đăng nhập',
        en:'Log in',
        ger:'Anmelden'
    },
    {
        text: 'Doanh nghiệp Đức/Việt Nam',
        vie: 'Doanh nghiệp Đức/Việt Nam',
        en:'German/Vietnames Company',
        ger:'Deutsche Unternehmen/Vietnam'
    },
    {
        text: 'Ngôn ngữ',
        vie: 'Ngôn ngữ',
        en:'Language',
        ger:'Sprache'
    },
    {
        text: 'Chọn doanh nghiệp',
        vie: 'Chọn doanh nghiệp',
        en:'Select enterprise',
        ger:'Wählen Sie ein Unternehmen'
    },
    {
        text: 'Chọn yêu cầu tuyển dụng',
        vie: 'Chọn yêu cầu tuyển dụng',
        en:'Select requirement',
        ger:'Stellenausschreibung auswählen'
    },
    {
        text: 'Trình độ ngoại ngữ',
        vie: 'Trình độ ngoại ngữ',
        en:'Foreign language level',
        ger:'Fremdsprachenniveau'
    },
    {
        text: 'Địa chỉ email',
        vie: 'Địa chỉ email',
        en:'Email address',
        ger:'E-Mail-Addresse'
    },
    {
        text: 'Điện thoại',
        vie: 'Điện thoại',
        en:'Telephone number',
        ger:'Telefon'
    },
    {
        text: 'Nữ',
        vie: 'Nữ',
        en:'Female',
        ger:'Weiblich'
    },
    {
        text: 'Nam',
        vie: 'Nam',
        en:'Male',
        ger:'Männlich'
    },
    {
        text: 'Giới tính',
        vie: 'Giới tính',
        en:'Gender',
        ger:'Gendet'
    },
    {
        text: 'Mục này không được để trống',
        vie: 'Mục này không được để trống',
        en:'This field must be not empty',
        ger:'Dieses Feld darf nicht leer sein'
    },
    {
        text: 'Chọn file CV để đăng',
        vie: 'Chọn file CV để đăng',
        en:'Choose your file',
        ger:'Lebenslauf'
    },
    {
        text: 'Chọn nghề nghiệp',
        vie: 'Chọn nghề nghiệp',
        en:'Choose career',
        ger:'Wählen Sie Karriere'
    },
    {
        text: 'Đăng ký trực tuyến',
        vie: 'Đăng ký trực tuyến',
        en:'Online Register',
        ger:'Online registrieren'
    },
    {
        text: 'Xem JD tuyển dụng',
        vie: 'Xem JD tuyển dụng',
        en:'JD About Conditions',
        ger:'Einstellungsvoraussetzungen'
    },
    {
        text: 'Doanh nghiệp đăng ký',
        vie: 'Doanh nghiệp đăng ký',
        en:'Register For Enterprise',
        ger:'Eingetragenes Gewerbe'
    },
    {
        text: 'Người dùng',
        vie: 'Người dùng',
        en:'User',
        ger:'Benutzer'
    },
    {
        text: 'Lịch phỏng vấn',
        vie: 'Lịch phỏng vấn',
        en:'Interview Scheduler',
        ger:'Interviewplaner'
    },
    {
        text: 'Hồ sơ được giới thiệu',
        vie: 'Hồ sơ được giới thiệu',
        en:'Introduced profiles',
        ger:'Profil introduit'
    },
    {
        text: 'Đăng xuất',
        vie: 'Đăng xuất',
        en:'Log out',
        ger:'Ausloggen'
    },
    {
        text: 'Lĩnh vực chuyên môn',
        vie: 'Lĩnh vực chuyên môn',
        en:'Job Position',
        ger:'Berufliche Position'
    },
    {
        text: 'Cập nhật thành công',
        vie: 'Cập nhật thành công',
        en:'Updated successfully',
        ger:'Erfolgreich aktualisiert'
    },
    {
        text: 'Chuyên ngành',
        vie: 'Chuyên ngành',
        en:'Major',
        ger:'Wesentlich'
    },
    {
        text: 'Hành động',
        vie: 'Hành động',
        en:'Action',
        ger:'Action'
    },
    {
        text: 'Thêm mới thành công',
        vie: 'Thêm mới thành công',
        en:'Added successfully',
        ger:'Ajouté avec succès'
    },
    {
        text: 'Thêm lĩnh vực chuyên môn',
        vie: 'Thêm lĩnh vực chuyên môn',
        en:'Add job position',
        ger:'Ajouter un poste'
    },
    {
        text: 'Tên lĩnh vực:',
        vie: 'Tên lĩnh vực:',
        en:'Job position name:',
        ger:'Nom du poste:'
    },
    {
        text: 'Không được để trống',
        vie: 'Không được để trống',
        en:'This field must be not empty',
        ger:'Ce champ ne doit pas être vide'
    },
    {
        text: 'Lưu dữ liệu',
        vie: 'Lưu dữ liệu',
        en:'Save data',
        ger:'Daten speichern'
    },
    {
        text: 'Chọn tập tin:',
        vie: 'Chọn tập tin:',
        en:'Select file',
        ger:'Choisir le dossier'
    },
    {
        text: 'Yêu cầu:',
        vie: 'Yêu cầu:',
        en:'Requirment:',
        ger:'Exigence'
    },
    {
        text: 'Thay đổi thứ tự',
        vie: 'Thay đổi thứ tự',
        en:'Change the order',
        ger:'Ändern Sie die Reihenfolge'
    },
    {
        text: 'Thêm sự kiện mới',
        vie: 'Thêm sự kiện mới',
        en:'Add new event',
        ger:'Neues Ereignis hinzufügen'
    },
    {
        text: 'Tùy chọn',
        vie: 'Tùy chọn',
        en:'Option',
        ger:'Option'
    },
    {
        text: 'Xóa',
        vie: 'Xóa',
        en:'Delete',
        ger:'Löschen'
    },
    {
        text: 'Xóa dữ liệu',
        vie: 'Xóa dữ liệu',
        en:'Delete data',
        ger:'Daten löschen'
    },
    {
        text: 'Xóa sự kiện',
        vie: 'Xóa sự kiện',
        en:'Delete event',
        ger:'Ereignis löschen'
    },
    {
        text: 'Email liên hệ không được để trống',
        vie: 'Email liên hệ không được để trống',
        en:'Contact Email must be not null',
        ger:'Le-mail de contact ne doit pas être nul'
    },
    {
        text: 'Email liên hệ:',
        vie: 'Email liên hệ:',
        en:'Contact email:',
        ger:'E-mail de contact:'
    },
    {
        text: 'Tên doanh nghiệp:',
        vie: 'Tên doanh nghiệp:',
        en:'Company name:',
        ger:'Firmenname'
    },
    {
        text: 'Tên doanh nghiệp không được để trống',
        vie: 'Tên doanh nghiệp không được để trống',
        en:'Company name must be not null',
        ger:'Le nom de l entreprise ne doit pas être nul'
    },
    {
        text: 'Điện thoại công ty:',
        vie: 'Điện thoại công ty:',
        en:'Company phone number:',
        ger:'Firmentelefon:'
    },
    {
        text: 'Địa chỉ công ty:',
        vie: 'Địa chỉ công ty:',
        en:'Company address:',
        ger:'Firmenadresse:'
    },
    {
        text: 'Người quản lý:',
        vie: 'Người quản lý:',
        en:'Manager:',
        ger:'Manager:'
    },
    {
        text: 'Tên công ty không được để trống',
        vie: 'Tên công ty không được để trống',
        en:'Company name must be not null',
        ger:'Der Firmenname darf nicht null sein'
    },
    {
        text: 'Số điện thoại không được bỏ trống',
        vie: 'Số điện thoại không được bỏ trống',
        en:'Telephone number must be not null ',
        ger:'Die Telefonnummer darf nicht null sein'
    },
    {
        text: 'Địa chỉ công ty không được bỏ trống',
        vie: 'Địa chỉ công ty không được bỏ trống',
        en:'Company address must be not null',
        ger:'Die Firmenadresse darf nicht null sein'
    },
    {
        text: 'Vui lòng điền mật khẩu của bạn',
        vie: 'Vui lòng điền mật khẩu của bạn',
        en:'Please input your password',
        ger:'Bitte geben Sie Ihr Passwort ein'
    },
    {
        text: 'Điện thoại người quản lý:',
        vie: 'Điện thoại người quản lý:',
        en:'Manager telephone:',
        ger:'Manager Telefon:'
    },
    {
        text: 'Chọn file CV để upload:',
        vie: 'Chọn file CV để upload:',
        en:'Choose file:',
        ger:'Wählen Sie Ihre Datei:'
    },
    {
        text: 'Tất cả',
        vie: 'Tất cả',
        en:'All',
        ger:'Toute'
    },
    {
        text: 'Đã chuyển',
        vie: 'Đã chuyển',
        en:'Provided',
        ger:'Fournie'
    },
    {
        text: 'Đã trả',
        vie: 'Đã trả',
        en:'Returned',
        ger:'Revenu'
    },
    {
        text: 'Đã tuyển dụng',
        vie: 'Đã tuyển dụng',
        en:'Passed',
        ger:'Passé'
    },
    {
        text: 'Tên ứng viên',
        vie: 'Tên ứng viên',
        en:'Candidate Name',
        ger:'Nom du candidat'
    },
    {
        text: 'Trường đại học',
        vie: 'Trường đại học',
        en:'University',
        ger:'Université'
    },
    {
        text: 'Chuyên ngành',
        vie: 'Chuyên ngành',
        en:'Major',
        ger:'Majeure'
    },
    {
        text: 'Vị trí',
        vie: 'Vị trí',
        en:'Position',
        ger:'Position'
    },
    {
        text: 'Nơi sinh',
        vie: 'Nơi sinh',
        en:'Place of Birth',
        ger:'Geburtsort'
    },
    {
        text: 'Ngày sinh',
        vie: 'Ngày sinh',
        en:'Date of Birth',
        ger:'Geburtsdatum'
    },
    {
        text: 'Ngày cấp',
        vie: 'Ngày cấp',
        en:'Date of issue',
        ger:'Datum der Ausstellung'
    },
    {
        text:'Ngày phỏng vấn',
        vie: 'Ngày phỏng vấn',
        en: 'Interview Date',
        ger:'Termin für das Vorstellungsgespräch'
    },
    {
        text: 'Địa chỉ cư trú',
        vie: 'Địa chỉ cư trú',
        en:'Residential address',
        ger:'Wohnadresse'
    },
    {
        text: 'Tải CV',
        vie: 'Tải CV',
        en:'Download CV',
        ger:'Télécharger CV'
    },
    {
        text: 'Vị trí công việc:',
        vie: 'Vị trí công việc:',
        en:'Job position',
        ger:'Poste'
    },
    {
        text: 'Trạng thái:',
        vie: 'Trạng thái:',
        en:'Status:',
        ger:'Statut:'
    },
    {
        text: 'Người quản lý:',
        vie: 'Người quản lý:',
        en:'Manager:',
        ger:'Directeur:'
    }
]