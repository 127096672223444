import React, { useEffect, useState, ChangeEvent } from 'react';
import {Select, Button, Modal, Form, Input, message, SelectProps, Table, DatePicker, Collapse } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useAppDispatch } from '../../configs/hook';
import axios from 'axios';
import {translate} from '../../helpers/translate';
import delImg from '../../assets/images/recyclebin.png';
import { useSelector } from 'react-redux';
import { InternType, JobPoolType } from '../../interface';
import { RootState } from '../../redux/store';
import { JobPoolRequirement } from '../../interface';
import {JobPoolRequirementDetail} from '../../interface';
import { getJobPools } from '../../layouts/enterprise/jobPoolSlice';
import { CompanyType } from '../../interface';
import {getDateString} from '../../helpers/index';
import deleteImage from '../../assets/images/recyclebin.png';
import { useForm } from 'antd/es/form/Form';
import { FindCandidate } from '../internsRegistered/findCandidate';
export declare type AlignType = 'left' | 'center' | 'right';
const { Panel } = Collapse;
const JobPool=(props:{lang:string | null}) => {
  const [lang, setLang] = useState<string|null>('ger');
    const dispatch = useAppDispatch();
    const token = localStorage.getItem('token');
    const [form] = useForm();
    const [data, setData] = useState<CompanyType[]>([]);
    const [isFindCandidateModalOpen, setIsFindCandidateModalOpen] = useState(false);
    const [isInterviewModalOpen, setIsInterviewModalOpen] = useState(false);
    const [searchData, setSearchData] = useState<CompanyType[]>([]);
    const myCareers = useSelector((state:RootState)=>state.careers);
    const myJobPools = useSelector((state:RootState)=>state.jobpools);
    const myInterns = useSelector((state:RootState)=>state.allInterns);
    const [optionsEventPredefine, setOptionsEventPredefine] = useState<SelectProps['options']>([]);
    const [myAbbreation, setMyAbbreation] = useState('');
    const [myNavigation, setMyNaviagtion] = useState(-1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchInternsData, setSearchInternsData] = useState<InternType[]>([]);
    const [isModalJobDetailOpen, setIsModalJobDetailOpen] = useState(false);
    const [isModalAssignOpen, setIsModalAssignlOpen] = useState(false);
    const [isConfirmModalDeleteOpen, setIsConfirmModalDeleteOpen] = useState(false);
    const [myJobPoolId, setMyJobPoolId] = useState(-1);
    const [subjectName, setSubjectName] = useState<string>('');
    const [myJobPoolRequrieDate, setMyJobPoolRequireDate] = useState('2000-01-01');
    const [optionInternForInterview, setOptionInternForInterview] = useState<SelectProps['options']>([]);
    const [requirmentDetailId, setRequirementDetailId] = useState(-1);
    const [options, setOptions] = useState<SelectProps['options']>(
        myCareers.map(item=>{
            return {value: item.abbreatation, label: item.careerNavigationGe}
        })
    ); 
    const [optionsNavigation, setOptionsNavigation] = useState<SelectProps['options']>(
      [
        {value:1, label:translate(lang!=null?lang:'ger','Đổi bằng')}, {value:0, label:translate(lang!=null?lang:'ger','Học nghề')}
      ]
  );
  const [optionsIntern, setOptionsIntern] = useState<SelectProps['options']>([]);
    const handleOk = () => {
        setIsModalOpen(false);
      };    
      const handleCancel = () => {
        setIsModalOpen(false);
      };
      const handleChange1 = async (value: string[]) => {
        let mySubject = '_';
        await value.map((row:string)=>{
          mySubject += (row+'_');
        })
        setSubjectName(mySubject);
      };
      const showModal = () => {
        setIsModalOpen(true);
      };
      const getGerCompany = () => {
        fetch(localStorage.getItem("serverName")+'api/Company/all/TYG', {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
          },
  }).then(response => response.json())
  .then((data)=>{      
      setData(data); 
      setSearchData(localStorage.getItem('taxCode')=="All"? data:data.filter((x:any)=>x.taxCode==localStorage.getItem('taxCode')));           
  })
  .catch(err => console.log(err));
      }

  const handleConfirmFCModelOk = () => {
    setIsFindCandidateModalOpen(false);
  }
      const columns:  ColumnsType<JobPoolType> = [
        {
          title: 'Key',
          dataIndex: 'key',
          key: 'key',
          hidden: true
        },
        {
          title: translate(lang!=null?lang:'ger','Thêm mới'),
          dataIndex: 'add',
          key: 'add',
          hidden: localStorage.getItem('taxCode')=="All"?true:false,
          render:(key:number, record:JobPoolType, index:number)=>{
            return(
              <a onClick={()=>{
                setMyJobPoolId(record.id);
                setIsModalJobDetailOpen(true)
              }}>{translate(lang!=null?lang:'ger','Thêm mới')}</a>
            )
          }
        },
        {
          title: 'Id',
          dataIndex: 'id',
          key: 'id',
          hidden: localStorage.getItem('roleData') != '1' ? true : false
        },
        {
          title: 'Abbreation',
          dataIndex: 'abbreation',
          key: 'abbreation',
          hidden: true
        },
        {
          title: translate(lang!=null?lang:'ger','Danh mục nghề nghiệp'),
          dataIndex: 'navigationNameGer',
          key: 'navigationNameGer'
        },
        {
          title: translate(lang!=null?lang:'ger','Thành phố'),
          dataIndex: 'city',
          key: 'city'
        },
        {
          title:translate(lang!=null?lang:'ger','Định hướng nghề nghiệp'),
          dataIndex:'navigation',
          key:'navigation',
          render:(key:number, record: JobPoolType, index:number)=>{
            return(
              <p>{record.navigation==true?translate(lang!=null?lang:'ger', 'Đổi bằng'):translate(lang!=null?lang:'ger', 'Học nghề')}</p>
            )
          }
        }
      ].filter(x=>!x.hidden);
    const onFinish = async (values: any) => {
      const token = localStorage.getItem('token');
      console.log(values.Navigation);
        fetch(localStorage.getItem("serverName")+'api/JobPool/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({taxCode: localStorage.getItem("taxCode"), abrreatation: values.Abbreation,
            city: values.City, navigation: values.Navigation==1?true:false
            })
            })
    .then(response => {
        message.info(translate('en',"Added successfully"));
    })
    .catch(err => console.log(err));
      };
    useEffect(()=>{
      getGerCompany();
    },[])
    useEffect(()=>{
      setLang(localStorage.getItem('language'))
  },[localStorage.getItem('language')])
      const onFinish1 = async (values: any) => {
        console.log(getDateString(new Date(values.RequirementDate)));
        const token = localStorage.getItem('token');
          fetch(localStorage.getItem("serverName")+'api/JobPool/addrequirement', {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
              },
              body: JSON.stringify({requirementDate: getDateString(new Date(values.RequirementDate)), jobPoolId: myJobPoolId,
                numberOfVacancy: Number(values.NumberOfVacancy), noteContent: values.NoteContent
              })
              })
      .then(response => {
          message.info(translate('en',"Added successfully"));
          dispatch(getJobPools())
      })
      .catch(err => console.log(err));
        };

        const onFinish2 = async (values: any) => {
          const token = localStorage.getItem('token');
            console.log(myJobPoolRequrieDate + myJobPoolId);
            fetch(localStorage.getItem("serverName")+'api/JobPool/assignstudent', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({requirementDate: myJobPoolRequrieDate, jobPoolId: myJobPoolId,
                  numberOfVacancy: 0, noteContent: '', providedCv: subjectName
                })
                })
        .then(response => {
            setOptionsIntern([]);
            setSubjectName('');
            message.info(translate('en',"Added successfully"));
            dispatch(getJobPools())
        })
        .catch(err => console.log(err));
          };

      const handleConfirmOk = async (id:number) => {
        const token = localStorage.getItem('token');
        const urlStr = localStorage.getItem("serverName")
+ 'api/JobPool/requirmentdetail/' + requirmentDetailId;
console.log(urlStr);
const rawResponse = await fetch(urlStr, {
method: 'DELETE',
headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
}
}).then((response:any) => response.json())
.then((data:any)=>{
      dispatch(getJobPools());
})
.catch(err => console.log(err));
setIsConfirmModalDeleteOpen(false);
}
      const expandedRowRender = (record:JobPoolType) => {
        const columns: ColumnsType<JobPoolRequirement> = [
          { title: 'key', dataIndex: 'key', key: 'key', hidden: true },
          { title: translate(lang!=null?lang:'ger','Ngày yêu cầu'), dataIndex: 'requirementDate', key: 'requirementDate'},
          { title: 'Job pool id', dataIndex: 'jobPoolId', key: 'jobPoolId', hidden: true },
          { title: translate(lang!=null?lang:'ger','Kế hoạch phỏng vấn'), datIndex: 'interview', key:'interview',
            render:(key:number, record1:JobPoolRequirement, index:number)=>{
              return(
                <Button onClick={async()=>{
                  if(record1.providedCv!=null){
                    let internIds:number[] = await record1.providedCv.split('_').map(x=>{
                        return Number(x);
                    }).filter(x=>x!=0);
                    let myOptionInterns:any[] = await myInterns.filter(x=>internIds.includes(x.id)).map(u=>{
                      return {value: u.id, label: u.internName}
                    })
                    setOptionInternForInterview(myOptionInterns);
                  }
                  setIsInterviewModalOpen(true)}}>Interview Scheduler</Button>
              )
            }
          },
          { title: translate(lang!=null?lang:'ger','Số vị trí công việc'), dataIndex: 'numberOfVacancy', key: 'numberOfVacancy', align: 'right' as AlignType },
          { title: translate(lang!=null?lang:'ger','Số ứng viên đã được phân bổ'), dataIndex: 'providedCv', key: 'providedCv', align: 'right' as AlignType,
            render:(key:number, record1:JobPoolRequirement, index:number) => {
              let internIds:number[] = [];
              if(record1.providedCv!=null){
                internIds = record1.providedCv.split('_').map(x=>{
                    return Number(x);
                }).filter(x=>x!=0);
              }
              return (
                <a onClick={async()=>{
                  setSearchInternsData(myInterns.filter(x=>internIds.includes(x.id)));
                  setIsFindCandidateModalOpen(true);
                }}>{internIds.length}</a>
              )
            } 
          },
          {
            title: translate(lang!=null?lang:'ger','Tùy chọn'),
            dataIndex: 'action',
            key:'action',
            hidden: localStorage.getItem('taxCode')=="All"?false:true,
            render:(key:number, record1: JobPoolRequirement, index:number)=>{
                return(
                  <Button onClick={async()=>{
                    setMyJobPoolId(record1.jobPoolId);
                    setMyJobPoolRequireDate(record1.requirementDate);
                  await setMyAbbreation(record.abrreatation);
                  await setMyNaviagtion(record.navigation?1:0);
                    const token = localStorage.getItem('token');
                    const urlStr = localStorage.getItem("serverName")
        + 'api/Register/available/' + record.abrreatation + '/' + (record.navigation?1:0);
        console.log(urlStr);
        const rawResponse = await fetch(urlStr, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
    }).then((response:any) => response.json())
    .then(async (data:any)=>{
      if(record1.providedCv!=null){
        let internIds:number[] = await record1.providedCv.split('_').map(x=>{
            return Number(x);
        }).filter(x=>x!=0);
        let myOptionInterns:any[] = await myInterns.filter(x=>internIds.includes(x.id)).map(u=>{
          return {value: u.id, label: u.taxCode + '-' + u.internName}
        })
        form.setFieldsValue({ProvidedCv:myInterns.filter(x=>internIds.includes(x.id)).map(u=>{
          return {value: u.id, label: u.taxCode + '-' + u.internName}
        })});
        await data.map((item:any)=>{
          myOptionInterns.push({value: item.id, label: item.taxCode + '-' + item.internName})
        })
        setOptionsIntern(myOptionInterns);        
        setIsModalAssignlOpen(true);
      }
                
    })
    .catch(err => console.log(err))
                  }}
                  >{translate(lang!=null?lang:'ger','Phân bổ ứng viên')}</Button>
                );
            }
          }         
        ].filter(a=>!a.hidden);
        return <Table columns={columns} expandable={{
          expandedRowRender: (record1:JobPoolRequirement) => {
            const columns: ColumnsType<JobPoolRequirementDetail> = [
              { title: 'id', dataIndex: 'id', key: 'id' },
              { title: 'Labor require date', dataIndex: 'requirementDate', key: 'requirementDate'},
              { title: 'Job pool id', dataIndex: 'jobPoolId', key: 'jobPoolId', hidden: true },
              
              { title: 'internId', dataIndex: 'internId', key: 'internId',
                render:(key:number, record2:JobPoolRequirementDetail, index:number) => {
                  return(
                    <p title = {record2.internId!=null?myInterns.
                      filter(x=>x.id==record2.internId)[0].socialMedia:''}
                    >{record2.internId!=null?myInterns.
                      filter(x=>x.id==record2.internId)[0].taxCode
                      + '-' + myInterns.
                      filter(x=>x.id==record2.internId)[0].internName:''}</p>
                  )
                }
              },
              { title: 'result', dataIndex: 'result', key: 'result',
              render:(key:number, record2:JobPoolRequirementDetail, index:number) => {
                return(
                  <p>{record2.internId!=null?'Passed':''}</p>
                )
              }
            },
              {
                title: 'Action',
                dataIndex: 'delete',
                key: 'delete',
                hidden: localStorage.getItem('taxCode')=="All"?true:false,
                render: (key:number, record2: JobPoolRequirementDetail, index:number)=>{
                  return(
                    <Button onClick={()=>{
                      setRequirementDetailId(record2.id);
                      setIsConfirmModalDeleteOpen(true);
                    }}>Delete</Button>
                  )
                }
              }             
            ].filter(a=>!a.hidden);
            return(
              <Table columns={columns} 
        dataSource={myJobPools.filter(a=>a.id==record.id)[0]
          .jobPoolRequirements?.filter(b=>b.jobPoolId == record1.jobPoolId && b.requirementDate == record1.requirementDate)
        [0].jobPoolRequirementDetails} pagination={false} />
            )
          }
        }}
        dataSource={myJobPools.filter(a=>a.id==record.id)[0].jobPoolRequirements} pagination={false} />;
      };
      const getPredefinedEvents = (myOption:string) => {
        fetch(localStorage.getItem("serverName")+'api/CareerNavigation/events', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
      }).then(response => response.json())
      .then(async(data)=>{
        if(myOption=='TYP')
        setOptionsEventPredefine(data.filter((x:any)=>[1,2].includes(x.id)).map((item:any)=>{
          return {value: item.id, label: item.eventNameGer}
        }))
        else
        setOptionsEventPredefine(data.filter((x:any)=>[3,4].includes(x.id)).map((item:any)=>{
          return {value: item.id, label: item.eventNameGer}
        }))
      })
      }    
      const onFinish3 = (values:any) =>{
        const formData = new FormData();
        formData.append("InternId", JSON.stringify(values.InternId));
        formData.append("EventDate", values.EventDate.$d.toLocaleString());
        formData.append("EventName", values.EventName);
        formData.append("Result", 'Pending');
        formData.append("UserCreated", 'Chưa xác định');
        formData.append("Link", 'No link');
        const config = {     
          headers: { "Content-Type": "multipart/form-data", 'Authorization': `Bearer ${token}` }
        }
        axios.post(localStorage.getItem("serverName")+'api/InternHistory', formData, config)
          .then(response => {
                message.success(response.data);
                form.setFieldsValue({InternId:[]})
          })
          .catch(error => {
              message.info("Đã có lỗi trong quá trình đăng ký");
              console.log(error);
          });
      }
    useEffect(()=>{
      getPredefinedEvents('TYG');
    },[]);
    return(
    <>
    <Collapse>
    {searchData.map((item, index)=>{
      return(
        <Panel header={<div>
            {item.taxCode}&nbsp;-&nbsp;{item.companyName}&nbsp;-&nbsp;{item.telephone}
          </div>
          } key={index}>
    {localStorage.getItem("taxCode")!="All"?
    <Button onClick={showModal}
    disabled = {localStorage.getItem('emailData') != 'test@alta.com.vn'? 
    false : true }
    >{translate(lang!=null?lang:'ger','Tạo công việc')}</Button>: ""}
    <Table columns={columns} expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
    dataSource={myJobPools.filter(x=>x.taxCode==item.taxCode)} />
    </Panel>
      );
    })}   
    </Collapse>
    <Modal title={translate(lang!=null?lang:'ger','Tạo công việc')} 
    open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Form labelCol={{ span: 8 }}
        wrapperCol={{ span: 20 }}
        layout="horizontal"
        onFinish={onFinish} >          
        <Form.Item label={translate(lang!=null?lang:'ger','Chọn nghề nghiệp')} 
        name="Abbreation"
        rules={[{ required: true, message: translate(lang!=null?lang:'ger',"Chọn tên công việc") }]}>
            <Select options={options} />
        </Form.Item>
        <Form.Item label={translate(lang!=null?lang:'ger',"Thành phố")} 
        name="City"
        rules={[{ required: true, message: translate(lang!=null?lang:'ger',"Mục này không được bỏ trống") }]}>
          <Input />
        </Form.Item>
        <Form.Item label={translate(lang!=null?lang:'ger','Định hướng nghề nghiệp')} 
        name="Navigation"
        rules={[{ required: true, message: translate('en',"Phải chọn định hướng nghề nghiệp") }]}>
            <Select options={optionsNavigation} />
        </Form.Item>      
        <Form.Item label="" wrapperCol={{offset:4}}>
          <Button type="primary" htmlType="submit">{translate('en',"Save")}</Button>
        </Form.Item>
        </Form>
    </Modal>
    <Modal title={translate('en','Thêm chi tiết')} 
    open={isModalJobDetailOpen} footer={null} onCancel={()=>setIsModalJobDetailOpen(false)}>
        <Form labelCol={{ span: 8 }}
        wrapperCol={{ span: 20 }}
        layout="horizontal"
        onFinish={onFinish1} >          
        <Form.Item label={translate(lang!=null?lang:'ger','Ngày yêu cầu')} 
        name="RequirementDate"
        rules={[{ required: true, message: translate(lang!=null?lang:'ger',"Mục này không được để trống") }]}>
            <DatePicker />
        </Form.Item>
        <Form.Item label={translate(lang!=null?lang:'ger',"Số vị trí công việc")} 
        name="NumberOfVacancy"
        rules={[{ required: true, message: translate(lang!=null?lang:'ger',"Mục này không được để trống") }]}>
          <Input type="number" />
        </Form.Item>
        <Form.Item label={translate(lang!=null?lang:'ger',"Ghi chú")} 
        name="NoteContent"
        rules={[{ required: true, message: translate(lang!=null?lang:'ger',"Mục này không được để trống") }]}>
          <Input />
        </Form.Item>            
        <Form.Item label="" wrapperCol={{offset:4}}>
          <Button type="primary" htmlType="submit">{translate('en',"Save")}</Button>
        </Form.Item>
        </Form>
    </Modal>
    <Modal title={translate(lang!=null?lang:'ger','Phân bổ')} 
    open={isModalAssignOpen} footer={null} onCancel={()=>setIsModalAssignlOpen(false)}>
        <Form labelCol={{ span: 8 }}
        wrapperCol={{ span: 20 }}
        layout="horizontal"
        form = {form}
        onFinish={onFinish2} >          
        <Form.Item label={translate(lang!=null?lang:'ger',"Chọn ứng viên")} 
        name="ProvidedCv"
        rules={[{ required: true, message: translate(lang!=null?lang:'ger',"This field must be not empty") }]}>
          <Select
          mode="multiple"
          showSearch
          optionFilterProp="children"
          filterOption={(input, option:any) => {
          return (
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          );        
        }}
          placeholder={translate('ger',"Định hướng nghề nghiệp")}
          onChange={handleChange1}
          options={optionsIntern}
        />
        </Form.Item>            
        <Form.Item label="" wrapperCol={{offset:4}}>
          <Button type="primary" htmlType="submit">{translate('en',"Save")}</Button>
        </Form.Item>
        </Form>
    </Modal>
    <Modal title="Delete data" open={isConfirmModalDeleteOpen} onOk={()=>handleConfirmOk(requirmentDetailId)} onCancel={()=>setIsConfirmModalDeleteOpen(false)}>
        <div style={{height:'110px', marginTop:'20px'}}>
          <div style={{float:'left', overflow:'hidden'}}>
            <img src={deleteImage} style={{width:'100px', height:'80px'}} />
          </div>
          <div style={{float:'left', fontSize:'24px', paddingLeft:'10px', color:'red'}}>
          {translate(lang!=null?lang:'ger','Bạn có chắc chắn xóa không?')}
          </div>
        </div>
    </Modal>
    <Modal title="Find Candidate" open={isFindCandidateModalOpen} onOk={()=>handleConfirmFCModelOk()} 
    onCancel={()=>{
      setIsFindCandidateModalOpen(false)}}>
        <FindCandidate data={searchInternsData} />
    </Modal>
    <Modal title={translate(lang!=null?lang:'ger','Xếp lịch phỏng vấn')} open={isInterviewModalOpen}
      footer={null}
      onCancel={()=>{
        form.setFieldsValue({InternId:[]})
        setIsInterviewModalOpen(false)}}>
      <Form onFinish={onFinish3} form={form} style={{border: '1px solid gray', marginTop: 20, paddingTop:20}}
    layout="horizontal"
    labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}
    >
      <Form.Item name="EventDate" label={translate(lang!=null?lang:'ger','Chọn ngày phỏng vấn')}
      rules={[{required:true, message:translate(lang!=null?lang:'ger','Phải chọn ngày phỏng vấn')}]}
      >
      <DatePicker showTime={{ format: 'HH:mm' }}
      format="YYYY-MM-DD HH:mm"
      />
      </Form.Item>
      <Form.Item name="EventName"
      label={translate(lang!=null?lang:'ger','Chọn đợt phỏng vấn')}
      rules={[{required:true, message:translate(lang!=null?lang:'ger','Phải chọn đợt phỏng vấn')}]}
      >
        <Select
        options={optionsEventPredefine}
        />
      </Form.Item>
      <Form.Item name="InternId"
      label='Chọn ứng viên:'
      rules={[{required:true, message:translate(lang!=null?lang:'ger','Phải chọn ứng viên')}]}
      >
        <Select
        mode='multiple'
        options={optionInternForInterview}
        />
      </Form.Item>
      <Form.Item label="   ">
          <Button type="primary" htmlType="submit"
          disabled = {localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
          false : true }
          >{translate(lang!=null?lang:'ger','Lưu dữ liệu')}</Button>
      </Form.Item>
    </Form>
    </Modal>
  </>)
}
export default JobPool;