import React from 'react';
import {dataTranslate} from './dataTranslate';
export const translate = (lang:string, text: string):string =>{
    let outPutText = '';
    switch(lang)
    {
        case 'vie':
            outPutText = dataTranslate.filter(x=>x.text == text).length > 0 ? 
            dataTranslate.filter(x=>x.text == text)[0].vie : text;
            break;
        case 'en':
            outPutText = dataTranslate.filter(x=>x.text == text).length > 0 ? 
            dataTranslate.filter(x=>x.text == text)[0].en : text;
            break;
        default:
            outPutText = dataTranslate.filter(x=>x.text == text).length > 0 ? 
            dataTranslate.filter(x=>x.text == text)[0].ger : text;
    }
    return outPutText;
    
}