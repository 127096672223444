import React, { ChangeEvent, useEffect, useState } from 'react';
import { Collapse,
   Form, Button, Select, DatePicker, Input, message, Row } from 'antd';
import { useAppDispatch } from '../../configs/hook';
import FormItem from 'antd/es/form/FormItem';
import dayjs from 'dayjs';
import axios from 'axios';
import type { SelectProps } from 'antd';
import { translate } from '../../helpers/translate';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import Item from 'antd/es/list/Item';

const { Panel } = Collapse;
type OptionsType = {
  value: number,
  label: string
}
const Register = (props:{lang:string}) => {
  const subjects = useSelector((state:RootState)=>state.careers);
  const formItemLayout ={
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
          }
    const buttonItemLayout ={
            wrapperCol: { span: 14, offset: 4 },
          }
  const [fileList, setFileList] = useState<File>();
  const [fileList1, setFileList1] = useState<File>();
  const [cmndImage, setCmndImage] = useState<File>();
  const [passportImage, setPassportImage] = useState<File>();
  const [graduatedImage, setGraduatedImage] = useState<File>();
  const [subjectName, setSubjectName] = useState<string[]>([]);
  const [internName, setInternName] = useState('');
  const [email, setEmail] = useState('');
  const [major,setMajor] = useState(''); 
  const [dateOfBirth,setDateOfBirth] = useState(new Date('2001-01-01'));
  const onFinish = async (values: any) => {
    const token = localStorage.getItem('token');
    const formData = new FormData();
    let myStartDate = dateOfBirth.getDate()<10?"0"+dateOfBirth.getDate():dateOfBirth.getDate();
    let myStartMonth = (dateOfBirth.getMonth()+1)<10?"0"+(dateOfBirth.getMonth()+1):dateOfBirth.getMonth()+1;
    let internSpecialized:string = '';
    console.log(subjectName);
    subjectName.map((a:string)=>{
      internSpecialized = internSpecialized + a + '_';
    })
    
      if(fileList)
      {
        formData.append("CVFile", fileList);
        if(fileList1)
          formData.append("CVFile1", fileList1);
        if(cmndImage)
          formData.append("CmndImage", cmndImage);
        if(passportImage)
          formData.append("PassportImage", passportImage);
        if(graduatedImage)
          formData.append("GraduatedImage", graduatedImage);
        formData.append("InternMail", values.InternMail);
        formData.append("InternName", values.InternName);
        formData.append("ForeignLanguage", values.ForeignLanguage)
        formData.append("Major", values.Major);
        formData.append("TelephoneNum", values.TelephoneNum);
        formData.append("CitizenIdentification", values.CitizenIdentification);
        formData.append("University", values.University);
        formData.append("Internable", values.Internable);
        formData.append("FullTime", values.FullTime);
        formData.append("PlaceOfBirth", values.PlaceOfBirth);
        formData.append("Address", values.Address);
        formData.append("Father", values.Father);
        formData.append("Mother", values.Mother);
        formData.append("ParentAddress", values.ParentAddress);
        formData.append("SocialMedia", values.SocialMedia);
        formData.append("ParentContact", values.ParentContact);
        formData.append("DateOfBirth", dateOfBirth.getFullYear() + '-' + myStartMonth + '-' + myStartDate);
        formData.append("Passport", values.Passport);
        formData.append("InternSpecialized", internSpecialized);
        formData.append("Gender", values.Gender)
        const config = {     
          headers: { "Content-Type": "multipart/form-data", 'Authorization': `Bearer ${token}` }
        }
        axios.post(localStorage.getItem("serverName")+'api/Register', formData, config)
          .then(response => {
                message.success(response.data.message);
          })
          .catch(error => {
              message.info("Đã có lỗi trong quá trình đăng ký");
              console.log(error);
          });       
      };
    }
  const [options, setOptions] = useState<SelectProps['options']>([]);
  const [optionsUniversity, setOptionsUniversity] = useState<SelectProps['options']>([]);
  const [optionsJDs, setOptionsJDs] = useState<SelectProps['options']>([]);
  const [optionsGender, setOptionsGender] = useState<SelectProps['options']>([
    {
      value: '0', label: translate(props.lang,'Nữ')
    },
    {
      value: '1', label: translate(props.lang,'Nam')
    }
  ]);
  const [optionsInternable, setOptionsInternable] = useState<SelectProps['options']>([
    {
      value: '1', label: translate(props.lang,'Đổi bằng')
    },
    {
      value: '0', label: translate(props.lang,'Học nghề')
    }
  ]);

  const onChange = (key: string | string[]) => {
    console.log(key);
  };
  const dispatch = useAppDispatch();
  const [emailData, setEmailData] = useState(localStorage.getItem('emailData'));
  const [optionsHowToKnowAlta,setOptionsHowToKnowAlta] = useState([
    {value:"Deutsch A1", label: "Deutsch A1"},
    {value:"Deutsch A2",label:"Deutsch A2"},
    {value:"Deutsch B1", label: "Deutsch B1"},
    {value:"Deutsch B2",label:"Deutsch B2"},
    {value:"Deutsch C1",label:"Deutsch C1"},
    {value:"Deutsch C2",label:"Deutsch C2"},
    {value:"English B1", label: "English B1"},
    {value:"English B2",label:"English B2"},
    {value:"English C1",label:"English C1"},
    {value:"English C2",label:"English C2"},
    {
      value: 'IELTS under 4.0', label: 'IELTS under 4.0'
    },
    {
      value: 'IELTS 4.0 – under 5.0', label: 'IELTS 4.0 – under 5.0'
    },
    {
      value: 'IELTS 5.0 – under 6.5', label: 'IELTS 5.0 – under 6.5'
    },
    {
      value: 'IELTS 6.5 – under 7.5', label: 'IELTS 6.5 – under 7.5'
    },
    {
      value: 'IELTS from 8.0', label: 'IELTS from 8.0'
    }
  ])
  const handleChange = (value: number) => {
    
  };
  const handleChange1 = (value: string[]) => {
    setSubjectName(value);   
  };
  const handleChange2 = (value: string) => {
    //setMatruong(value);
    
  };
  useEffect(()=>{
      console.log(subjects);
      setOptionsJDs(subjects.map(row=>{
        return {value: row.abbreatation, 
          label: props.lang=='vie' ? row.navigationNameVn : props.lang == 'ger' ? row.careerNavigationGe : row.careerNavigationEn}
      }))
      setOptionsInternable([
        {
          value: '1', label: translate(props.lang,'Đổi bằng')
        },
        {
          value: '0', label: translate(props.lang,'Học nghề')
        }
      ])
      setOptionsGender([
        {
          value: '0', label: translate(props.lang,'Nữ')
        },
        {
          value: '1', label: translate(props.lang,'Nam')
        }
      ])
  },[props.lang])
  return (
    <>
    <div>
    <Form onFinish={onFinish} style={{border: '1px solid gray', marginTop: 20, paddingTop:20}}
    layout="horizontal"
    labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}
    >
      <FormItem name="fileList"
        label={translate(props.lang,'Chọn file CV để đăng')}
        rules={[{required:true, message:'Mục này không được để trống'}]}
        >
        <Input type="file" onChange={(e:ChangeEvent<HTMLInputElement>)=>{
          const temp = e.target.files;
          if(!temp) 
            return;
          else
            setFileList(temp[0])}} />
      </FormItem>
      <FormItem name="fileList1"
        label={translate(props.lang,'Chọn file CV để đăng')}
        >
        <Input type="file" onChange={(e:ChangeEvent<HTMLInputElement>)=>{
          const temp = e.target.files;
          if(!temp) 
            return;
          else
            setFileList1(temp[0])}} />
      </FormItem>
      <FormItem name="cmndImage"
        label={translate(props.lang,'Chọn file ảnh chứng minh nhân dân')}
        rules={[{required:true, message:'Mục này không được để trống'}]}
        >
        <Input type="file" onChange={(e:ChangeEvent<HTMLInputElement>)=>{
          const temp = e.target.files;
          if(!temp) 
            return;
          else
            setCmndImage(temp[0])}} />
      </FormItem>
      <FormItem name="passportImage"
        label={translate(props.lang,'Chọn file ảnh passport')}
        >
        <Input type="file" onChange={(e:ChangeEvent<HTMLInputElement>)=>{
          const temp = e.target.files;
          if(!temp) 
            return;
          else
            setPassportImage(temp[0])}} />
      </FormItem>
      <FormItem name="GraduatedImage"
        label={translate(props.lang,'Chọn file ảnh bằng tốt nghiệp cấp 3')}
        rules={[{required:true, message:'Mục này không được để trống'}]}
        >
        <Input type="file" onChange={(e:ChangeEvent<HTMLInputElement>)=>{
          const temp = e.target.files;
          if(!temp) 
            return;
          else
            setGraduatedImage(temp[0])}} />
      </FormItem>
      <Form.Item name="InternName"
      label={translate(props.lang,'Họ và tên')}
      rules={[{required:true, message:translate(props.lang,'Mục này không được để trống')}]}
      >
        <Input value={internName} onChange={(e)=>setInternName(e.currentTarget.value)} />
      </Form.Item>
      <Form.Item name="Gender"
      label={translate(props.lang,'Giới tính')}
      rules={[{required:true, message:translate(props.lang,'Mục này không được để trống')}]}
      >
        <Select
        onChange={handleChange2}
        options={optionsGender}
        />
      </Form.Item>
      <FormItem name="DateOfBirth" label={translate(props.lang,"Ngày sinh")}
      rules={[{required:true, message:translate(props.lang,'Mục này không được để trống')}]}
      >
      <DatePicker defaultValue={dayjs(dateOfBirth)}
        onChange={(date, dateString)=>{
          setDateOfBirth(new Date(dateString));
        }} />
      </FormItem>
      <Form.Item name="PlaceOfBirth"
      label={translate(props.lang,'Nơi sinh')}
      rules={[{required:true, message:translate(props.lang,'Mục này không được để trống')}]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="Address"
      label={translate(props.lang,'Cư trú')}
      rules={[{required:true, message:translate(props.lang,'Mục này không được để trống')}]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="CitizenIdentification"
      label={translate(props.lang,'Căn cước công dân')}
      rules={[{required:true, message:translate(props.lang,'Mục này không được để trống')}]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="Passport"
      label={translate(props.lang,'Số hộ chiếu')}
      //rules={[{required:true, message:translate(props.lang,'Mục này không được để trống')}]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="TelephoneNum"
      label={translate(props.lang,'Điện thoại')}
      rules={[{required:true, message:translate(props.lang,'Mục này không được để trống')}]}
      >
        <Input value={email} onChange={(e)=>setEmail(e.currentTarget.value)} />
      </Form.Item>
      <Form.Item name="SocialMedia"
      label={translate(props.lang,'Tài khoản mạng xã hội')}
      rules={[{required:true, message:translate(props.lang,'Mục này không được để trống')}]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="InternMail"
      label={translate(props.lang,'Địa chỉ email')}
      rules={[{required:true, message:translate(props.lang,'Mục này không được để trống')}]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="Father"
      label={translate(props.lang,'Họ tên bó')}
      rules={[{required:true, message:translate(props.lang,'Mục này không được để trống')}]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="Mother"
      label={translate(props.lang,'Họ tên mẹ')}
      rules={[{required:true, message:translate(props.lang,'Mục này không được để trống')}]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="ParentAddress"
      label={translate(props.lang,'Địa chỉ bố mẹ')}
      rules={[{required:true, message:translate(props.lang,'Mục này không được để trống')}]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="ParentContact"
      label={translate(props.lang,'Liên lạc người thân ở VN')}
      rules={[{required:true, message:translate(props.lang,'Mục này không được để trống')}]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="Internable"
      label={translate(props.lang,'Chọn hướng đi')}
      rules={[{required:true, message:translate(props.lang,'Mục này không được để trống')}]}
      >
        <Select
        options={optionsInternable}
        />
      </Form.Item>
      <Form.Item name="InternSpecialized"
      label={translate(props.lang,'Định hướng nghề nghiệp')}
      rules={[{required:true, message:translate(props.lang,'Mục này không được để trống')}]}
      >
        <Select
          mode="multiple"
          placeholder={translate(props.lang,"Định hướng nghề nghiệp")}
          onChange={handleChange1}
          options={optionsJDs}
        />
      </Form.Item>
      <Form.Item name="ForeignLanguage"
      label={translate(props.lang,'Trình độ ngoại ngữ')}
      rules={[{required:true, message:translate(props.lang,'Mục này không được để trống')}]}
      >
        <Select
        onChange={handleChange2}
        options={optionsHowToKnowAlta}
        />
      </Form.Item>
      <Form.Item name="Major"
      label={translate(props.lang,'Chuyên ngành')}
      rules={[{required:true, message:translate(props.lang,'Mục này không được để trống')}]}
      >
        <Input value={major} onChange={(e)=>setMajor(e.currentTarget.value)} />
      </Form.Item>
      <Form.Item label="   ">
          <Button type="primary" htmlType="submit">{translate(props.lang,'Lưu dữ liệu')}</Button>
      </Form.Item>
    </Form>
    </div>
    </>
  )
}
export default Register; 