import React, { ChangeEvent, useEffect, useState } from 'react';
import { Collapse, Modal, Tabs,
   Form, Button, Select, DatePicker, Input, message } from 'antd';
import { useAppDispatch } from '../../configs/hook';
import FormItem from 'antd/es/form/FormItem';
import dayjs from 'dayjs';
import axios from 'axios';
import type { SelectProps } from 'antd';
import { translate } from '../../helpers/translate';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import Item from 'antd/es/list/Item';
import { getIntern, internSlice } from '../../layouts/guests/internSlice';
import './style.css';
import { InternType } from '../../interface';
import { DataType } from '../enterprisevn/ourInternCv';
const TabPane = Tabs.TabPane;
const { Panel } = Collapse;
type OptionsType = {
  value: number,
  label: string
}
const MyProfile = (props:{lang:string, myProfileInfo?: InternType | DataType, readOnlyOption: boolean}) => {
  const dispatch = useAppDispatch();
  const [lang, setLang] = useState<string|null>('ger');
  const [imageData, setImageData] = useState<File>();
  const [fileList1, setFileList1] = useState<File>();
  const [optionsNavigation, setOptionsNavigation] = useState<SelectProps['options']>([
      {value:'1', label:'Đổi bằng'},{value:'0', label:'Học nghề'}
  ])
  const [tabKey, setTabKey] = useState('1');
  const [cmndImage, setCmndImage] = useState<File>();
  const [passportImage, setPassportImage] = useState<File>();
  const [graduatedImage, setGraduatedImage] = useState<File>();
  const [isCVFileEditable, setIsCVFileEditable] = useState(false);
  const [isNavigationEditable, setIsNavigationEditable] = useState(false);
  const [isNavigationCareerEditable, setIsNavigationCareerEditable] = useState(false);
  const [isCccdImageEditable, setIsCccdImageEditable] = useState(false);
  const [isPassportImageEditable, setIsPassportImageEditable] = useState(false);
  const [isGraduatedImageEditable, setIsGraduatedImageEditable] = useState(false);
  const [isCccdEditable, setIsCccdEditable] = useState(false);
  const [isSocialMediaEditable, setIsSocialMediaEditable] = useState(false);
  const [isFatherEditable, setIsFatherEditable] = useState(false);
  const [isMotherEditable, setIsMotherEditable] = useState(false);
  const [isParentAddressEditable, setIsParentAddressEditable] = useState(false);
  const [isParentContactEditable, setIsParentContactEditable] = useState(false);
  const [isPlaceOfBirthEditable, setIsPlaceOfBirthEditable] = useState(false);
  const [isPassportEditable, setIsPassportEditable] = useState(false);
  const [isInternNameEditable, setIsInternNameEditable] = useState(false);
  const [isAddressEditable, setIsAddressEditable] = useState(false);
  const [isDateOfBirthEditable, setIsDateOfBirthEditable] = useState(false);
  const [isDateOfCmndEditable, setIsDateOfCmndEditable] = useState(false);
  const [isTelephoneNumEditable, setIsTelephoneNumEditable] = useState(false);
  const [isMajorEditable, setIsMajorEditable] = useState(false);
  const [isUniversityEditable, setIsUniversityEditable] = useState(false);
  const [isForeignLangEditable, setIsForeignLangEditable] = useState(false);
  const myProfileInfo = useSelector((state:RootState)=>state.intern);
  const [fileList, setFileList] = useState<File>();
  const [subjectName, setSubjectName] = useState<string[]>([]);
  const [optionsJDs, setOptionsJDs] = useState<SelectProps['options']>([]);
  const [initialJDs, setInitialJDs] = useState<string[]>([]);
  const [optionsHowToKnowAlta,setOptionsHowToKnowAlta] = useState([
    {value:"Deutsch A1", label: "Deutsch A1"},
    {value:"Deutsch A2",label:"Deutsch A2"},
    {value:"Deutsch B1", label: "Deutsch B1"},
    {value:"Deutsch B2",label:"Deutsch B2"},
    {value:"Deutsch C1",label:"Deutsch C1"},
    {value:"Deutsch C2",label:"Deutsch C2"},
    {value:"English B1", label: "English B1"},
    {value:"English B2",label:"English B2"},
    {value:"English C1",label:"English C1"},
    {value:"English C2",label:"English C2"},
    {
      value: 'IELTS under 4.0', label: 'IELTS under 4.0'
    },
    {
      value: 'IELTS 4.0 – under 5.0', label: 'IELTS 4.0 – under 5.0'
    },
    {
      value: 'IELTS 5.0 – under 6.5', label: 'IELTS 5.0 – under 6.5'
    },
    {
      value: 'IELTS 6.5 – under 7.5', label: 'IELTS 6.5 – under 7.5'
    },
    {
      value: 'IELTS from 8.0', label: 'IELTS from 8.0'
    }
  ])
  const handleChange1 = (value: string[]) => {
    let myValue:string = "";
    value.map(item=>myValue = myValue + item + '_');
    setInitialJDs(value);
    UpdateData({...myProfileInfo, internSpecialized: myValue}, undefined, undefined,undefined, undefined);
  };
    const [emailData, setEmailData] = useState(localStorage.getItem('emailData'));
    const [isModalOpen, setIsModalOpen] = useState(false);
    function callback(key:any) {
      //console.log(key);
      setTabKey(key);
    }
  const myCareers = useSelector((state:RootState)=>state.careers);
  const UpdateData = (data:InternType | DataType, fileData: File | undefined, graduatedImage: File | undefined,
    cccdImage: File | undefined, passportImage: File | undefined) => {
    const token = localStorage.getItem('token');
    const formData = new FormData();
    console.log(data);
    if(imageData)
      formData.append("ImageData", imageData);
    if(fileData != undefined)
      formData.append("CVFile", fileData);
    if(cccdImage != undefined)
      formData.append("CmndImage", cccdImage);
    if(graduatedImage != undefined)
      formData.append("GraduatedImage", graduatedImage); 
    if(passportImage != undefined)
      formData.append("PassportImage", passportImage);
    formData.append("CitizenIdentification", data.citizenIdentification);
    formData.append("Major", data.major);
    formData.append("University", data.university);
    formData.append("TelephoneNum", data.telephoneNum);
    formData.append("ForeignLanguage", data.foreignLanguage);
    formData.append("DateOfBirth", data.dateOfBirth);
    formData.append("Address", data.address);
    formData.append("PlaceOfBirth", data.placeOfBirth);
    formData.append("Father", data.father);
    formData.append("Mother", data.mother);
    formData.append("ParentAddress", data.parentAddress);
    formData.append("ParentContact", data.parentContact);
    formData.append("Passport", data.passport);
    formData.append("Internable", data.internable);
    formData.append("InternSpecialized", data.internSpecialized);
    formData.append("InternName", data.internName);
    formData.append("SocialMedia", data.socialMedia);
    const config = {     
      headers: { 'content-type': 'multipart/form-data', 'Authorization': `Bearer ${token}` }
    }
     axios.put(localStorage.getItem("serverName")+'api/Register/'+ myProfileInfo.id, formData, config)
      .then(response => {
        console.log(response.data);
        dispatch(getIntern(myProfileInfo.internMail));
      })
      .catch(error => {
          console.log(error);
      });
      setFileList(undefined);
      setImageData(undefined);
  }
useEffect(()=>{
  setOptionsJDs(myCareers.map(row=>{
    return {value: row.abbreatation, 
      label: props.lang=='vie' ? row.navigationNameVn : props.lang == 'ger' ? row.careerNavigationGe : row.careerNavigationEn}
  }))
  if(myProfileInfo.internSpecialized != null){
  setInitialJDs(myCareers?.filter(x=>myProfileInfo.internSpecialized.includes((x.abbreatation)))
  .map(item=>item.abbreatation))
  };
},[tabKey]);
  return (
    <>
    <div className='LeftPane'>
        <img src={myProfileInfo.imageDataUrl} 
            style={{width:'300px', height:'400px', border:'5px solid green'}}
            onClick={()=>{
                setIsModalOpen(true);
            }}
            />
    </div>
    <div className='RightPane'>
    <Tabs defaultActiveKey={tabKey} onChange={callback}>
    <TabPane tab={translate(lang!=null?lang:'ger','Thông tin chung')} key="1">
        <table style={{width:'100%'}}>
            <tr>
              <td className='ColumnLeft'>{translate(lang!=null?lang:'ger','Họ và tên')}</td>
              <td>
              {!props.readOnlyOption ?
                <>
                  {!isInternNameEditable ?
                <a onClick={()=>setIsInternNameEditable(true)}
                >{myProfileInfo.internName == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.internName}</a>
                : <input value={myProfileInfo.internName == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.internName} 
                onChange = {(e)=> dispatch(internSlice.actions.updateIntern({
                  ...myProfileInfo, internName: e.currentTarget.value 
                }))}
                onKeyPress = {(e) => {
                  if(e.key == 'Enter'){
                        UpdateData({...myProfileInfo}, undefined, undefined, undefined, undefined)
                        setIsInternNameEditable(false);
                  }                 
                }}
                />}
                </>
              : <p>{myProfileInfo.internName == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.internName}</p> } 
                
              </td>
            </tr>
            <tr>
              <td>{translate(lang!=null?lang:'ger','Ngày sinh')}</td>
              <td>
                {!props.readOnlyOption ?
                <>
              {!isDateOfBirthEditable ?
                <a onClick={(e)=>setIsDateOfBirthEditable(true)}>{myProfileInfo.dateOfBirth}</a>
                : <DatePicker defaultValue={dayjs(myProfileInfo.dateOfBirth)} 
                onChange = {(date:any, dateString:string)=>{
                    dispatch(internSlice.actions.updateIntern({
                      ...myProfileInfo, dateOfBirth: dateString 
                    }))
                    UpdateData({...myProfileInfo, dateOfBirth: dateString}, undefined, undefined,
                      undefined, undefined);
                    setIsDateOfBirthEditable(false);
                  }
              }
                />
              }
              </>
              : <p>{myProfileInfo.dateOfBirth}</p> }
              </td>
            </tr>
            <tr>
              <td>{translate(lang!=null?lang:'ger','Nơi sinh')}</td>
              <td>
              {!props.readOnlyOption ?
                <>
                  {!isPlaceOfBirthEditable ?
                <a onClick={()=>setIsPlaceOfBirthEditable(true)}
                >{myProfileInfo.placeOfBirth == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.placeOfBirth}</a>
                : <input value={myProfileInfo.placeOfBirth == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.placeOfBirth} 
                onChange = {(e)=> dispatch(internSlice.actions.updateIntern({
                  ...myProfileInfo, placeOfBirth: e.currentTarget.value 
                }))}
                onKeyPress = {(e) => {
                  if(e.key == 'Enter'){
                        UpdateData({...myProfileInfo}, undefined, undefined, undefined, undefined)
                        setIsPlaceOfBirthEditable(false);
                  }                 
                }}
                />}
                </>
              : <p>{myProfileInfo.placeOfBirth}</p> }
              </td>
            </tr>
            <tr>
              <td>{translate(lang!=null?lang:'ger','Giới tính')}</td>
              <td>{myProfileInfo.gender=='1'?translate(lang!=null?lang:'ger','Nam'):translate(lang!=null?lang:'ger','Nữ')}</td>
            </tr>
            <tr>
              <td>{translate(lang!=null?lang:'ger','Căn cước công dân')}</td>
              <td>
              {!props.readOnlyOption ?
                <>
                  {!isCccdEditable ?
                <a onClick={()=>setIsCccdEditable(true)}
                >{myProfileInfo.citizenIdentification == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.citizenIdentification}</a>
                : <input value={myProfileInfo.citizenIdentification == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.citizenIdentification} 
                onChange = {(e)=> dispatch(internSlice.actions.updateIntern({
                  ...myProfileInfo, citizenIdentification: e.currentTarget.value 
                }))}
                onKeyPress = {(e) => {
                  if(e.key == 'Enter'){
                        UpdateData({...myProfileInfo}, undefined, undefined, undefined, undefined)
                        setIsCccdEditable(false);
                  }                 
                }}
                />}
                </>
              : <p>{myProfileInfo.citizenIdentification == null?'Không có dữ liệu': myProfileInfo.citizenIdentification}</p> } 
                
              </td>
            </tr>
            <tr>
              <td>{translate(lang!=null?lang:'ger','Ngày cấp')}</td>
              <td>
              {!props.readOnlyOption ?
                <>
                  {!isDateOfBirthEditable ?
                <a onClick={(e)=>setIsDateOfCmndEditable(true)}>{myProfileInfo.citizenIdentificationDate == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.citizenIdentificationDate}</a>
                : <DatePicker defaultValue={dayjs(new Date())} 
                onChange = {(date:any, dateString:string)=>{
                    dispatch(internSlice.actions.updateIntern({
                      ...myProfileInfo, dateOfBirth: dateString 
                    }))
                    UpdateData({...myProfileInfo, citizenIdentificationDate: dateString}, undefined, undefined,
                      undefined, undefined);
                    setIsDateOfCmndEditable(false);
                  }
              }
                />
              }
                </>
              : <p>{myProfileInfo.citizenIdentificationDate == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.citizenIdentificationDate}</p> } 
              
              </td>
            </tr>
            <tr>
              <td>{translate(lang!=null?lang:'ger','Tải ảnh CCCD')}</td>
              {!isCccdImageEditable ?
              <td>
              <a href='#'
              onClick = {()=>{
                const token = localStorage.getItem('token');
                fetch(localStorage.getItem("serverName")+'api/Register/files/' + myProfileInfo.cmndUrl, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
            })
            .then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = myProfileInfo.cmndUrl;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();  //afterwards we remove the element again
        });
            }}
              >Download CCCD</a>&nbsp;&nbsp;
              {!props.readOnlyOption ?
              <a onClick={()=>{
                setIsCccdImageEditable(true);
              }}>{translate(lang!=null?lang:'ger','Cập nhật ảnh CCCD')}</a> : '' }
              </td>
              : <td>
                <Input type="file" onChange={(e:ChangeEvent<HTMLInputElement>)=>{
          const temp = e.target.files;
          if(!temp) 
            return;
          else
            setCmndImage(temp[0])
            UpdateData({...myProfileInfo}, undefined, undefined, temp[0], undefined);
            setIsCccdImageEditable(false);
            }} />
              </td>
          }
            </tr>
            <tr>
              <td>{translate(lang!=null?lang:'ger','Điện thoại liên hệ')}</td>
              <td>
              {!props.readOnlyOption ?
                <>
                  {!isTelephoneNumEditable ?
                <a onClick={()=>setIsTelephoneNumEditable(true)}
                >{myProfileInfo.telephoneNum == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.telephoneNum}</a>
                : <input value={myProfileInfo.telephoneNum == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.telephoneNum} 
                onChange = {(e)=> dispatch(internSlice.actions.updateIntern({
                  ...myProfileInfo, telephoneNum: e.currentTarget.value 
                }))}
                onKeyPress = {(e) => {
                  if(e.key == 'Enter'){
                        UpdateData({...myProfileInfo}, undefined, undefined, undefined, undefined);
                        setIsTelephoneNumEditable(false);
                  }                 
                }}
                />}
                </>
              : <p>{myProfileInfo.telephoneNum == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.telephoneNum}</p> } 
                
              </td>
            </tr>
            <tr>
              <td>{translate(lang!=null?lang:'ger','Thư điện tử')}</td>
              <td>{myProfileInfo.internMail}</td>
            </tr>
            <tr>
              <td>{translate(lang!=null?lang:'ger','Tài khoản mạng xã hội')}</td>
              <td>
              {!props.readOnlyOption ?
                <>
                  {
                !isSocialMediaEditable ?
                <a onClick={()=>setIsSocialMediaEditable(true)}
                >{myProfileInfo.socialMedia == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.socialMedia}</a>
                : <input value={myProfileInfo.socialMedia == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.socialMedia} 
                onChange = {(e)=> dispatch(internSlice.actions.updateIntern({
                  ...myProfileInfo, socialMedia: e.currentTarget.value 
                }))}
                onKeyPress = {(e) => {
                  if(e.key == 'Enter'){
                        UpdateData({...myProfileInfo}, undefined, undefined, undefined, undefined);
                        setIsSocialMediaEditable(false);
                  }                 
                }}
                />
              }
                </>
              : <p>{myProfileInfo.socialMedia == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.socialMedia}</p> }   
                
              </td>
            </tr>
            <tr>
              <td>{translate(lang!=null?lang:'ger','Địa chỉ cư trú')}</td>
              <td>
              {!props.readOnlyOption ?
                <>
                  {
                !isAddressEditable ?
                <a onClick={()=>setIsAddressEditable(true)}
                >{myProfileInfo.address == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.address}</a>
                : <input value={myProfileInfo.address == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.address} 
                onChange = {(e)=> dispatch(internSlice.actions.updateIntern({
                  ...myProfileInfo, address: e.currentTarget.value 
                }))}
                onKeyPress = {(e) => {
                  if(e.key == 'Enter'){
                        UpdateData({...myProfileInfo}, undefined, undefined, undefined, undefined);
                        setIsAddressEditable(false);
                  }                 
                }}
                />
              }
                </>
              : <p>{myProfileInfo.address == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.address}</p> }   
                
                </td>
            </tr>
            <tr>
              <td>{translate(lang!=null?lang:'ger','Số hộ chiếu')}</td>
              <td>
              {!props.readOnlyOption ?
                <>
                  {
                !isPassportEditable ?
                <a onClick={()=>setIsPassportEditable(true)}
                >{myProfileInfo.passport == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.passport}</a>
                : <input value={myProfileInfo.passport == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.passport} 
                onChange = {(e)=> dispatch(internSlice.actions.updateIntern({
                  ...myProfileInfo, passport: e.currentTarget.value 
                }))}
                onKeyPress = {(e) => {
                  if(e.key == 'Enter'){
                        UpdateData({...myProfileInfo}, undefined, undefined, undefined, undefined);
                        setIsPassportEditable(false);
                  }                 
                }}
                />
              }
                </>
              : <p>{myProfileInfo.passport == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.passport}</p> }   
                
                </td>
            </tr>
            <tr>
              <td>{translate(lang!=null?lang:'ger','Tải ảnh hộ chiếu')}</td>
              {!isPassportImageEditable ?
              <td>
              <a href='#'
              onClick = {()=>{
                const token = localStorage.getItem('token');
                fetch(localStorage.getItem("serverName")+'api/Register/files/' + myProfileInfo.passportUrl, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
            })
            .then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = myProfileInfo.passportUrl;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();  //afterwards we remove the element again
        });
            }}
              >{translate(lang!=null?lang:'ger','Tải ảnh hộ chiếu')}</a>&nbsp;&nbsp;
              {!props.readOnlyOption ?
              <a onClick={()=>{
                setIsPassportImageEditable(true);
              }}>{translate(lang!=null?lang:'ger','Cập nhật ảnh hộ chiếu')}</a> : ''}
              </td>
              : <td>
                <Input type="file" onChange={(e:ChangeEvent<HTMLInputElement>)=>{
          const temp = e.target.files;
          if(!temp) 
            return;
          else
            setPassportImage(temp[0])
            UpdateData({...myProfileInfo}, undefined, undefined, undefined, temp[0]);
            setIsPassportImageEditable(false);
            }} />
              </td>
          }
            </tr>
            <tr>
              <td>{translate(lang!=null?lang:'ger','Họ tên cha')}</td>
              <td>
              {!props.readOnlyOption ?
                <>
                  {
                !isFatherEditable ?
                <a onClick={()=>setIsFatherEditable(true)}
                >{myProfileInfo.father == null?'Không có dữ liệu': myProfileInfo.father}</a>
                : <input value={myProfileInfo.father == null?'Không có dữ liệu': myProfileInfo.father} 
                onChange = {(e)=> dispatch(internSlice.actions.updateIntern({
                  ...myProfileInfo, father: e.currentTarget.value 
                }))}
                onKeyPress = {(e) => {
                  if(e.key == 'Enter'){
                        UpdateData({...myProfileInfo}, undefined, undefined, undefined, undefined);
                        setIsFatherEditable(false);
                  }                 
                }}
                />
              }
                </>
              : <p>{myProfileInfo.father == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.father}</p> }   
              </td>
            </tr>
            <tr>
              <td>{translate(lang!=null?lang:'ger','Họ tên mẹ')}</td>
              <td>
              {!props.readOnlyOption ?
                <>
                  {
                !isMotherEditable ?
                <a onClick={()=>setIsMotherEditable(true)}
                >{myProfileInfo.mother == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.mother}</a>
                : <input value={myProfileInfo.mother == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.mother} 
                onChange = {(e)=> dispatch(internSlice.actions.updateIntern({
                  ...myProfileInfo, mother: e.currentTarget.value 
                }))}
                onKeyPress = {(e) => {
                  if(e.key == 'Enter'){
                        UpdateData({...myProfileInfo}, undefined, undefined, undefined, undefined);
                        setIsMotherEditable(false);
                  }                 
                }}
                />
              }
                </>
              : <p>{myProfileInfo.mother == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.mother}</p> }   
              </td>
            </tr>
            <tr>
              <td>{translate(lang!=null?lang:'ger','Địa chỉ bố mẹ')}</td>
              <td>
              {!props.readOnlyOption ?
                <>
                  {
                !isParentAddressEditable ?
                <a onClick={()=>setIsParentAddressEditable(true)}
                >{myProfileInfo.parentAddress == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.parentAddress}</a>
                : <input value={myProfileInfo.parentAddress == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.parentAddress} 
                onChange = {(e)=> dispatch(internSlice.actions.updateIntern({
                  ...myProfileInfo, parentAddress: e.currentTarget.value 
                }))}
                onKeyPress = {(e) => {
                  if(e.key == 'Enter'){
                        UpdateData({...myProfileInfo}, undefined, undefined, undefined, undefined);
                        setIsParentAddressEditable(false);
                  }                 
                }}
                />
              }
                </>
              : <p>{myProfileInfo.parentAddress == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.parentAddress}</p> }   
              </td>
            </tr>
            <tr>
              <td>{translate(lang!=null?lang:'ger','Số liên lạc bố/mẹ')}</td>
              <td>
              {!props.readOnlyOption ?
                <>
                  {
                !isParentContactEditable ?
                <a onClick={()=>setIsParentContactEditable(true)}
                >{myProfileInfo.parentContact == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.parentContact}</a>
                : <input value={myProfileInfo.parentContact == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.parentContact} 
                onChange = {(e)=> dispatch(internSlice.actions.updateIntern({
                  ...myProfileInfo, parentContact: e.currentTarget.value 
                }))}
                onKeyPress = {(e) => {
                  if(e.key == 'Enter'){
                        UpdateData({...myProfileInfo}, undefined, undefined, undefined, undefined);
                        setIsParentContactEditable(false);
                  }                 
                }}
                />
              }
                </>
              : <p>{myProfileInfo.parentContact == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.parentContact}</p> }     
              </td>
            </tr>
        </table>
    </TabPane>
    <TabPane tab={translate(lang!=null?lang:'ger','Đào tạo và kinh nghiệm làm việc')} key="2">
    <table style={{width:'100%'}}>
            <tr>
              <td className='ColumnLeft'>{translate(lang!=null?lang:'ger','Chuyên ngành')}</td>
              <td className='ColumnRight'>
              {!props.readOnlyOption ? 
                <>
                {!isMajorEditable ?
                <a onClick={()=>setIsMajorEditable(true)}
                >{myProfileInfo.major == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.major}</a>
                : <input value={myProfileInfo.major == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.major} 
                onChange = {(e)=> dispatch(internSlice.actions.updateIntern({
                  ...myProfileInfo, major: e.currentTarget.value 
                }))}
                onKeyPress = {(e) => {
                  if(e.key == 'Enter'){
                        UpdateData({...myProfileInfo}, undefined, undefined, undefined, undefined);
                        setIsMajorEditable(false);
                  }                 
                }}
                />}
                </>
              : <p>{myProfileInfo.major == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.major}</p>}
              </td>
            </tr>
            <tr>
              <td>{translate(lang!=null?lang:'ger','Trường đại học/cao đẳng')}</td>
              <td>
              {!props.readOnlyOption ? 
                <>
                {!isUniversityEditable ?
                <a onClick={()=>setIsUniversityEditable(true)}
                >{myProfileInfo.university == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.university}</a>
                : <input value={myProfileInfo.university == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.university} 
                onChange = {(e)=> dispatch(internSlice.actions.updateIntern({
                  ...myProfileInfo, university: e.currentTarget.value 
                }))}
                onKeyPress = {(e) => {
                  if(e.key == 'Enter'){
                        UpdateData({...myProfileInfo}, undefined, undefined, undefined, undefined);
                        setIsUniversityEditable(false);
                  }                 
                }}
                />}
                </>
              : <p>{myProfileInfo.university == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.university}</p>}              
              </td>
            </tr>
            <tr>
              <td>{translate(lang!=null?lang:'ger','Trình độ ngoại ngữ')}</td>
              <td>
              {!props.readOnlyOption ? 
                <>
                {!isForeignLangEditable ?
                <a onClick={()=>setIsForeignLangEditable(true)}
                >{myProfileInfo.foreignLanguage == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.foreignLanguage}</a>
                : <Select style={{width:'350px'}} onChange={(value)=>{
                  UpdateData({...myProfileInfo, foreignLanguage: value}, undefined, undefined,
                    undefined, undefined)
                  setIsForeignLangEditable(false);
                }}
                options={optionsHowToKnowAlta}
                />
              }
                </>
              : <p>{myProfileInfo.foreignLanguage == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.foreignLanguage}</p>}
                      
              </td>
            </tr>
            <tr>
              <td>{translate(lang!=null?lang:'ger','Tải tập tin CV')}</td>
              {!isCVFileEditable ?
              <td>
              <a href='#'
              onClick = {()=>{
                const token = localStorage.getItem('token');
                fetch(localStorage.getItem("serverName")+'api/Register/files/' + myProfileInfo.fileUrl, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
            })
            .then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = myProfileInfo.fileUrl;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();  //afterwards we remove the element again
        });
            }}
              >{translate(lang!=null?lang:'ger','Tải CV')}</a>&nbsp;&nbsp;
              {!props.readOnlyOption ?
              <a onClick={()=>{
                setIsCVFileEditable(true);
              }}>{translate(lang!=null?lang:'ger','Cập nhật CV')}</a> : ''}
              </td>
              : <td>
                <Input type="file" onChange={(e:ChangeEvent<HTMLInputElement>)=>{
          const temp = e.target.files;
          if(!temp) 
            return;
          else
            setFileList(temp[0])
            UpdateData({...myProfileInfo}, temp[0], undefined, undefined, undefined);
            setIsCVFileEditable(false);
            }} />
              </td>
          }
            </tr>
            <tr>
              <td>{translate(lang!=null?lang:'ger','Bằng trung học phỏ thông')}</td>
              {!isGraduatedImageEditable ?
              <td>
              <a href='#'
              onClick = {()=>{
                const token = localStorage.getItem('token');
                fetch(localStorage.getItem("serverName")+'api/Register/files/' + myProfileInfo.graduatedUrl, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
            })
            .then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = myProfileInfo.graduatedUrl;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();  //afterwards we remove the element again
        });
            }}
              >{translate(lang!=null?lang:'ger','Tải bằng THPT')}</a>&nbsp;&nbsp;
              {!props.readOnlyOption ?
              <a onClick={()=>{
                setIsGraduatedImageEditable(true);
              }}>{translate(lang!=null?lang:'ger','Cập nhật bằng THPT')}</a> : '' }
              </td>
              : <td>
                <Input type="file" onChange={(e:ChangeEvent<HTMLInputElement>)=>{
          const temp = e.target.files;
          if(!temp) 
            return;
          else
            setGraduatedImage(temp[0])
            UpdateData({...myProfileInfo}, undefined, temp[0], undefined, undefined);
            setIsGraduatedImageEditable(false);
            }} />
              </td>
          }
            </tr>
        </table>
    </TabPane>
    <TabPane tab={translate(lang!=null?lang:'ger','Đăng ký')} key="3">
    <table>
            <tr>
              <td>{translate(lang!=null?lang:'ger','Định hướng')}&nbsp;&nbsp;</td>
              <td>
              {!props.readOnlyOption ? 
                <>
                    {!isNavigationEditable ?
                <a onClick={()=>setIsNavigationEditable(true)}
                >{myProfileInfo.internable == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.internable}</a>
                : <Select style={{width:'300px'}} onChange={(value)=>{
                  console.log(value)
                  UpdateData({...myProfileInfo, internable: value}, 
                    undefined, undefined, undefined, undefined)
                    setIsNavigationEditable(false);
                    
                }}
                options = {optionsNavigation}
                />
              }
                </>
              : <p>{myProfileInfo.internable == null?translate(lang!=null?lang:'ger','Không có dữ liệu'): myProfileInfo.internable}</p>}
                      
              </td>
            </tr>
            <tr>
              <td>{translate(lang!=null?lang:'ger','Định hướng nghề nghiệp')}:&nbsp;&nbsp;</td>
              <td>
              <Select style={{width:'300px'}}
                  mode="multiple" disabled = {props.readOnlyOption?true:false}
                  value={initialJDs}
                  placeholder={translate(props.lang,"Định hướng nghề nghiệp")}
                  onChange={handleChange1}
                  options={optionsJDs}
              />        
              </td>
            </tr>
        </table>
    </TabPane>
  </Tabs>
    </div>
        <Modal title={translate(lang!=null?lang:'ger','Thay đổi ảnh đại diện')}
            open={isModalOpen} onOk={()=>{
            UpdateData({...myProfileInfo}, undefined, undefined, undefined, undefined)
            setIsModalOpen(false);      
          }}
            onCancel={()=>setIsModalOpen(false)} >
                Chọn ảnh: <Input type="file" accept="image/png, image/jpeg, image/jpg"
                onChange={(e:ChangeEvent<HTMLInputElement>)=>{
          const temp = e.target.files;
          if(!temp) 
            return;
          else
            setImageData(temp[0])}} />
        </Modal>
    </>
  )
}
export default MyProfile; 